import dayjs from "dayjs";
import React from "react";
import { Dropdown, IconButton, Modal, Notification, DatePicker, Button, Input, Icon } from "rsuite";
import axios from "../../../../../utilities/axios";
import { AxiosError } from "axios";
import _ from "lodash";

export type typeSendDate = {
    obs_message: string,
    date_message: string
}

interface ChatUpdateStatusState {
    date: {
        date: Date,
        obs: string
    };
    showModal: boolean;
    showModalAppointment: boolean;
    typeSelectModal: 'URGENTE' | 'FINALIZADO' | 'INICIADO' | 'NAO INICIADO' | 'NAO URGENTE' | '';
    loading: boolean;
    chatSituation: string;
    prevSituations: any;
}



interface ChatUpdateStatusProps {
    chatId: number | string;
    parentState?: any;
    submitValues: ({ ...propsSubmit }: typeSendDate) => void
}


export class ChatUpdateStatus extends React.Component<ChatUpdateStatusProps, ChatUpdateStatusState> {

    constructor(props: any) {
        super(props);
        this.state = {
            date: {
                date: new Date(),
                obs: ''
            },
            showModal: false,
            showModalAppointment: false,
            typeSelectModal: '',
            loading: false,
            chatSituation: "",
            prevSituations: null
        }
    }

    syncSituation() {
        axios.get(`/api/v1/chats/get/situation/${this.props.chatId}`)
            .then((response) => {
                const situation = response.data?.item;
                this.setState({ chatSituation: situation?.currentSituation?.situation_chat_name?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase(), prevSituations: situation?.prevSituations });
            });
    }

    componentDidMount(): void {
        this.syncSituation();
    }

    selectInModal(ignoreVerify?: boolean) {
        this.setState({ loading: true });
        if (ignoreVerify) {
            this.isToggleType(
                {
                    type: this.state.typeSelectModal,
                    callback: () => {
                        this.setState(
                            {
                                showModal: false,
                                date: {
                                    date: new Date(),
                                    obs: ''
                                },
                                loading: false
                            }
                        )
                    }
                }
            )
            return
        }

        if (this.state.typeSelectModal === "FINALIZADO") {
            const chatParams = this.props.parentState.oldState;
            axios.get(`/api/v1/appointments/last/${chatParams.project_product_id}/${chatParams.step_id}`)
                .then((response) => {
                    const appointment = response.data?.items;
                    if (!_.isEmpty(appointment)) {
                        console.log("typeSelectModal", appointment.project_product_appointment_status)
                        if (
                            appointment.project_product_appointment_status === "EM ANDAMENTO"
                            ||
                            appointment.project_product_appointment_status === "PAUSADO"
                        ) {
                            this.setState({ showModalAppointment: true, loading: false });
                        } else {
                            this.isToggleType({
                                type: this.state.typeSelectModal,
                                callback: () => {
                                    this.setState({
                                        showModal: false,
                                        date: {
                                            date: new Date(),
                                            obs: ''
                                        },
                                        loading: false
                                    });
                                }
                            });
                        }
                    } else {
                        this.isToggleType({
                            type: this.state.typeSelectModal,
                            callback: () => {
                                this.setState({
                                    showModal: false,
                                    date: {
                                        date: new Date(),
                                        obs: ''
                                    },
                                    loading: false
                                });
                            }
                        });
                    }
                });
        } else {
            this.isToggleType({
                type: this.state.typeSelectModal,
                callback: () => {
                    this.setState({
                        showModal: false,
                        date: {
                            date: new Date(),
                            obs: ''
                        },
                        loading: false
                    });
                }
            });
        }
    }

    isToggleType({ type, callback }: { type: 'URGENTE' | 'FINALIZADO' | 'INICIADO' | 'NAO INICIADO' | 'NAO URGENTE' | '', callback?: () => void }) {
        let situation = 0;
        switch (type) {
            case 'NAO INICIADO':
                situation = 4
                break;
            case 'INICIADO':
                situation = 1
                break;
            case 'URGENTE':
                situation = 2
                break;
            case 'NAO URGENTE':
                const prevSituation = this.state.prevSituations?.[this.state.prevSituations.length - 2]?.situation_chat_log_last_fk_situation_chat_id || 1;

                // console.log("debugs nao urgente", this.state.prevSituations);

                situation = prevSituation;
                break;
            case 'FINALIZADO':
                situation = 3
                break;
        }
        axios
            .get(`/api/v1/situation/chat/${situation}/${this.props.chatId}/false`)
            .then((response) => {
                if (response.data.message) {
                    Notification.success(
                        {
                            title: 'Alterado com Sucesso',
                            description: response.data.message
                        }
                    )
                    if (type == 'NAO INICIADO') {
                        this.props.submitValues({ date_message: `Marcado como Não Iniciado em: ${dayjs(this.state.date.date).format('DD/MM/YYYY HH:mm')}`, obs_message: this.state.date.obs })
                    }
                    if (type == 'FINALIZADO') {
                        this.props.submitValues({ date_message: `Finalizado em: ${dayjs(this.state.date.date).format('DD/MM/YYYY HH:mm')}`, obs_message: this.state.date.obs })
                    }
                    if (type === 'INICIADO') {
                        this.props.submitValues({ date_message: `Iniciado em: ${dayjs(this.state.date.date).format('DD/MM/YYYY HH:mm')}`, obs_message: this.state.date.obs })
                    }
                    if (type === 'NAO URGENTE') {
                        this.props.submitValues({ date_message: `Desmarcado como Urgente em: ${dayjs(this.state.date.date).format('DD/MM/YYYY HH:mm')}`, obs_message: this.state.date.obs })
                    }

                    if (type === 'URGENTE') {
                        this.props.submitValues({ date_message: `Marcado como Urgente em: ${dayjs(this.state.date.date).format('DD/MM/YYYY HH:mm')}`, obs_message: this.state.date.obs })
                    }
                }
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    Notification.error(
                        {
                            title: err.response.data.message,
                            description: err.response.data.error.stack
                        }
                    )
                    if (err.response.data.errorMessage) {
                        const { errorMessage } = err.response.data
                        for (const item of errorMessage) {
                            Notification.error(
                                {
                                    title: item.message,
                                    description: item.error
                                }
                            )
                        }
                    }
                }
            })
            .finally(() => {
                if (callback) {
                    callback()
                }
            })
    }

    render(): React.ReactNode {
        let disableAction = false;
        if (this.state.prevSituations) {
            if (this.state.prevSituations?.[this.state.prevSituations.length - 1]?.situation_chat_name === "FINALIZADO") {
                if (this.state.chatSituation !== 'nao iniciado') {
                    disableAction = true;
                }
            }
        }
        return (
            <>
                <Modal show={this.state.showModal} onHide={() => {
                    this.setState({ showModal: false, typeSelectModal: "" })
                }} size="xs">
                    <Modal.Header onHide={() => {
                        this.setState({ showModal: false, typeSelectModal: "" })
                    }} closeButton>
                        <Modal.Title>
                            Selecione uma data
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={
                            {
                                display: "flex",
                                flexDirection: "column",
                                gap: 10
                            }
                        }
                    >
                        <div>
                            <label>Data</label>
                            <DatePicker
                                value={this.state.date.date}
                                format="DD/MM/YYYY HH:mm"
                                style={
                                    {
                                        width: '100%'
                                    }
                                }
                                // onChange={(value) => console.log({value})}
                                onChange={(value) => this.setState({ date: { ...this.state.date, date: value } })}
                            />
                        </div>
                        <div>
                            <label>Observação</label>
                            <Input
                                onChange={(value) => this.setState({ date: { ...this.state.date, obs: value } })}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="green"
                            onClick={() => {
                                this.selectInModal()
                            }}
                            loading={this.state.loading}
                        >
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalAppointment}
                    size="sm"
                    onHide={() => {
                        this.setState({ showModalAppointment: false, showModal: false });
                    }}
                >
                    <Modal.Header onHide={() => {
                        this.setState({ showModalAppointment: false, showModal: false });
                    }} closeButton>
                        <Modal.Title>
                            <Icon
                                icon="remind"
                                style={{
                                    color: '#ffb300',
                                    fontSize: 18,
                                    marginTop: 6
                                }}
                            />
                            &nbsp;Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Essa fase não poderá ser finalizada, pois existe apontamento em andamento.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            appearance="subtle"
                            onClick={() => {
                                this.setState({ showModalAppointment: false, showModal: false });
                            }}
                            loading={this.state.loading}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Dropdown
                    placement="topStart"
                    onOpen={() => this.syncSituation()}
                    renderTitle={() => {
                        return <IconButton
                            appearance="primary"
                            style={
                                {
                                    backgroundColor: 'rgb(0, 179, 171)'
                                }
                            }
                            icon={<i className="fas fa-ellipsis-v"></i>} circle />;
                    }}
                >
                    {this.state.chatSituation && (
                        <Dropdown.Item
                            disabled={this.state.chatSituation === "nao iniciado" ? true : false}
                            onSelect={() => {
                                // this.isToggleType(
                                //     {
                                //         type: 'nao iniciado'
                                //     }
                                // )
                                this.setState({ showModal: true, typeSelectModal: 'NAO INICIADO' });
                            }}
                        >
                            Não iniciado
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item
                        disabled={(this.state.chatSituation === "iniciado" || disableAction) ? true : false}
                        onSelect={() => {
                            // this.isToggleType(
                            //     {
                            //         type: 'iniciado'
                            //     }
                            // )
                            this.setState({ showModal: true, typeSelectModal: 'INICIADO' });
                        }}
                    >
                        Iniciado
                    </Dropdown.Item>
                    <Dropdown.Item
                        disabled={(this.state.chatSituation === "urgente" || this.state.chatSituation === "finalizado") ? true : false}
                        onSelect={() => {
                            // this.isToggleType(
                            //     {
                            //         type: 'urgente'
                            //     }
                            // )
                            this.setState({ showModal: true, typeSelectModal: 'URGENTE' });
                        }}
                    >
                        {/* {JSON.stringify(this.props)} */}
                        Marcar como urgente
                    </Dropdown.Item>
                    {this.state.chatSituation === 'urgente' && (
                        <>
                            <Dropdown.Item
                                // disabled={(this.state.chatSituation === "nao urgente" || this.state.chatSituation === "finalizado") ? true : false}
                                onSelect={() => {
                                    // this.isToggleType(
                                    //     {
                                    //         type: 'Não Urgente'
                                    //     }
                                    // )
                                    this.setState({ showModal: true, typeSelectModal: 'NAO URGENTE' });
                                }}
                            >
                                Desmarcar como urgente
                            </Dropdown.Item>
                        </>
                    )}
                    <Dropdown.Item
                        disabled={(this.state.chatSituation === undefined || this.state.chatSituation === "finalizado" || this.state.chatSituation === "nao iniciado" || disableAction) ? true : false}
                        onSelect={() => {
                            this.setState({ showModal: true, typeSelectModal: 'FINALIZADO' });
                        }}
                    >
                        Finalizar
                    </Dropdown.Item>
                </Dropdown>
            </>
        )
    }
}