import React, { useContext } from "react";

type ChatProviderProps = {
    children: React.ReactNode;
}

type setPathUrlProps = {
    path?: string;
    labelValue?: string
    direction?: 'back' | 'front';
}

type ChatProviderState = {
    pathUrl: any[];
    setPathUrl: ({ path, direction }: setPathUrlProps) => void;
}

const initialState = {
    pathUrl: [],
    setPathUrl: () => null
}


const ChatProviderContext = React.createContext<ChatProviderState>(initialState);

const ChatProvider = ({ children, ...props }: ChatProviderProps) => {
    const [pathUrl, setPathUrl] = React.useState<any[]>([]);

    const value = {
        pathUrl,
        setPathUrl: ({ path, labelValue, direction = 'front' }: setPathUrlProps) => {
            if (direction === 'back') {
                pathUrl.pop()
                setPathUrl(pathUrl)
            } else {
                const newElement = {
                    label: path,
                    labelValue: labelValue
                };
                pathUrl.push(newElement);
                setPathUrl([...pathUrl]); 
            }
        }
    }


    return (
        <ChatProviderContext.Provider {...props} value={value} >
            {children}
        </ChatProviderContext.Provider>
    )
}


const useChatContext = () => {
    const context = useContext(ChatProviderContext);


    if (context === undefined) {
        throw new Error('useChatContext must be used within a ChatProvider')
    }

    return context;
}

export { ChatProvider, useChatContext, ChatProviderContext }