// import { Notification } from "rsuite";
import {io as socketio} from "socket.io-client";
import config from "../globals";

const io = socketio(config.url, {autoConnect:true});

io.on('alert',(msg : string) => {
    alert(msg);
})


export {io};