//IMPORTAR O COMPONENTE
import { CreateLambdaComplement } from "./CreateLambdaComplement";
import { CreateTablesComplement } from "./CreateTablesComplement";
import { TableComplement } from "./TableComplement";
import { TableGridLambda } from "./TableGridLambda";
import { ValidatePage } from './ValidatePage'
import { TableNewDownloader } from './TableNewDownloader'
import { ChatUserComponent } from "./chatUsers/ChatUserComponent";
import { ProjectProductFlowCustom } from "./ProjectProductFlowCustom";
import { ProjectProductGroupStepTableFlowsCustom } from "./ProjectProductGroupStepTableFlowsCustom";
import { CheckTreePickerUsersCustom } from "./CheckTreePickerUsersCustom";

export default {
    CreateLambdaComplement,
    CreateTablesComplement,
    TableComplement,
    TableGridLambda,
    ValidatePage,
    TableNewDownloader,
    ChatUserComponent,
    ProjectProductFlowCustom,
    ProjectProductGroupStepTableFlowsCustom,
    CheckTreePickerUsersCustom
    // EXPORTAR O COMPONENTE
};
