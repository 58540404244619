import { Notification } from "rsuite";
import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PersonCepNumberEvents extends Event {
    onBlur = (value: any, setField: any, setState: any) => {
        this.setState({
            loadings: [
                "person_address_street",
                "person_address_number",
                "person_address_complement",
                "person_address_neighborhood",
                "person_fk_state_id",
                "person_fk_city_id"
            ]
        });

        axios
            .get("/api/v1/search/cep", {
                params: {
                    cepNumber: value
                }
            })
            .then(async (res) => {
                if (res && res.data) {
                    const item = res.data.item;
                    setField("person_address_street", item.logradouro);
                    setField("person_address_complement", item.complemento);
                    setField("person_address_neighborhood", item.bairro);
                    setField("person_fk_state_id", item.state_id);
                    setField("person_fk_city_id", item.cities.city_id);
                }
            })
            .catch((e) => {
                Notification.error(e);
                setState("loadings", []);
            })
            .finally(() => {
                setState("loadings", []);
            });
    };
}
