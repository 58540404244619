import React from "react";
import { Route, withRouter } from "react-router";
import { ChatContext } from "../authProvider/main";

class ChatProtectRouter extends React.Component<any, any> {
    static contextType = ChatContext;

    componentDidMount(): void {
        // const arrayLength = this.props.location.pathname.split('/')
        // if(arrayLength.length == 3) {
        //     // const isToken = this.props.location.pathname.split('/web-chat/').pop();
        //     // if(isToken) {
        //     //     this.setState({isToken: isToken});
        //     //     localStorage.setItem('chatToken',isToken)
        //     // }
        // }
    }

    render() {
        // console.log('this :::> ', this)
        // if(!this.context.isAuthenticated) {
        //     return null
        // }
        return <Route {...this.props}></Route>;
    }
}

export default withRouter(ChatProtectRouter);
