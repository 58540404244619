import React from "react";
import { Avatar, Button, Container, Content, Modal, Placeholder } from "rsuite";
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import { ModalInfoChatStatus } from "../components/ModalInfoChatStatus";
import { AxiosError, AxiosResponse } from "axios";
import { ChatClientRender } from "../components/ChatClientRender";


interface ReadListItemsProps {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    previousRoute: ({ route, dataParams }: { route: string, dataParams: Record<string, any> }) => void;
    paramsRoute: Record<string, any>;
}

interface ReadListItemsState {
    data: [],
    showModal: boolean,
    modalInfoData: [],
    loading: boolean,
    chatClients: any[]
}

export class ReadListItems extends React.Component<ReadListItemsProps, ReadListItemsState> {
    constructor(props: ReadListItemsProps) {
        super(props);
        this.state = {
            data: [
            ],
            showModal: false,
            modalInfoData: [],
            loading: false,
            chatClients: []
        }
        this.selectChatClient = this.selectChatClient.bind(this)
    }

    componentDidMount(): void {
        axios
            .get(`/api/v1/project_products/${this.props.paramsRoute.project_id}/get`)
            .then((response) => {
                this.setState({ data: response.data.item })
            })
    }

    handleOpenInfo = ({ item }: { item: Record<string, any> }) => {
        this.setState({ loading: true })

        axios.get(`/api/v1/steps/${item.project_product_id}/${item.project_product_fk_product_id}/get`)
            .then((response) => {
                this.setState({ modalInfoData: response.data.item })
            })
            .finally(() => {
                this.setState({ loading: false, showModal: true })
            })
    }


    TwentyTimesParagraphs = () => {
        // Cria um array com 20 elementos vazios
        const array = Array.from({ length: 20 });

        return (
            <>
                {array.map((_, index) => (
                    <Placeholder.Paragraph key={index} graph='square' active />
                ))}
            </>
        );
    };

    selectItem = ({ data }: { data: Record<string, any> }) => {
        this.props.nextRouter(
            {
                route: 'chatList',
                paramsRoute: {
                    project_product_id: data.project_product_id,
                    project_product_fk_product_id: data.project_product_fk_product_id,
                    project_id: this.props.paramsRoute.project_id,
                    product_id: data.product_id,
                    pathUrl: [
                        ...this.props.paramsRoute.pathUrl,
                        {
                            labelCrud: 'Itens',
                            labelValue: data.product_name,
                            value: 1
                        }
                    ],
                    data
                }
            }
        )
    }

    selectChatClient = ({ data }: { data: Record<string, any> }) => {
        this.props.nextRouter(
            {
                route: 'chatPage',
                paramsRoute: {
                    step_item: {
                        ...data.steps[0]
                    },
                    step_id: data.steps[0].step_id,
                    project_id: this.props.paramsRoute.project_id,
                    pathUrl: [
                        ...this.props.paramsRoute.pathUrl,
                        {
                            labelCrud: 'Chats',
                            labelValue: data.steps[0].step_name,
                            value: 2
                        }
                    ]
                }
            }
        )
    }

    renderProjects = () => {
        return this.state.data.map((value: any, index) => {
            return (
                <div
                    style={
                        {
                            width: '100%',
                            minHeight: '4rem',
                            borderRadius: 10,
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: '#131047',
                            cursor: 'pointer'
                        }
                    }
                    onClick={() => this.selectItem({ data: value })}
                >
                    <div
                        style={
                            {
                                width: '85%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                flexWrap: 'wrap',
                                background: '#f5f5f5',
                                paddingLeft: 10,
                                borderRadius: 10,
                                // alignItems: "center",
                            }
                        }
                    >
                        <div style={{ display: "flex", flexDirection: "row" }}>

                            <Avatar
                                src={`http://${window.location.host.split('25565').join('25567')}${value.product_file[0]?.file_path}`}
                                circle
                            />

                            <div style={{ display: "flex", flexDirection: "column",marginLeft:12 }}>

                                <label>Item</label>

                                <h6>
                                    {value.product_name}
                                </h6>
                            </div>

                        </div>

                    </div>
                    <div
                        style={
                            {
                                width: '16%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }
                        }
                    >
                        <i className="far fa-box fa-2x " style={{ color: '#fff' }}></i>
                        <p
                            style={{ color: "#fff" }}
                        >
                            Chat
                            {/* 0 */}
                        </p>
                    </div>
                </div>
            )

            // return (
            //     <div
            //         style={
            //             {
            //                 width: '100%',
            //                 minHeight: '12rem',
            //                 background: '#e9e9e9',
            //                 borderRadius: 10,
            //                 display: 'grid',
            //                 gridTemplateRows: '2fr 1fr',
            //                 padding: 10,
            //             }
            //         }
            //     >
            //         <div
            //             style={
            //                 {
            //                 }
            //             }
            //         >
            //             <div>
            //                 <Avatar
            //                     src={`http://${window.location.host.split('25565').join('25567')}${value.product_file[0]?.file_path}`}
            //                     circle
            //                 />
            //             </div>
            //             <div>
            //                 <label>Produto</label>
            //                 <h6>
            //                     {value.product_name}
            //                 </h6>
            //             </div>
            //         </div>
            //         <div
            //             style={
            //                 {
            //                     display: "flex",
            //                     justifyContent: "center",
            //                     alignItems: "center",
            //                     alignContent: "center",
            //                 }
            //             }
            //         >
            //             <Button
            //                 loading={this.state.loading}
            //                 color="blue"
            //                 style={
            //                     {
            //                         width: '100%',
            //                         height: '2rem'
            //                     }
            //                 }
            //                 onClick={() => this.selectItem({ data: value })}
            //             >
            //                 Acessar
            //             </Button>
            //             {/* <ModalInfoChatStatus
            //                 onClose={() => this.setState({ showModal: false })}
            //                 params={
            //                     {
            //                         project_product_id: value.project_product_id,
            //                         project_product_fk_product_id: value.project_product_fk_product_id,
            //                     }
            //                 }
            //                 typeCall="webUser"
            //             /> */}
            //         </div>
            //     </div>
            // )
        })
    }

    render(): React.ReactNode {
        return (
            <>

                <HeaderComponent
                    // nextRouter={this.props.nextRouter}
                    previousRoute={this.props.previousRoute}
                    dataParams={this.props.paramsRoute}
                    routePrevious="projects"
                />
                <Content
                    style={
                        {
                            // display: 'flex',
                            // margin: 'auto',
                            paddingTop: 10,
                            justifyContent: 'center',
                            alignContent: 'center'
                        }
                    }
                >
                    <div
                        style={
                            {

                                margin: 'auto',
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    display: 'flex',
                                    gap: 30,
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    justifyContent: 'start',
                                }
                            }
                        >
                            {this.state.data.length > 0 ? (
                                <>
                                    <div
                                        style={
                                            {
                                                display: 'flex',
                                                gap: 5,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                justifyContent: 'start',
                                            }
                                        }
                                    >
                                        {this.renderProjects()}
                                    </div>
                                    <ChatClientRender
                                        paramsRoute={this.props.paramsRoute}
                                        typeCall="webUser"
                                        selectItem={this.selectChatClient}
                                    />
                                </>
                            ) : (
                                <>
                                    <Placeholder.Paragraph rows={12} active />
                                    {/* {this.TwentyTimesParagraphs()} */}
                                </>
                            )}
                        </div>

                    </div>
                </Content>
            </>
        )
    }
}