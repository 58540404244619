import React from "react";
import { Panel } from "rsuite";
import { CardChatPage } from "./CardChatPage";

export class CardChatComponent extends React.Component<any, {}> {
    state = {
        pathRoute: 'chatPage',
        paramsRoute: {},
        loadings: false
    }

    constructor(props: any) {
        super(props);
        // Vinculando o método ao contexto do componente
        this.nextRouter = this.nextRouter.bind(this);
        this.previousRoute = this.previousRoute.bind(this);
    }

    componentDidMount(): void {
        this.mountedRouterInitial()
    }

    mountedRouterInitial() {

    }

    nextRouter({ route, paramsRoute }: { route: string, paramsRoute: Record<string, any> }) {
        this.setState(
            {
                pathRoute: route,
                paramsRoute: paramsRoute,
            }

        )
    }

    previousRoute({ route, dataParams }: { route: string, dataParams: Record<string, any> }) {
        dataParams.pathUrl.pop()
        this.setState(
            {
                pathRoute: route,
                paramsRoute: dataParams
            }
        )
    }


    renderComponentRoute = () => {
        const output: any = [];
        let { pathRoute, paramsRoute } = this.state;
        if (pathRoute === "chatPage") {
            output.push(
                <CardChatPage
                    // previousRoute={this.previousRoute}
                    paramsRoute={this.props.item}
                />
            )
        }

        return output;
    }

    render(): React.ReactNode {
        return (
            // <Container
            //     style={
            //         {
            //             // height: '75vh',
            //             width: "100%",
            //             gap: '10'
            //         }
            //     }
            // >
            //     {/* <Content>
            //         <HeaderComponent 
            //             routePrevious="projects"
            //             dataParams={{}}
            //             previousRoute={this.previousRoute}
            //         />
            //     </Content> */}
            //     <Content>
            //     </Content>
            // </Container>
            <Panel shaded>
                {this.renderComponentRoute()}
            </Panel>
        )
    }
}