import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import axios from "../../../../../utilities/axios";
import axiosClientChat from "../../../../../utilities/axiosClientChat";

interface ChatClientRenderProps {
    typeCall: 'webUser' | 'webPerson';
    paramsRoute: Record<string, any>;
    style?: React.CSSProperties
    selectItem: ({ data }: { data: Record<string, any> }) => void
}


type State = {
    chatClients: any[];
    loadings: boolean;
}

export class ChatClientRender extends React.Component<ChatClientRenderProps, State> {
    state = {
        chatClients: [],
        loadings: false,
    }

    componentDidMount(): void {
        if (this.props.typeCall === 'webUser') {
            axios
                .get(`/api/v1/chats/get/client`, {
                    params: {
                        project_id: this.props.paramsRoute.project_id
                    }
                })
                .then((response: AxiosResponse) => {
                    this.setState({ chatClients: response.data.items })
                })
                .catch((e: AxiosError) => {

                })
                .finally(() => {
                    this.setState({ loadings: false })
                })
        }
        if (this.props.typeCall === "webPerson") {
            axiosClientChat
                .get(`/chat-client/get/client`, {
                    params: {
                        project_id: this.props.paramsRoute.projectId
                    }
                })
                .then((response: AxiosResponse) => {
                    this.setState({ chatClients: response.data.items })
                })
                .catch((e: AxiosError) => {

                })
                .finally(() => {
                    this.setState({ loadings: false })
                })
        }
    }


    render(): React.ReactNode {
        return (
            <div
                style={this.props.style}
            >
                {!this.state.loadings && this.state.chatClients.length > 0 && (
                    <>
                        {this.state.chatClients.map((value: Record<string, any>) => {
                            return (

                                <div
                                    style={
                                        {
                                            width: '100%',
                                            minHeight: '4rem',
                                            borderRadius: 10,
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            backgroundColor: value?.steps[0].situation_chat_color ? value?.steps[0].situation_chat_color : '#131047',
                                            cursor: 'pointer'
                                        }
                                    }
                                    onClick={() => this.props.selectItem({ data: value })}
                                >
                                    <div
                                        style={
                                            {
                                                width: '85%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: "center",
                                                flexWrap: 'wrap',
                                                background: '#f5f5f5',
                                                paddingLeft: 10,
                                                borderRadius: 10,
                                                // alignItems: "center",
                                            }
                                        }
                                    >
                                        <h6>
                                            {value.steps[0].step_name}
                                        </h6>
                                        <p
                                            style={{
                                                fontSize: 13,
                                                color: value.steps[0]?.delay_date?.color ? value.steps[0]?.delay_date?.color : '#131047'
                                                // color: 'yellow'
                                            }}
                                        >
                                            {value.steps[0].delay_date?.message}
                                        </p>
                                    </div>
                                    <div
                                        style={
                                            {
                                                width: '16%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }
                                        }
                                    >
                                        <i className="far fa-comments fa-2x " style={{ color: '#fff' }}></i>
                                        <p
                                            style={{ color: "#fff" }}
                                        >
                                            {value?.steps[0].messages_not_viewed?.count ? value?.steps[0].messages_not_viewed?.count : 0}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}
            </div>
        )
    }
}