import React from "react";
import { Button, Dropdown, Icon, Notification } from "rsuite";
import axios from "../../../utilities/axios";

interface PrintProjectComponentProps {
    parentProps: any;
    parentState: any;
}


interface PrintProjectComponentState {
    loading: boolean;
}

class ProjectComponent extends React.Component<PrintProjectComponentProps, PrintProjectComponentState> {
    state: PrintProjectComponentState = {
        loading: false,
    }

    printProjects = () => {
        const { parentState } = this.props
        if (parentState.checked.length < 1) {
            Notification.info(
                {
                    title: "Aviso",
                    description: "Não é possível imprimir sem nenhum projeto selecionado!"
                }
            )
            return;
        }
        this.setState({ loading: true });
        for (const i in parentState.checked) {
            console.log("debugs", parentState.checked[i])
            this.setState({ loading: true });
            axios.get(`/api/v1/projects/reports/print-project/${parentState.checked[i]}`).then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            }).catch((e) => {
                throw ("Erro");
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    printProjectsDetailed = () => {
        const { parentState } = this.props
        if (parentState.checked.length < 1) {
            Notification.info(
                {
                    title: "Aviso",
                    description: "Não é possível imprimir sem nenhum projeto selecionado!"
                }
            )
            return;
        }
        this.setState({ loading: true });
        for (const i in parentState.checked) {
            console.log("debugs", parentState.checked[i])
            this.setState({ loading: true });
            axios.get(`/api/v1/projects/reports/print-project-detailed/${parentState.checked[i]}`).then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            }).catch((e) => {
                throw ("Erro");
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    render(): React.ReactNode {
        return (
            <Dropdown
                disabled={this.state.loading}
                title="topEnd"
                placement="topEnd"
                trigger="hover"
                renderTitle={children => {
                    return (
                        <Button
                            color="red"
                            onClick={this.printProjects}
                            style={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 5
                                }
                            }
                            loading={this.state.loading}
                        >
                            <i className="fas fa-file"></i>
                            Imprimir PDF
                        </Button>
                    )
                }}>
                <Dropdown.Item
                    disabled={this.state.loading}
                    onClick={this.printProjects}
                >
                    <Icon icon="file-o" /> Resumido
                </Dropdown.Item>
                <Dropdown.Item divider />
                <Dropdown.Item
                    disabled={this.state.loading}
                    onClick={this.printProjectsDetailed}
                >
                    <Icon icon="file-text" /> Detalhado
                </Dropdown.Item>
            </Dropdown>
        )
    }
}

export class ProjectBrowseEvents {
    onRenderFooterBrowserlize = (props: any, state: any) => {
        return <ProjectComponent parentProps={props} parentState={state} />;
    }
}