import React from "react"
import { withRouter } from "react-router-dom"
import { Button, Container, Content, FlexboxGrid, Header, Notification } from "rsuite"
import axiosClientChat from "../../utilities/axiosClientChat"
import config from "../../globals"
import { Placeholder } from 'rsuite';
import { HeaderComponente } from "../../components/chatClient/Header"
import { ChatProviderContext } from "../../components/chatClient/chatProvider/main"
import { ModalInfo } from "./browserPageComponents/ModalInfo"
import { ModalComplementInfo } from "./browserPageComponents/ModalComplementInfo"

const { Paragraph } = Placeholder;


type TSizeTypes = "sm" | 'md' | "lg";


interface BrowsePageChatState {
    data: any[],
    loading: boolean,
    sizeWindow: TSizeTypes,
    modalComplementInfoProps: {
        show: boolean,
        data: any
    }
}

class BrowsePageChat extends React.Component<any, BrowsePageChatState> {
    static contextType = ChatProviderContext;
    private holdTimer: NodeJS.Timeout | null = null;
    private holdDuration = 1000;
    state: BrowsePageChatState = {
        data: [
        ],
        loading: true,
        sizeWindow: 'sm',
        modalComplementInfoProps: {
            show: false,
            data: {}
        }
    }

    componentDidMount(): void {
        this.updateSize()
        axiosClientChat.get(`/chat-client/project/list`)
            .then(async (response) => {
                this.setState({ data: response.data.items })
            })
            .catch((e) => {
                // Notification.error(
                //     {
                //         title: 'Ops, parece que algo deu errado!',
                //         description: e.response.data.message
                //     }
                // )
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    updateSize() {
        let size: TSizeTypes = 'sm'
        const sizeWindow = window.innerWidth;
        if (sizeWindow > 768) {
            size = 'md'
        }
        this.setState(
            {
                sizeWindow: size
            }
        )
    }


    selectItem = ({ data }: { data: any }) => {
        const { setPathUrl } = this.context;
        setPathUrl(
            {
                path: 'Projeto',
                labelValue: data.project_code
            }
        )
        const currentRouteSplited = this.props.history.location.pathname.split("/");
        const currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");
        this.props.history.push(`${currentRoute}/item/${data.project_id}`)
    }

    TwentyTimesParagraphs = () => {
        // Cria um array com 20 elementos vazios
        const array = Array.from({ length: 20 });

        return (
            <>
                {array.map((_, index) => (
                    <Paragraph key={index} graph='square' />
                ))}
            </>
        );
    };


    handleMouseAndTouchDow = (params: {data: any}) => {
        const {data} = params;
        this.holdTimer = setTimeout(() => {
            this.onLongPress({ data });
        }, this.holdDuration)
    }

    handleMouseAndTouchUp = () => {
        if(this.holdTimer) {
            clearTimeout(this.holdTimer);
            this.holdTimer = null;
        }
    }

    onLongPress = (params: {data: any}) => {
        this.setState(
            {
                modalComplementInfoProps: {
                    show: true,
                    data: params.data,
                }
            }
        )
    }

    onCLose = () => {
        this.setState(
            {
                modalComplementInfoProps: {
                    show: false,
                    data: {}
                }
            }
        )
    }



    renderProjects = () => {
        return this.state.data.map((value: any, index) => {
            return (
                <div
                    style={
                        {
                            width: '95%',
                            minHeight: '2rem',
                            borderRadius: 10,
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: '#131047',
                            cursor: 'pointer',
                            userSelect: 'none',
                            WebkitUserSelect: 'none', // Para Safari
                        }
                    }
                    onClick={() => this.selectItem({ data: value })}
                    onMouseDown={() => this.handleMouseAndTouchDow({ data: value })}
                    onMouseUp={this.handleMouseAndTouchUp}
                    onTouchStart={() => this.handleMouseAndTouchDow({ data: value })}
                    onTouchEnd={this.handleMouseAndTouchUp}
                >
                    <div
                        style={
                            {
                                width: '85%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                flexWrap: 'wrap',
                                background: '#f5f5f5',
                                paddingLeft: 10,
                                borderRadius: 10,
                                // alignItems: "center",
                                padding: '20px',
                                userSelect: "none",
                                WebkitUserSelect: 'none', // Para Safari
                            }
                        }
                    >
                        {['md', 'lg'].includes(this.state.sizeWindow) ? (
                            <FlexboxGrid>
                                <FlexboxGrid.Item order={1} colspan={8}>
                                    <div >
                                        <label>Projeto</label>
                                        <h6>
                                            {value.project_code} - {value?.project_description}
                                            {/* - {value.companies.company_fantasy} */}
                                        </h6>
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item order={3} colspan={8}>
                                    <div >
                                        <label>Endereço</label>
                                        <h6>
                                            {value?.project_street} , {value?.project_number} {value?.project_district} - {value?.cities?.city_name}-{value?.states?.state_name}
                                        </h6>

                                    </div>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : (
                            <>
                                <div
                                    style={
                                        {
                                            display: 'flex',
                                            flexDirection: "column",
                                        }
                                    }
                                >
                                    <label>Projeto</label>
                                    <h6>
                                        {value.project_code} - {value?.project_description}
                                    </h6>
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        style={
                            {
                                width: '16%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                userSelect: 'none',
                                WebkitUserSelect: 'none', // Para Safari
                            }
                        }
                    >
                        <i className="far fa-comments fa-2x " style={{ color: '#fff' }}></i>
                        <p
                            style={{ color: "#fff" }}
                        >
                            {/* 0 */}
                        </p>
                    </div>
                </div>
            )
            // return (
            //     <div
            //         style={
            //             {
            //                 width: '100%',
            //                 height: '100%',
            //                 background: '#e9e9e9',
            //                 borderRadius: 10,
            //                 display: 'grid',
            //                 gridTemplateRows: '25px 1fr 30px',
            //                 padding: 8,
            //             }
            //         }
            //     >
            //         {/* <h6>
            //             {value.project_code}
            //         </h6> */}
            //         <div
            //             style={
            //                 {
            //                     display: 'flex',
            //                     justifyContent: 'end',

            //                 }
            //             }
            //         >
            //             <i className="fas fa-info-circle"></i>
            //         </div>
            //         <div
            //             style={
            //                 {
            //                     // background: 'blue',
            //                     alignContent: "center",
            //                 }
            //             }
            //         >
            //             <FlexboxGrid>
            //                 <FlexboxGrid.Item order={1} colspan={12}>
            //                     <div >
            //                         <label>Projeto</label>
            //                         <h6>
            //                             {value.project_code} - {value?.project_description}
            //                             {/* - {value.companies.company_fantasy} */}
            //                         </h6>
            //                     </div>
            //                 </FlexboxGrid.Item>
            //                 {/* <FlexboxGrid.Item order={2} colspan={8}>
            //                     <div >
            //                         <label>Cliente</label>
            //                         <h6>
            //                             {value?.people?.person_name}
            //                         </h6>
            //                     </div>
            //                 </FlexboxGrid.Item> */}
            //                 <FlexboxGrid.Item order={3} colspan={10}>
            //                     <div >
            //                         <label>Endereço</label>
            //                         <h6>
            //                         {value?.project_street} , {value?.project_number} {value?.project_district} - {value?.cities?.city_name}-{value?.states?.state_name}
            //                         </h6>

            //                     </div>
            //                 </FlexboxGrid.Item>
            //             </FlexboxGrid>
            //             {/* <div>
            //                 <label>Projeto</label>
            //                 <h6>
            //                     {value.project_code}
            //                 </h6>                            
            //             </div> */}
            //         </div>
            //         <div
            //             style={
            //                 {
            //                     display: "flex",
            //                 }
            //             }
            //         >
            //             <Button
            //                 color="blue"
            //                 style={
            //                     {
            //                         width: '100%'
            //                     }
            //                 }
            //                 onClick={() => this.selectItem({ data: value })}
            //             >
            //                 Acessar
            //             </Button>
            //         </div>
            //     </div>
            // )
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Container
                    style={
                        {
                            gap: 10
                        }
                    }
                >
                    <ModalComplementInfo
                        dataModal={this.state.modalComplementInfoProps.data}
                        showModal={this.state.modalComplementInfoProps.show}
                        onCLoseModal={this.onCLose.bind(this)}
                    />
                    <HeaderComponente
                        title="Chats"
                        useBack={false}
                        useInfo={true}
                        renderUseInfo={
                            <ModalInfo

                            />
                        }
                    />
                    <Content
                        style={
                            {
                                // display: 'flex',
                                // margin: 'auto',
                                paddingTop: 10,
                                justifyContent: 'center',
                                alignContent: 'center'
                            }
                        }
                    >
                        <div
                            style={
                                {

                                    margin: 'auto'
                                }
                            }
                        >
                            <div
                                style={
                                    {
                                        display: 'flex',
                                        gap: 5,
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                    }
                                }
                            >
                                {!this.state.loading ? (
                                    <>
                                        {this.state.data.length == 0 ? (
                                            <div
                                                style={
                                                    {
                                                        display: 'flex',
                                                        // height: '50vh',
                                                        justifyContent: 'center',
                                                        alignItems: "center",
                                                        gap: 5,
                                                        // flexWrap: 'wrap',
                                                        // textAlign: "center",
                                                        // // background: '#131047',
                                                        // // background:"gray",
                                                        // padding: 15,
                                                        // borderRadius: 20,
                                                        // boxShadow: '2px 2px 12px 5px rgba(0, 0, 0, 0.2)',
                                                        // color: '#fff',
                                                        // width:"100%"
                                                    }
                                                }
                                            >
                                                <h6 style={{ color: "black" }}>
                                                    Nenhum Projeto Encontrado!
                                                </h6>
                                                {/* <h1>
                                                Sem Projetos para esse Usuario
                                            </h1>
                                            <i className="fas fa-times fa-5x"></i> */}
                                            </div>
                                        ) : (
                                            <>

                                                {['sm'].includes(this.state.sizeWindow) && (
                                                    <div
                                                        style={
                                                            {
                                                                backgroundColor: '#e9e9e9',
                                                                padding: 10,
                                                                borderRadius: 10,
                                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                                            }
                                                        }
                                                    >
                                                        <p>Segure o card para acessar info. complementares</p>
                                                    </div>
                                                )}
                                                {this.renderProjects()}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Placeholder.Graph active rows={12} />
                                        {/* {this.TwentyTimesParagraphs()} */}
                                    </>
                                )}
                            </div>

                        </div>
                    </Content>
                </Container>
            </>
        )
    }
}

export default withRouter(BrowsePageChat)