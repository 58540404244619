import '@asseinfo/react-kanban/dist/styles.css';
import dayjs from "dayjs";
import { Button, ControlLabel, DateRangePicker, Loader, Message, Panel, Placeholder, SelectPicker, Tag, Toggle } from "rsuite";
import { io } from '../../../utilities/io';
//@ts-ignore
import Board from "@asseinfo/react-kanban";
import React, { SyntheticEvent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CheckTreePicker } from '../../../components/formfields/CheckTreePicker';
import axios from '../../../utilities/axios';
import { KanbanCard } from './KanbanCard';
import { UserContext } from '../../../components/authProvider/main';
import _ from 'lodash';
import { locale } from '../../../utilities/locale/DateRangePicker';

export class KanbanPage extends React.Component<any, {}> {
    static contextType = UserContext;

    public state: any = {
        data: { kanbans: {} },
        groups: [],
        steps: [],
        projects: [],
        filter: [],
        isLoading: true,
        isLoadingClean: false,
        isLoadingKanban: false,
        isDrawerOpen: false,
        viewCard: null,
        receiver: false
    };

    componentDidMount(): void {
        this.getData();
        this.getGroupsData();

        if (this.state.data?.kanbans?.length <= 0) {
            io.on("kanban:receiver", async (data: any) => {
                if (data.company_id === this.props.companyId) {
                    this.setState({ receiver: dayjs().unix() });
                    this.getData();
                }
            });
        }
    }

    componentDidUpdate(): void {
        if (this.state.data?.kanbans?.length <= 0) {
            io.on("kanban:receiver", async (data: any) => {
                if (data.company_id === this.props.companyId) {
                    this.setState({ receiver: dayjs().unix() });
                    this.getData();
                    this.getGroupsData();
                }
            });
        }
    }

    getData = () => {
        // if (this.state.groups?.length > 0) {
        this.setState({ isLoading: true, isLoadingKanban: true });
        axios.post(`/api/v1/kanban-get-map`, {
            params: {
                ...this.state.filter
            }
        }).then((res: any) => {
            this.setState({ data: res?.data });
        }).finally(() => {
            this.setState({ isLoading: false, isLoadingKanban: false });
        });
        // }
    }

    getGroupsData = () => {
        axios.get(`/api/v1/groups/select${this.state.filter.projects?.length > 0 ? `?project_id=[${this.state.filter.projects}]` : ''}${this.state.filter.products?.length > 0 ? `&product_id=[${this.state.filter.products}]` : ''}`).then((res: any) => {
            const items = res.data.items;
            this.setState({ groups: items });
        }).finally(() => {

        });
    }

    selectFilter = (field: any, value: any) => {
        this.setState({ filter: { ...this.state.filter, [field]: value } }, this.getData);
    }

    cleanAllFilters = () => {
        this.setState({ isLoadingClean: !this.state.isLoadingClean });
    }

    renderKanbanCard = () => {
        return (
            <KanbanCard
                item={this.state.viewCard}
                open={this.state.isDrawerOpen}
                onClose={() => this.setState({ isDrawerOpen: false })}
            />
        )
    }

    render(): React.ReactNode {
        const { data, filter, isLoading, isLoadingKanban, isLoadingClean } = this.state;
        const kanbanData = { ...data };

        return (
            <>
                <div style={{ marginBottom: 15 }}>
                    <div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
                        <div style={{ width: "40%" }}>
                            <ControlLabel>Projetos</ControlLabel>
                            <CheckTreePicker
                                name="projects"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/projects/select?sortBy=project_id&sortType=desc${filter.products?.length > 0 ? `&product_id=[${filter.products}]` : ''}${filter.groups?.length > 0 ? `&group_id=[${filter.groups}]` : ''}`,
                                }}
                                displayLabel="projects.project_code + ' - ' + projects.project_description"
                                value={filter.projects || []}
                                isLoadingClean={isLoadingClean}
                                onChange={(value: any) => this.selectFilter("projects", value)}
                                onClean={() => this.cleanAllFilters()}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "40%", marginLeft: 6 }}>
                            <ControlLabel>Itens</ControlLabel>
                            <CheckTreePicker
                                name="products"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/products/select${filter.projects?.length > 0 ? `?project_id=[${filter.projects}]` : ''}${filter.groups?.length > 0 ? `${filter.projects?.length > 0 ? "&" : "?"}group_id=[${filter.groups}]` : ''}`,
                                }}
                                displayLabel="products.product_name"
                                value={filter.products || []}
                                isLoadingClean={isLoadingClean}
                                onChange={(value: any) => this.selectFilter("products", value)}
                                onClean={() => this.cleanAllFilters()}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "40%", marginLeft: 6 }}>
                            <ControlLabel>Grupo de Fases</ControlLabel>
                            {/* <SelectPicker
                                placeholder="Selecione..."
                                style={{ width: "100%" }}
                                value={filter.group_id}
                                // defaultValue={groups?.[0]?.value}
                                onChange={(value: any) => this.selectFilter("group_id", value)}
                                onOpen={() => this.getGroupsData()}
                                data={this.state.groups ?? []}
                                onClean={() => this.cleanAllFilters()}
                            /> */}

                            <CheckTreePicker
                                name="groups"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/groups/select${filter.projects?.length > 0 ? `?project_id=[${filter.projects}]` : ''}${filter.products?.length > 0 ? `&product_id=[${filter.products}]` : ''}`
                                }}
                                displayLabel="groups.group_name"
                                value={filter.groups || []}
                                isLoadingClean={isLoadingClean}
                                onChange={(value: any) => this.selectFilter("groups", value)}
                                onClean={() => this.cleanAllFilters()}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Exibir Arquivados?</ControlLabel>
                            <Toggle
                                defaultChecked={filter.show_exclude ?? false}
                                checked={filter.show_exclude ?? false}
                                onChange={(value: boolean) => this.selectFilter("show_exclude", value)}
                                style={{ width: "100%" }}
                            ></Toggle>
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Data Inicial e Data Final da Criação do Projeto</ControlLabel>
                            <DateRangePicker
                                preventOverflow
                                locale={locale}
                                onClean={() => this.selectFilter("date", null)}
                                placement="bottomEnd"
                                format="DD/MM/YYYY"
                                onChange={(value: any) => this.selectFilter("date", value)}
                            />
                        </div>
                    </div>
                </div>

                {
                    isLoading && (
                        <div style={{ width: "100%", marginBottom: 15 }}>
                            <Placeholder.Paragraph rows={12} />
                        </div>
                    )
                }

                {
                    !isLoading && data && (
                        <>
                            {_.isEmpty(data?.kanbans) && (
                                <Message type="info" showIcon description={data.message ? data.message : "Não existem projetos com o filtro aplicado"} />
                            )}
                            {!_.isEmpty(data?.kanbans) && (
                                <Panel bordered bodyFill>
                                    {isLoadingKanban && (
                                        <Loader backdrop content="Carregando..." size="lg" />
                                    )}
                                    {Object.keys(data?.kanbans)?.map(groupId => (
                                        <div key={groupId}>
                                            {/* <p>{JSON.stringify(data?.kanbans[groupId.toString()])}</p> */}
                                            {/* <h3>Group: {groupId}</h3> */}
                                            {data?.kanbans?.[groupId.toString()] && (
                                                <Board
                                                    key={groupId}
                                                    style={{ width: "100%", marginBottom: 30 }}
                                                    disableColumnDrag
                                                    disableCardDrag
                                                    renderColumnHeader={(column: any) =>
                                                        <div style={{
                                                            padding: '10px',
                                                            backgroundColor: "#00b3ab",
                                                            borderBottom: '2px solid #ddd'
                                                        }}>
                                                            <p style={{ margin: 0, color: "#FFF" }}>{column.title}</p>
                                                        </div>
                                                    }
                                                    renderCard={(item: any, index: number) => {
                                                        return (<div className='kanbanAdjustment2'>
                                                            <div className="card-a card-a-custom" style={{ background: "white" }}>
                                                                <div className="card-header-a" style={{ background: item.situation_chat_color }}>
                                                                    <h6 className="card-title-a">{item?.project_code}<Button
                                                                        appearance="primary"
                                                                        color="blue"
                                                                        size='sm'
                                                                        style={{ float: "right", marginTop: -10 }}
                                                                        onClick={() => this.setState({ viewCard: item, isDrawerOpen: true })}>
                                                                        <i className="fas fa-eye"></i>
                                                                    </Button></h6>
                                                                </div>
                                                                <div className='card-body-a'>
                                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                                        <strong>Cliente</strong><br />
                                                                        <Link
                                                                            key={index}
                                                                            target={"_blank"}
                                                                            to={`/dashboard/configuracoes/people/view/${item?.project_client_fk_person_id}`}
                                                                        >
                                                                            {item?.cliente}
                                                                            <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                                        </Link>
                                                                    </p>
                                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                                        <strong>Descrição</strong><br />
                                                                        <Link
                                                                            key={index}
                                                                            target={"_blank"}
                                                                            to={`/dashboard/project/projects/view/${item?.project_product_fk_project_id}`}
                                                                        >
                                                                            {item?.title} - {item?.product_name}
                                                                            <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                                        </Link>
                                                                    </p>
                                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                                        <strong>Status</strong><br />
                                                                        <Tag style={{ backgroundColor: item.situation_chat_color, color: "#FFF" }}>
                                                                            {item.situation_chat_name}
                                                                        </Tag>
                                                                    </p>
                                                                    {item.situation_chat_name !== "FINALIZADO" && (
                                                                        <p className='break-word'>
                                                                            <strong>Prazo</strong><br />
                                                                            {item?.project_deadline_project} Dias
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    }}
                                                >
                                                    {data?.kanbans?.[groupId.toString()]}
                                                </Board>
                                            )}
                                        </div>
                                    ))}
                                </Panel>
                            )}
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(KanbanPage);