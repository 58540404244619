import _ from "lodash";
import axios from "../../../utilities/axios"
import { Event } from "../../Event"

export class ProjectProductFkProductIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        const uuidv4 = () => {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }

        const flow_id = value;
        if (flow_id) {
            this.setState('loadings', [
                'project_product_fk_product_id',
                'project_product_group_step_table_flows',
                'project_product_quantity'
            ]);

            await axios
                .get(`/api/v1/products/${flow_id}`)
                .then(async (res) => {
                    if (res && res.data) {
                        const group_id = res.data?.item.product_fk_group_id;
                        const product_type_id = res.data?.item.product_type_fk_product_type_id;
                        // console.log({ group_id })

                        if (product_type_id === 1) {
                            this.setField('project_product_quantity', {
                                hidden: false,
                                required:true,
                                
                            });
                            this.setFieldValue('project_product_quantity',1)

                            // console.log("debugs globalProps", this.globalProps.form.project_product_quantity)
                            this.globalProps.form.project_product_quantity.canBrowse = true;
                        } else {
                            this.setField('project_product_quantity', {
                                hidden: true
                            });
                            this.globalProps.form.project_product_quantity.canBrowse = false;
                        }

                        if (group_id) {
                            await axios
                                .get(`/api/v1/groups/${group_id}`)
                                .then((res) => {
                                    if (res && res.data) {
                                        const item = res.data?.item;
                                        // console.log("fases para clonar", res.data)

                                        if (item.group_step_tables && item.group_step_tables.length > 0) {
                                            let newGroupStepTableFlows = item.group_step_tables.map((table: any) => ({
                                                project_product_group_step_table_flow_fk_step_id: table.group_step_table_fk_step_id,
                                                project_product_group_step_table_flow_fk_product_id: flow_id,
                                                project_product_group_step_table_flow_fk_group_id: group_id,
                                                guid: uuidv4(),
                                                steps: table.steps,
                                                project_product_group_step_table_flow_order_by: table.group_step_table_order_by
                                            }));

                                            newGroupStepTableFlows = _.orderBy(newGroupStepTableFlows, [`project_product_group_step_table_flow_order_by`], 'asc');

                                            setField("project_product_group_step_table_flows", newGroupStepTableFlows);
                                            this.globalProps.values.project_product_group_step_table_flows = newGroupStepTableFlows;
                                            // console.log("fases clonadas", this.globalProps.values.project_product_group_step_table_flows);
                                        }
                                    }
                                });
                        }
                    }
                })
            this.setState("loadings", [])
        }
    }
}