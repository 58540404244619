import classNames from "classnames";
import React from "react";
import { Button, Panel } from "rsuite";
import ClockInPsm from "./widgets/ClockInPsm";
import Favorites from "./widgets/Favorites";

export class Widget extends React.Component<any, {}> {

    public editable = ['Favorites'];

    getWidgetTitle(item: any) {
        const type = item.type;
        switch (type) {
            case "Favorites":
                return "Favoritos";
            default:
                return "Outros";
        }
    }

    renderWidgetType(item: any) {
        const type = item.type;
        switch (type) {
            case "Favorites":
                return <Favorites {...item} />;
            case "ClockInPsm":
                return <ClockInPsm {...item} />;
            default:
                return <div></div>;
        }
    }

    onClick = () => {
        if(this.props.onEdit) {
            this.props.onEdit(this.props.id ?? -1)
        }
    }

    onDelete = () => {
        if(this.props.onDelete) {
            this.props.onDelete(this.props.id ?? -1);
        }
    }

    render() {
        return (
            <div className={classNames("col-md-"+(this.props.widget.width ?? '12'))}>
            <Panel bordered style={{ backgroundColor: "#fff" }} className="rs-no-body mt-4">
                <div className="d-flex justify-contents-between align-items-center mb-4">
                    <div>
                        <h5>{this.getWidgetTitle(this.props.widget)}</h5>
                    </div>
                    <div>{this.props.children}</div>
                    <div>
                        {(this.props.isEditing && this.editable.includes(this.props?.widget?.type)) && <Button onClick={this.onClick} color={this.props.active ? 'blue':undefined}>
                            <i className="fas fa-pencil-alt"></i>
                        </Button>}
                        {this.props.isEditing && <Button onClick={this.onDelete} color={"red"} className="ml-2">
                            <i className="fas fa-trash"></i>
                        </Button>}
                    </div>
                </div>
                {this.renderWidgetType(this.props.widget)}
            </Panel>
            </div>
        );
    }
}
