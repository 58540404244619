import { Component, ReactNode } from "react";
import { Avatar, Checkbox } from "rsuite";



interface ExpandComponentProps {
    item: Record<string, any>;
    handleSetForwardMessage: ({ stepItemProjectProduct, indiceName }: { stepItemProjectProduct: Record<string, any>, indiceName: string }) => void
}

type ExpandComponentState = {
    expanded: boolean
}

export class ExpandComponent extends Component<ExpandComponentProps, ExpandComponentState> {
    state = {
        expanded: false
    }

    render(): ReactNode {
        return (
            <>
                <div>
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#044e45',
                                padding: 16,
                                borderRadius: '6px 6px 0 0',
                                color: '#fff',
                                overflowY: 'hidden'
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }
                            }
                        >
                            <div

                            >
                                {this.props.item.product_name}
                            </div>
                            <i className="fas fa-chevron-circle-down fa-2x"
                                style={
                                    {
                                        cursor: 'pointer'
                                    }
                                }
                                onClick={() => this.setState({ expanded: !this.state.expanded })}
                            ></i>
                        </div>
                    </div>
                    <div
                        style={
                            {
                                background: '#dcf8c6',
                                borderBottomLeftRadius: 6,
                                borderBottomRightRadius: 6,
                            }
                        }
                    >
                        {this.state.expanded && (
                            <>
                                {this.props.item.chats.map((item: Record<string, any>, index: number) => {
                                    return (
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    padding: 10,
                                                    alignItems: "center",
                                                }
                                            }
                                        >
                                            <Checkbox
                                                // checked={() => this.handleSetForwardMessage()}
                                                // checkable={true}
                                                onChange={(value: any, event) => this.props.handleSetForwardMessage({ stepItemProjectProduct: item, indiceName: item.step_name })}
                                            />
                                            <div>
                                                {item ? item?.step_name : ''}
                                            </div>
                                        </div>

                                    )
                                })}
                            </>
                        )}
                    </div>

                </div>
            </>
        )
    }
}