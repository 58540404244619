import '@asseinfo/react-kanban/dist/styles.css';
import dayjs from "dayjs";
import { Button, ControlLabel, DateRangePicker, Divider, Loader, Notification, Panel, Placeholder, Toggle } from "rsuite";
import { io } from "../../utilities/io";
//@ts-ignore
import Board, { moveCard } from "@asseinfo/react-kanban";
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CheckTreePicker } from '../../components/formfields/CheckTreePicker';
import axios from "../../utilities/axios";
import KanbanCard from './KanbanCard';
import { UserContext } from '../../components/authProvider/main';
import { locale } from '../../utilities/locale/DateRangePicker';

export class KanbanPage extends React.Component<any, {}> {
    static contextType = UserContext;

    public state: any = {
        data: [],
        steps: [],
        projects: [],
        filter: [],
        isLoading: true,
        isLoadingKanban: false,
        isDrawerOpen: false,
        viewCard: null,
        receiver: false
    };

    componentDidMount(): void {
        this.getData();
        this.getStepsData();

        if (this.state.data?.length <= 0) {
            io.on("kanban:receiver", async (data: any) => {
                if (data.company_id === this.props.companyId) {
                    this.setState({ receiver: dayjs().unix() });
                    this.getData();
                }
            });
        }
    }

    componentDidUpdate(): void {
        if (this.state.data?.length <= 0) {
            io.on("kanban:receiver", async (data: any) => {
                if (data.company_id === this.props.companyId) {
                    this.setState({ receiver: dayjs().unix() });
                    this.getData();
                }
            });
        }
    }

    getData = () => {
        // if (filter?.steps?.length > 0) {
        this.setState({ isLoadingKanban: true });
        axios.post(`/api/v1/kanban-get`, {
            params: {
                ...this.state.filter
            }
        }).then((res: any) => {
            this.setState({ data: res.data });
        }).finally(() => {
            this.setState({ isLoading: false, isLoadingKanban: false });
        });
        // }
    }

    getStepsData = () => {
        axios.get("/api/v1/steps/select").then((res: any) => {
            const items = res.data.items.map((item: any) => item.value);
            this.setState({ steps: items });
        }).finally(() => {

        });
    }

    moveCardFn = async (currentCard: any, from: any, to: any) => {
        // console.log({from, to})
        if ((from.fromColumnId === 4 && to.toColumnId !== 1) || (from.fromColumnId === 1 && to.toColumnId === 4)) {
            return Notification.warning({ title: "Aviso", description: "Não é possivel mover!" });
        }
        io.emit("kanban:emit", { currentCard, from, to });
        const newData = moveCard(this.state.data, from, to);
        this.setState({ data: newData });
    }

    selectFilter = (field: any, value: any) => {
        this.setState({ filter: { ...this.state.filter, [field]: value } }, this.getData);
    }

    renderKanbanCard = () => {
        return (
            <KanbanCard
                item={this.state.viewCard}
                open={this.state.isDrawerOpen}
                onClose={() => this.setState({ isDrawerOpen: false })}
            />
        )
    }

    render(): React.ReactNode {
        const { data, filter, isLoading, isLoadingKanban } = this.state;
        const kanbanData = { ...data };

        return (
            <>
                <div style={{ marginBottom: 15 }}>
                    {/* {JSON.stringify(filter)} */}
                    <div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
                        <div style={{ width: "40%" }}>
                            <ControlLabel>Projetos</ControlLabel>
                            <CheckTreePicker
                                name="projects"
                                api={{
                                    method: "GET",
                                    url: "/api/v1/projects/select",
                                    // uses: [{
                                    //     type: "value",
                                    //     field: this.context.data.user.user_id,
                                    //     as: "project_started_by"
                                    // }]
                                }}
                                displayLabel="projects.project_code + ' - ' + projects.project_description"
                                value={filter.projects}
                                onChange={(value: any) => this.selectFilter("projects", value)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "40%", marginLeft: 6 }}>
                            <ControlLabel>Fases</ControlLabel>
                            <CheckTreePicker
                                name="steps"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/steps/select${filter.projects?.length > 0 ? `?project_id=[${filter.projects}]` : ''}`,
                                }}
                                value={filter.steps}
                                onChange={(value: any) => this.selectFilter("steps", value)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Exibir Arquivados?</ControlLabel>
                            <Toggle
                                defaultChecked={filter.show_exclude ?? false}
                                checked={filter.show_exclude ?? false}
                                onChange={(value: boolean) => this.selectFilter("show_exclude", value)}
                                style={{ width: "100%" }}
                            ></Toggle>
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Data Inicial e Data Final da Criação do Projeto</ControlLabel>
                            <DateRangePicker
                                preventOverflow
                                locale={locale}
                                onClean={() => this.selectFilter("date", null)}
                                placement="bottomEnd"
                                format="DD/MM/YYYY"
                                // defaultValue={this.state.filter?.date}
                                onChange={(value: any) => this.selectFilter("date", value)}
                            />
                        </div>
                    </div>
                </div>

                {
                    isLoading && (
                        <div style={{ width: "100%", marginBottom: 15 }}>
                            <Placeholder.Paragraph rows={12} />
                        </div>
                    )
                }

                {
                    !isLoading && kanbanData && (
                        <>
                            <Panel bordered bodyFill>
                                {isLoadingKanban && (
                                    <Loader backdrop content="Carregando..." size="lg" />
                                )}
                                <Board
                                    style={{ width: "100%" }}
                                    disableColumnDrag={true}
                                    onCardDragEnd={this.moveCardFn}
                                    // initialBoard={data}
                                    renderColumnHeader={(column: any) =>
                                        <div style={{
                                            padding: '10px',
                                            backgroundColor: column.color,
                                            // position: 'sticky',
                                            // top: 0,
                                            // zIndex: 9999,
                                            borderBottom: '2px solid #ddd'
                                        }}>
                                            <p style={{ margin: 0, color: "#FFF" }}>{column.title}</p>
                                        </div>
                                    }
                                    renderCard={(item: any, index: number) => {
                                        return (<div className='kanbanAdjustment'>
                                            <div className="card-a" style={{ background: "white" }}>
                                                <div className="card-header-a">
                                                    <h6 className="card-title-a">{item?.project_code}
                                                        {item?.chat_message_fk_situation_chat_id && (
                                                            <Button
                                                                appearance="primary"
                                                                color="blue"
                                                                size='sm'
                                                                style={{ float: "right", marginTop: -10 }}
                                                                onClick={() => this.setState({ viewCard: item, isDrawerOpen: true })}>
                                                                <i className="fas fa-eye"></i>
                                                            </Button>
                                                        )}
                                                    </h6>
                                                </div>
                                                <div className='card-body-a'>
                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                        <strong>Cliente</strong><br />
                                                        <Link
                                                            key={index}
                                                            target={"_blank"}
                                                            to={`/dashboard/configuracoes/people/view/${item?.project_client_fk_person_id}`}
                                                        >
                                                            {item?.cliente}
                                                            <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                        </Link>
                                                    </p>
                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                        <strong>Descrição</strong><br />
                                                        <Link
                                                            key={index}
                                                            target={"_blank"}
                                                            to={`/dashboard/project/projects/view/${item?.project_product_fk_project_id}`}
                                                        >
                                                            {item?.title} - {item?.product_name}
                                                            <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                        </Link>
                                                    </p>
                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                        <strong>Fase</strong><br />
                                                        {item.step_name}
                                                        {/* <InputSelect crud={"steps"} id={item?.step_id} field={"step_name"} /> */}
                                                    </p>
                                                    <p className='break-word'>
                                                        <strong>Prazo</strong><br />
                                                        {item?.project_deadline_project} Dias
                                                    </p>
                                                    {/* <Divider /> */}
                                                </div>
                                                {/* <div className="card-footer-a">
                                                    Criado em: {dayjs(item?.project_created_at).format("DD/MM/YYYY HH:mm")}
                                                </div> */}
                                            </div>
                                        </div>);
                                    }}
                                >
                                    {kanbanData}
                                </Board>
                                {this.renderKanbanCard()}
                            </Panel>
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(KanbanPage);