import axios from "../../utilities/axios";
import { Event } from "../Event";

export class ProjectClientFkPersonIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState('loadings', [
                'project_phone_number',
                'project_cell_number',
                'project_email',
                'project_zip_code',
                'project_street',
                'project_number',
                'project_complement',
                'project_district',
                'project_fk_state_id',
                'project_fk_city_id'
            ]);

            axios
                .get('/api/v1/people/' + value)
                .then((response: any) => {
                    const { item } = response.data;
                    setField("project_phone_number", item.person_phone_number);
                    setField("project_cell_number", item.person_cell_number);
                    setField("project_email", item.person_email);
                    setField("project_zip_code", item.person_cep_number);
                    setField("project_street", item.person_address_street);
                    setField("project_number", item.person_address_number);
                    setField("project_complement", item.person_address_complement);
                    setField("project_district", item.person_address_neighborhood);
                    setField("project_fk_state_id", item.person_fk_state_id);
                    setField("project_fk_city_id", item.person_fk_city_id);
                })
                .finally(() => {
                    this.setState("loadings", []);
                });
        }
    }
}