import React from "react";
import { Button, Loader, Modal } from "rsuite";
import axios from "../../../../../utilities/axios";
import axiosClientChat from "../../../../../utilities/axiosClientChat";

interface ModalInfoChatStatusProps {
    onClose: () => void;
    typeCall: 'webUser' | 'webPerson';
    params: {
        project_product_id: number | string;
        project_product_fk_product_id: number | string;
    }
}

interface ModalInfoStatus {
    show: boolean;
    data: any[];
    loading: boolean;
}

export class ModalInfoChatStatus extends React.Component<ModalInfoChatStatusProps, ModalInfoStatus> {

    constructor(props: ModalInfoChatStatusProps) {
        super(props);
        this.state = {
            show: false,
            data: [],
            loading: false
        }
    }

    openModal(): void {
        this.setState({ loading: true })

        if (this.props.typeCall === 'webUser') {
            axios.get(`/api/v1/steps/${this.props.params.project_product_id}/${this.props.params.project_product_fk_product_id}/get`)
                .then((response) => {
                    this.setState({ data: response.data.item })
                })
                .finally(() => {
                    this.setState({ show: true, loading: false })
                })
        }
        if (this.props.typeCall === "webPerson") {
            axiosClientChat.get(`/chat-client/steps/${this.props.params.project_product_id}/${this.props.params.project_product_fk_product_id}/get`,
                {
                    params: {
                        viewAll: true
                    }
                }
            )
                .then((response) => {
                    console.log({ response })
                    this.setState({ data: response.data.item })
                })
                .finally(() => {
                    this.setState({ show: true, loading: false })
                })
        }

    }

    render(): React.ReactNode {
        return (
            <>
                <Modal
                    show={this.state.show}
                    onHide={() => this.setState({ show: false })}
                >
                    <Modal.Header>
                        <h4>
                            Informações do Item
                        </h4>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.data.length > 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    gap: 15,
                                    padding: 2
                                }}
                            >
                                {this.state.data.map((value: any, index) => {
                                    if (value.project_product_group_step_fk_step_id !== 9) {
                                        const getBackgroundColor = (status: string) => {
                                            switch (status) {
                                                case "NÃO INICIADO":
                                                    return "#0d5452";
                                                case "INICIADO":
                                                    return "#FFD700";
                                                case "URGENTE":
                                                    return "#C93337";
                                                case "FINALIZADO":
                                                    return "#32CD32";
                                                default:
                                                    return "#FFF";
                                            }
                                        };

                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    width: '100%',
                                                    height: '4rem',
                                                    borderRadius: 10,
                                                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: 'calc(100% - 4rem)',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        flexWrap: 'wrap',
                                                        background: '#f5f5f5',
                                                        paddingLeft: 10,
                                                        borderRadius: 10,
                                                    }}
                                                >
                                                    <p>
                                                        Status do Chat: <span style={{ fontWeight: 'bold' }}>{value?.steps[0]?.situation_chat_name ? value?.steps[0]?.situation_chat_name : 'NÃO INICIADO'}</span>
                                                    </p>
                                                    <h6>
                                                        {value.steps[0].step_name}
                                                    </h6>
                                                    <p
                                                        style={{
                                                            fontSize: 13,
                                                            color: value.steps[0]?.delay_date?.color ? value.steps[0]?.delay_date?.color : '#131047',
                                                        }}
                                                    >
                                                        {value.steps[0]?.delay_date?.message}
                                                    </p>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '4rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        background: getBackgroundColor(value?.steps[0]?.situation_chat_name),
                                                        borderTopRightRadius: 10,
                                                        borderBottomRightRadius: 10,
                                                    }}
                                                >
                                                    <i className="far fa-comments fa-2x"></i>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
                {!this.state.loading ? (
                    <div style={{ width: "100%", height: "100%", cursor: "pointer" }} onClick={() => this.openModal()}>
                        <i className="fas fa-info-circle fa-2x"></i>
                    </div>
                ) : (<Loader size="sm" />)}
            </>
        )
    }
}
