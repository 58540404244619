import React from "react";
import { Placeholder, Container, Content } from "rsuite";
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import { ChatComponentList } from "../components/ChatComponentList";
import _ from "lodash";


interface ChatListProps {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    previousRoute: ({ route, dataParams }: { route: string, dataParams: Record<string, any> }) => void;
    paramsRoute: Record<string, any>;
}


export class ChatList extends React.Component<ChatListProps, any> {
    state = {
        data: []
    }

    selectItem = ({ data }: { data: Record<string, any> }) => {
        this.props.nextRouter(
            {
                route: 'chatPage',
                paramsRoute: {
                    step_item: {
                        step_color: data.steps[0].step_color,
                        step_created_at: data.steps[0].step_created_at,
                        step_created_by: data.steps[0].step_created_by,
                        step_fk_company_id: data.steps[0].step_fk_company_id,
                        step_id: data.steps[0].step_id,
                        step_name: data.steps[0].step_name,
                        step_updated_at: data.steps[0].step_updated_at,
                    },
                    step_id: data.steps[0].step_id,
                    project_product_id: this.props.paramsRoute.project_product_id,
                    project_product_fk_product_id: this.props.paramsRoute.project_product_fk_product_id,
                    project_id: this.props.paramsRoute.project_id,
                    product_id: this.props.paramsRoute.product_id,
                    pathUrl: [
                        ...this.props.paramsRoute.pathUrl,
                        {
                            labelCrud: 'Chats',
                            labelValue: data.steps[0].step_name,
                            value: 3
                        }
                    ],
                    data
                }
            }
        )
    }

    render(): React.ReactNode {
        return (
            <>
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: "column",
                            gap: 20
                        }
                    }
                >
                    <HeaderComponent
                        // nextRouter={this.props.nextRouter}
                        previousRoute={this.props.previousRoute}
                        dataParams={this.props.paramsRoute}
                        routePrevious="products"
                        showInfoButton
                    />
                    <ChatComponentList
                        params={
                            {
                                project_product_fk_product_id: this.props.paramsRoute.project_product_fk_product_id,
                                project_product_id: this.props.paramsRoute.project_product_id
                            }
                        }
                        selectItem={this.selectItem}
                        typeCall="webUser"
                    />
                </div>
            </>
        )
    }
}