import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PersonEvents extends Event {
    public onInit = (value: any, setField: any, setState: any, formValues: any) => {
        if (!this.globalProps.location.pathname.includes('/view')) {
            this.setFieldValue("person_date_registration", dayjs().format('DD/MM/YYYY'))
            axios
                .get("/api/v1/me")
                .then((subRes) => {
                    var subData = subRes.data;

                    this.setFieldValue("person_user", subData.user.user_name);
                })

        }
    }


    public onLoad = () => {
        const { values } = this.globalProps
        if(values.person_cpf_cnpj && values.person_cpf_cnpj.length >= 14) {
            this.setField('person_fantasy_name',{
                hidden: false
            })
        }
    }

}