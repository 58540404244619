import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Notification } from "rsuite";
import axios from "../../../utilities/axios";

interface ArchiveProjectComponentProps {
    parentProps: any;
    parentState: any;
}


interface ArchiveProjectComponentState {
    loadings: boolean;
}



class ArchivedProjectComponent extends React.Component<ArchiveProjectComponentProps, ArchiveProjectComponentState> {
    state: ArchiveProjectComponentState = {
        loadings: false,
    }

    doUnarchive = () => {
        const { parentState } = this.props
        if(parentState.checked.length < 1) {
            Notification.info(
                {
                    title: "Aviso",
                    description: "Não é possível desarquivar sem nenhum projeto selecionado!"
                }
            )
            return;
        }
        this.setState(
            {
                loadings: true
            }
        )
        axios
            .post('/api/v1/unarchive/projects', {
                ids: parentState.checked
            })
            .then((res) => {
                Notification.success(
                    {
                        title: "Sucesso!",
                        description: "os projetos foram desarquivados com sucesso!"
                    }
                )
                setTimeout(() => {
                    window.location.reload()
                },650)
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState(
                        {
                            loadings: false
                        }
                    )
                },700)
            })
    }

    render(): React.ReactNode {
        return (
            <>
            
                <Button
                    color="cyan"
                    onClick={this.doUnarchive}
                    style={
                        {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5
                        }
                    }
                    loading={this.state.loadings}
                >
                    <i className="fas fa-file"></i>
                    Desarquivar Projetos
                </Button>
            </>
        )
    }
}



export class ArchivedProjectBrowseEvents {
    onRenderFooterBrowserlize = (props: any, state: any) => {
        return <ArchivedProjectComponent parentProps={props} parentState={state} />;
    }
}