import dayjs from "dayjs";
import React from "react";
import { Button, DateRangePicker, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CheckTreePicker } from "../../formfields/CheckTreePicker";
import config from "../../../globals";
import { locale } from "../../../utilities/locale/DateRangePicker";
import { AxiosSelectPicker } from "../../formfields/AxiosSelectPicker";

export class AppointmentReport extends React.Component<any, {}> {
    public state: any = {
        filter: {
            projects: [],
            products: [],
            steps: [],
            users: [],
            peoples: [],
            status: [],
            appointment_init: [dayjs().startOf('month'), dayjs().endOf('month')],
            appointment_finish: [dayjs().startOf('month'), dayjs().endOf('month')],
            date: [dayjs().startOf('month'), dayjs().endOf('month')]
        },
        isLoading: false
    }

    componentDidMount(): void {

    }

    onSubmitButton = () => {
        this.setState({ isLoading: true });
        axios.get("/api/v1/projects/reports/appointments", {
            params: {
                filter: this.state.filter
            }
        }).then((res) => {
            if (res?.status === 200) {
                if (config.url.includes("localhost")) {
                    config.url = config.url.split('25565').join('25567');
                }
                if (res.data.generatedExcel) {
                    setTimeout(() => {
                        window.open(config.url + res.data.generatedExcel, "_blank");
                    }, 3000);
                } else {
                    Notification.warning({
                        title: "Aviso",
                        description: "Nenhum registro encontrado.",
                    });
                }
            }
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        const { projects, products, steps, users, peoples, date, status, appointment_init, appointment_finish } = this.state.filter;

        return (
            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex", margin: "auto", gap: 5, marginBottom: 32 }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <label>Cliente</label>
                        <CheckTreePicker
                            name="people"
                            api={{
                                method: "GET",
                                url: "/api/v1/people/select",
                                uses: [
                                    {
                                        type: "value",
                                        field: "1",
                                        as: "person_fk_type_person_id"
                                    }
                                ]
                            }}
                            displayLabel="people.person_name + ' - CNPJ: ' + people.person_cpf_cnpj"
                            disabled={this.state.isLoading}
                            value={peoples}
                            onChange={(value: any) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        peoples: value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>Data de inicio (De/Até)</label>
                        <DateRangePicker
                            format="DD/MM/YYYY"
                            preventOverflow
                            locale={locale}
                            defaultValue={appointment_init}
                            disabled={this.state.isLoading}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        appointment_init: value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>Data de finalização (De/Até)</label>
                        <DateRangePicker
                            format="DD/MM/YYYY"
                            preventOverflow
                            locale={locale}
                            defaultValue={appointment_finish}
                            disabled={this.state.isLoading}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        appointment_finish: value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "75%" }}>
                        <label>Usuário</label>
                        <CheckTreePicker
                            name="user"
                            api={{
                                method: "GET",
                                url: "/api/v1/users/select"
                            }}
                            disabled={this.state.isLoading}
                            value={users}
                            onChange={(value: any) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        users: value,
                                    }
                                })
                            }}
                        />
                    </div>

                </div>

                <div style={{ width: "100%", display: "flex", margin: "auto", gap: 5, marginBottom: 32 }}>

                    <div style={{ display: "flex", flexDirection: "column", width: "75%" }}>
                        <label>Projeto</label>
                        <CheckTreePicker
                            name="project"
                            api={{
                                method: "GET",
                                url: "/api/v1/projects/select"
                            }}
                            displayLabel="project.project_code + ' - ' + project.project_description"
                            disabled={this.state.isLoading}
                            value={projects}
                            onChange={(value: any) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        projects: value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "75%" }}>
                        <label>Itens</label>
                        <CheckTreePicker
                            name="products"
                            api={{
                                method: "GET",
                                url: `/api/v1/products/select${projects?.length > 0 ? `?project_id=[${projects}]` : ''}`,
                            }}
                            displayLabel="products.product_name"
                            disabled={this.state.isLoading}
                            value={products}
                            // isLoadingClean={isLoadingClean}
                            onChange={(value: any) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        products: value
                                    }
                                })
                            }}
                        // onClean={() => this.cleanAllFilters()}
                        />
                    </div>

                </div>

                <div style={{ width: "100%", display: "flex", margin: "auto", gap: 5, marginBottom: 8 }}>

                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>Fases</label>
                        <CheckTreePicker
                            name="step"
                            api={{
                                method: "GET",
                                url: `/api/v1/steps/select${projects?.length > 0 ? `?project_id=[${projects}]` : ''}${products?.length > 0 ? `${projects?.length > 0 ? "&" : "?"}product_id=[${products}]` : ''}`
                            }}
                            disabled={this.state.isLoading}
                            value={steps}
                            onChange={(value: any) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        steps: value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>Status Atual</label>
                        <AxiosSelectPicker
                            preventOverflow
                            placement="topStart"
                            disabled={this.state.isLoading}
                            value={status}
                            onChange={(value: any) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        status: value
                                    }
                                })
                            }}
                            api={{
                                method: "GET",
                                url: "/api/v1/situation_appointments/select"
                            }}
                            placeholder={"Selecione..."}
                            data={[]}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>Data do Status Atual (De/Até)</label>
                        <DateRangePicker
                            format="DD/MM/YYYY"
                            preventOverflow
                            locale={locale}
                            defaultValue={date}
                            disabled={this.state.isLoading}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        ...this.state.filter,
                                        date: value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "25%", marginTop: 20 }}>
                        <Button loading={this.state.isLoading} onClick={() => this.onSubmitButton()} appearance="primary" color="green"><i className="fas fa-fw fa-file-excel"></i> Relatório Excel</Button>
                    </div>
                </div>
                {/* {JSON.stringify(this.state.filter)} */}
            </div>
        )
    }
}
