import React from "react";
import { Whisper, Tooltip, Notification, Loader } from "rsuite";
import axios from "../../../utilities/axios";



interface Props {
    parentProps: any;
    rowData: Record<string, any>
}


interface State {
    loadings: boolean
}

class OngoingProjectComponent extends React.Component<Props, State> {
    state: State = {
        loadings: false
    }


    doUnarchive = (params: { projectId: number }) => {
        const { projectId } = params;
        console.log("teste ", projectId);
        this.setState(
            {
                loadings: true
            }
        )
        axios
            .post('/api/v1/archived/projects', {
                ids: [projectId]
            })
            .then((res) => {
                Notification.success(
                    {
                        title: "Sucesso!",
                        description: "Projeto(s) arquivados com sucesso!"
                    }
                )
                setTimeout(() => {
                    window.location.reload()
                }, 750)
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState(
                        {
                            loadings: false
                        }
                    )
                }, 850)
            })
    }



    render(): React.ReactNode {
        return (
            <>
                {this.props.rowData?.['project_fk_project_sub_situation_id'] == 1 && (
                    <>
                        {this.state.loadings ? (
                            <>
                                <Loader />
                            </>
                        ) : (
                            <>
                                <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Arquivar Projeto</Tooltip>}>
                                    <i onClick={() => this.doUnarchive(
                                        {
                                            projectId: this.props.rowData.project_id
                                        }
                                    )} className="fas text-info fa-fw mr-2 clickable fas fa-archive"></i>
                                </Whisper>
                            </>
                        )}
                    </>
                )}
            </>
        )
    }
}



export class OngoingProjectBrowserActions {
    onRenderActions = (props: any, rowData: Record<string, any>) => {
        return <OngoingProjectComponent parentProps={props} rowData={rowData} />;
    }
}