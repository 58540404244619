import React from "react";

export const AuthContext = React.createContext({ token: null, isAuthenticated: false, setAuth: (token: string) => {} });
export const ChatContext = React.createContext({ token: null, isAuthenticated: false, setAuth: (token: string) => {}})
export const ModuleContext = React.createContext({ module: null, setModule: function () {} });
export const UserContext = React.createContext({ data: null, setUser: function () {} });
export const UserChatContext = React.createContext({ data: null, setUser: function () {} });

// export class AuthProvider extends React.Component<any,{}> {

// }
