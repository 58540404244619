import '@asseinfo/react-kanban/dist/styles.css';
import { Button, Drawer, Panel } from "rsuite";
//@ts-ignore
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from "../../utilities/axios";
import { CardChatComponent } from './CardChatComponent';

export class KanbanCard extends React.Component<any, {}> {

    public state: any = {
        item: this.props.item,
        open: this.props.open,
        data: null
    };

    componentDidUpdate(prevProps: any) {
        if (this.props.item !== prevProps.item || this.props.open !== prevProps.open) {
            this.setState({
                item: this.props.item,
                open: this.props.open
            }, this.getData);
        }
    }

    getData = () => {
        this.setState({ isLoading: true });
        axios.get(`/api/v1/kanban-card/${this.state.item.project_product_id}/${this.state.item.step_id}`).then((res: any) => {
            this.setState({ data: res.data.card });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    render(): React.ReactNode {
        const { item, open } = this.state;

        return (
            <Drawer
                placement="right"
                size="md"
                // full
                backdrop
                autoFocus
                show={open}
                onHide={this.props.onClose}
            >
                <Drawer.Header>
                    <Drawer.Title>{item?.project_code} - {item?.cliente} - {item?.title} - {item?.product_name}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    {/* <p>Prazo total: {item?.project_deadline_project} Dias</p> */}
                    {/* <Nav appearance="tabs" justified>
                        <Nav.Item active eventKey="dados_cliente">Dados Cliente</Nav.Item>
                        <Nav.Item eventKey="dados_terceiro">Dados Terceiro</Nav.Item>
                        <Nav.Item eventKey="anexos">Anexos</Nav.Item>
                        <Nav.Item eventKey="descricao_das_fases">Descrição das Fases</Nav.Item>
                    </Nav> */}
                    <Panel header={`Chat da Fase - ${item?.step_name}`} bordered>
                        <CardChatComponent item={item} />
                    </Panel>
                    {/* <Placeholder.Paragraph rows={12} /> */}
                </Drawer.Body>
                <Drawer.Footer>
                    <Button onClick={this.props.onClose} appearance="subtle">
                        Fechar
                    </Button>
                </Drawer.Footer>
            </Drawer>
        )
    }
}

export default withRouter(KanbanCard);
