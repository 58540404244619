import React, { CSSProperties } from 'react';

const contentStyle: CSSProperties = {
  height: '50%',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap'
};

interface PreventOverflowContainerProps {
  children: (getContainer: () => HTMLDivElement) => JSX.Element;
  height?: number;
  overflow?: boolean;
}

const PreventOverflowContainer = ({ children, height = 300, overflow = true }: PreventOverflowContainerProps) => {
  const containerRef: any = React.createRef<HTMLDivElement>();
  const contentRef: any = React.createRef<HTMLDivElement>();

  const containerStyle: CSSProperties = {
    overflow: !overflow ? 'false' : 'auto',
    position: 'absolute'
  };

  React.useEffect(() => {
    containerRef.current.scrollTop = contentRef.current.clientHeight / 2 - 60;
    containerRef.current.scrollLeft =
      contentRef.current.clientWidth / 2 - containerRef.current.clientWidth / 2;
  }, []);

  return (
    <div
      id="preventOverflowContainer"
      style={{ ...containerStyle, height } as CSSProperties}
      ref={containerRef}
    >
      <div style={contentStyle} ref={contentRef}>
        {children(() => containerRef.current)}
      </div>
    </div>
  );
};

export default PreventOverflowContainer;