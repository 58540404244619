import React from "react";
import { Modal } from "rsuite";


interface ModalComplementInfoProps {
    dataModal: Record<string, any>;
    showModal: boolean;
    onCLoseModal: () => void;
}


interface ModalComplementInfoState {
    // loading: false
}


export class ModalComplementInfo extends React.Component<ModalComplementInfoProps, ModalComplementInfoState> {
    constructor(props: ModalComplementInfoProps) {
        super(props);
        this.state = {

        }
    }

    onClose = () => {


        this.props.onCLoseModal();
    }

    // renderBody = () => {
    //     const { dataModal } = this.props;
    //     console.log({ dataModal })
    //     return Object.entries(dataModal).map(([key, value]) => {
    //         console.log({ key, value });
    //         return (
    //             <>
    //                 <div>
    //                     <label></label>
    //                     <p></p>
    //                 </div>
    //             </>
    //         )
    //     })
    // }

    render(): React.ReactNode {
        const { dataModal } = this.props;
        return (
            <>
                    <Modal
                        show={this.props.showModal}
                        onHide={this.onClose}
                    >
                        <Modal.Header>
                            Informações complementares do Projeto
                        </Modal.Header>
                        <Modal.Body>
                            {/* {this.renderBody()} */}
                            <div>
                                <fieldset>
                                    <h6>Descrição do Projeto</h6>
                                    <p>
                                        {dataModal.project_description}
                                    </p>
                                </fieldset>
                                <fieldset>
                                    <h6>Endereço</h6>
                                    <p
                                        style={
                                            {

                                            }
                                        }
                                    >
                                        {dataModal?.project_street} , {dataModal?.project_number} {dataModal?.project_district} - {dataModal?.cities?.city_name}-{dataModal?.states?.state_name}
                                    </p>
                                </fieldset>
                            </div>
                        </Modal.Body>
                    </Modal>
            </>
        )
    }
}