import _ from "lodash";
import React from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button, Checkbox, Loader, Modal, Notification, Panel, Table, CheckTreePicker as RSuiteCheckTreePicker } from "rsuite";
import axios from "../../../utilities/axios";
import Formalize from "../../Formalize";
import { SchemaToParsedCell } from "../../customTable/SchemaToParsedCell";
import { NumberInput } from "../NumberInput";

const { Column, HeaderCell, Cell } = Table;
//config
const custom_name = 'project_product_group_step_table_flows';

function DraggableCell({ children, onDrag, id, rowData, columnIdName, ...rest }: any) {
    const ref = React.useRef(null);
    const ItemTypes = {
        COLUMN: 'column',
        ROW: 'row'
    };



    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.ROW,
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop(item: any, monitor: any) {
            onDrag && onDrag(item[`${columnIdName}`], rowData[`${columnIdName}`]);
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ROW,
        item: { [`${columnIdName}`]: rowData[`${columnIdName}`] },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0 : 1;
    const isActive = canDrop && isOver;

    drag(drop(ref));

    const styles: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
        background: isActive ? '#ddd' : '',
        justifyContent: 'center',
    };

    return (
        <Cell {...rest} style={{ padding: 0 }}>
            <div ref={ref} style={styles}>
                {children}

            </div>
        </Cell>
    );
}

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}



export class ProjectProductGroupStepTableFlowsCustom extends React.PureComponent<any, {}> {

    public state: any = {
        isLoading: true,
        isRead: false,
        isModalOpen: false,
        isSettingsModalOpen: false,
        isEdit: null,
        form: {},
        totalWidth: 0,
        hiddenRows: [],
        selectedValues: {},
        columnOrder: "[]",
        items: [],
        isConfirmDeleteModal: false,
        searchs: {},
    };

    private eventHandler: any | null = null;
    public elementRef: any = null;
    public cachedApiItems: any = {};

    onStartLoading = () => {
        axios.get('/api/v1/custom/checktreepicker/initial/select', {
            params: {
                crud: 'user_restricts',
                relationTable: 'users',
                relationTableId: this.props.values.user_id,
                paramsWhere: [
                    {
                        user_fk_project_id: this.props.values.user_fk_project_id
                    }
                ]
            }
        })
            .then((res: any) => {
                var arr: any = [...res.data.items];
                // if (value) {
                //     arr = this.find(value, arr);
                //     //(arr);
                // }
                this.setState({ data: arr, loading: false });
            })
    }

    onLoadingOptions = () => {
        this.setState({ isLoading: true });
        // console.log("i'm herereroiew jroijr ::", this.props)
        axios.get('/api/v1/users/restrict/select', {
            params: {
                project_users: this.props.parentValues.project_users,
                user_id: this.props.values?.user_id ? this.props.values.user_id : null
            }
        })
            .then((response) => {
                this.setState({ data: response.data.items })
            })
            .catch((err) => {
                Notification.info(
                    {
                        title: 'Info',
                        description: err.response.data.message
                    }
                )
                this.setState({ data: [] })
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }


    onConfirmDelete = async (rowData: any, rowIndex: any, currentTable: any) => {
        // console.log("CLICOU", rowData, rowIndex, currentTable)
        axios.post("/api/v1/perm-delete", { isCustom: true, customName: custom_name, id: rowData['ihm_service_performed_id'], currentTable: currentTable }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado",
            });
        });

        var items = this.state.items;
        var merge = { ihm_fault_phrases: this.state.items, ihm_service_performeds: this.state.items }
        items.splice(rowIndex, 1);
        this.setState({ items, isLoading: true }, () => {
            if (this.props.onChange) {
                this.props.onChange(merge);
            }
            if (this.props.onDelete) {
                this.props.onDelete(merge);
            }
            this.setState({ isLoading: false, isConfirmDeleteModal: false });
            this.setState({ tempDeleteItemID: null, tempDeleteItem: null, tempDeleteTable: null });
        });
    };

    onClickDelete = async (rowIndex: any, rowData: any) => {
        this.setState({ isConfirmDeleteModal: true, tempDeleteItemID: rowIndex, tempDeleteItem: rowData, tempDeleteTable: custom_name });
    };

    renderActions = (rowData: any, rowIndex: any) => {
        const extras = this.eventHandler?.getActions ? this.eventHandler?.getActions(rowData) : null;

        return (
            <div style={{ display: "flex" }}>
                {this.props.readOnly !== true && this.props.canDelete !== false && (
                    <i onClick={() => this.onClickDelete(rowIndex, rowData)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                )}
                {extras}
                <Modal show={this.state.isConfirmDeleteModal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                            Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && <>Deseja mesmo excluir esse registro ? o registro não poderá ser restaurado!</>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} onClick={() => this.onConfirmDelete(this.state.tempDeleteItem, this.state.tempDeleteItemID, this.state.tempDeleteTable)} appearance="primary">
                            <i className="fas fa-check fa-fw mr-2"></i>Confirmar
                        </Button>
                        <Button disabled={this.state.isLoading} color="red" onClick={() => this.setState({ isConfirmDeleteModal: false })}>
                            <i className="fas fa-times fa-fw mr-2"></i>Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* {JSON.stringify(this.eventHandler)} */}
            </div>
        );
    };

    openAdd = () => {
        this.setState({ isRead: false, isModalOpen: true, isEdit: null, selectedValues: {} });
    };

    componentWillUnmount(): void {
        this.props.onChange();
    }

    componentDidMount() {
        if (!this.props.values.project_product_id) {
            this.setState({ items: this.props.values.project_product_group_step_table_flows });
        }

        const flow_id = this.props.values.project_product_fk_product_id;
        if (this.props.values.project_product_group_step_table_flows && this.props.values.project_product_id) {
            axios
                .get(`/api/v1/custom/clone-flow-phases/${this.props.values.project_product_id}`)
                .then((res) => {
                    if (res && res.data) {
                        const items = res.data?.items;
                        // console.log("fases para clonar", res.data)

                        if (items.length > 0) {
                            this.setState({ items: items });
                        }
                    }
                });
        }

        if (this.props.values.project_product_fk_product_id && !this.props.values.project_product_group_step_table_flows) {
            this.setState({ isLoading: true });
            axios
                .get(`/api/v1/products/${flow_id}`)
                .then(async (res) => {
                    if (res && res.data) {
                        const group_id = res.data?.item.product_fk_group_id;
                        // console.log({ group_id })

                        if (group_id) {
                            axios
                                .get(`/api/v1/groups/${group_id}`)
                                .then((res) => {
                                    if (res && res.data) {
                                        const item = res.data?.item;
                                        // console.log("fases para clonar", res.data)

                                        if (item.group_step_tables && item.group_step_tables.length > 0) {
                                            const newGroupStepTableFlows = item.group_step_tables.map((table: any) => ({
                                                project_product_group_step_table_flow_fk_step_id: table.group_step_table_fk_step_id,
                                                project_product_group_step_table_flow_fk_product_id: flow_id,
                                                project_product_group_step_table_flow_fk_group_id: group_id,
                                                guid: uuidv4(),
                                                steps: table.steps,
                                                project_product_group_step_table_flow_order_by: table.group_step_table_order_by
                                            }));

                                            this.setState({ items: newGroupStepTableFlows });
                                            this.props.values.project_product_group_step_table_flows = newGroupStepTableFlows;
                                            // console.log("fases clonadas", this.globalProps.values.project_product_group_step_table_flows);
                                        }
                                    }
                                }).finally(() => {
                                    this.setState({ isLoading: false });
                                });
                        }
                    }
                })
        }

        this.elementRef = React.createRef();
        var api = "project_product_group_step_table_flows";
        axios
            .get("/api/v1/" + api + "/fields")
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }
                    this.setState({ form: fields });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });

            this.onLoadingOptions();
    }

    componentDidUpdate(prevProps: any) {
        this.props.values.project_product_group_step_table_flows = this.state.items;
        // console.log("atualizou custopm", this.state.items)
    }

    reOpenModal = () => {
        setTimeout(this.openAdd, 1000);
    }
    onSubmit = async (values: any) => {
        const { items, isEdit } = this.state;
        let updatedItems = [...items || []];

        if (isEdit !== null) {
            updatedItems[isEdit] = values;
        } else {
            updatedItems.push(values);
        }

        const lastIndex = updatedItems.length - 1;
        const lastItem = updatedItems[lastIndex];

        try {
            const response = await axios.get(`/api/v1/steps/${lastItem?.project_product_group_step_table_flow_fk_step_id}`);
            if (response && response.data) {
                const step = response.data.item;
                updatedItems[lastIndex] = {
                    ...lastItem,
                    project_product_group_step_table_flow_order_by: updatedItems.length,
                    project_product_group_step_table_flow_fk_project_product_id: updatedItems[0].project_product_group_step_table_flow_fk_project_product_id,
                    project_product_group_step_table_flow_fk_product_id: updatedItems[0].project_product_group_step_table_flow_fk_product_id,
                    project_product_group_step_table_flow_fk_group_id: updatedItems[0].project_product_group_step_table_flow_fk_group_id,
                    steps: step,
                };

                this.setState(
                    {
                        items: updatedItems,
                        isModalOpen: false
                    },
                    () => {
                        const mergeDados = {
                            ihm_fault_phrases: updatedItems,
                            ihm_service_performeds: updatedItems
                        };

                        if (this.props.onChange) {
                            this.props.onChange(mergeDados);
                        }

                        if (isEdit !== null) {
                            if (this.props.onEdit) {
                                this.props.onEdit(values, updatedItems.length);
                            }
                        } else {
                            if (this.props.onAdd) {
                                this.props.onAdd(values, updatedItems.length);
                            }
                        }
                    }
                );
            }
        } catch (error) {
            console.error("Error fetching step:", error);
        }
    };

    renderSubmit = (onSubmitEvent: any) => {
        // return null;
        return (
            <div className="col-md-12" style={{ marginBottom: 10 }}>
                <Button
                    onClick={() => {
                        sessionStorage.removeItem("currentParent");
                        sessionStorage.removeItem("currentParentIndex");
                        this.setState({ isModalOpen: false });
                    }}>
                    <i className="fas fa-fw fa-times mr-2"></i>
                    Cancelar
                </Button>
                {this.state.isEdit !== undefined && this.state.isRead === false && (
                    <Button
                        color="green"
                        onClick={() => {
                            sessionStorage.removeItem("currentParent");
                            sessionStorage.removeItem("currentParentIndex");
                            if (onSubmitEvent) {
                                onSubmitEvent();
                            }
                        }}>
                        <i className="fas fa-fw fa-save mr-2"></i>
                        Gravar
                    </Button>
                )}
            </div>
        );
    };

    handleInputChange = (value: any, rowIndex: any, dataKey: any) => {
        const items = [...this.state.items];
        items[rowIndex][dataKey] = value;
        this.setState({ items }, () => {
            if (this.props.onChange) {
                this.props.onChange(items);
            }
        });
    };

    formatTextObject = (text: any) => {
        // console.log("formatTextObject", text, typeof text);
        if (typeof text === 'string' && text.trim() !== '') {
            return text.replace(/[{}"]/g, '').split(',').map(Number);
        }
        return text;
    }

    handleDragRow = (sourceId: number, targetId: number) => {
        const sourceFilter = this.filterData(this.state.searchs);
        const newArrayDraggle = this.sort(sourceFilter, sourceId, targetId);

        this.setState({ items: newArrayDraggle }, () => {
            if (this.props.onChange) {
                this.props.onChange(newArrayDraggle);
            }
        });
    };

    filterData = (searchs: any) => {
        var output = [];

        var data = this.state.items;
        for (var i in data) {
            var row = data[i];

            if (this.state.hiddenRows.includes(i)) {
                continue;
            }
            var includes = true;
            for (var fieldName in this.state.form) {
                var field = this.state.form[fieldName];
                if (field !== undefined) {
                    var parsedValue = SchemaToParsedCell.parseFieldToValue(field, row);
                    if (searchs[fieldName]) {
                        if (!parsedValue.includes(searchs[fieldName])) {
                            includes = false;
                            break;
                        }
                    }
                }
            }
            // //("PARSED",SchemaToParsedCell.parseFieldToValue(this.state.form[i],data[i]))
            if (includes) {
                output.push(data[i]);
            }
        }

        output = _.orderBy(output, [`project_product_group_step_table_flow_order_by`], 'asc');
        return output;
    };

    sort(source: any, sourceId: number, targetId: number) {
        const columnIdName = 'project_product_group_step_table_flow_order_by';
        const nextData = source.slice();
        const dragItem = nextData.find((item: any) => item[columnIdName] === sourceId);
        const targetIndex = nextData.findIndex((item: any) => item[columnIdName] === targetId);

        nextData.splice(nextData.findIndex((item: any) => item[columnIdName] === sourceId), 1);

        nextData.splice(targetIndex, 0, dragItem);

        nextData.forEach((item: any, index: number) => {
            item[columnIdName] = index + 1;
        });

        return nextData;
    }

    render() {
        return (
            <>
                <div style={{ display: "inline-flex" }}>
                    <Panel bordered bodyFill style={{ padding: 8 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}>
                            {this.props.compact !== true && <h4 dangerouslySetInnerHTML={{ __html: "Fases" }}></h4>}
                            <div>
                                <Button color="green" onClick={this.openAdd}>
                                    <i className="fas fa-fw fa-plus mr-2"></i>
                                    Adicionar
                                </Button>
                            </div>
                        </div>
                        <Modal overflow={false} size={"lg"} className="rs-modal-min" show={this.state.isModalOpen}>
                            {Object.keys(this.state.form).length > 0 && (
                                <Formalize
                                    isModal={true}
                                    readOnly={false}
                                    parentValues={this.props.parentValues}
                                    parent={this.props.parent}
                                    values={_.cloneDeep(this.state.selectedValues)}
                                    onSubmit={this.onSubmit}
                                    submitButton={this.renderSubmit}
                                    form={this.state.form}
                                    history={this.props.history}
                                    name={"this.props.field.api"}
                                />
                            )}
                        </Modal>
                        {/* {JSON.stringify(this.state.items)} */}
                        <DndProvider backend={HTML5Backend}>
                            {/* {JSON.stringify(this.state.items)} */}
                            <Table
                                headerHeight={50}
                                rowHeight={50}
                                // className={this.props.compact ? "compact" : ""}
                                wordWrap
                                renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                                loading={this.state.isLoading}
                                height={400}
                                data={this.state.items}>

                                <Column fixed={"left"} width={this.eventHandler?.width ?? 100} >
                                    <HeaderCell>Ordem</HeaderCell>
                                    <DraggableCell onDrag={this.handleDragRow} columnIdName={`project_product_group_step_table_flow_order_by`}>
                                        <i className="fas fa-arrows-alt"></i>
                                    </DraggableCell>
                                </Column>

                                <Column resizable width={400}>
                                    <HeaderCell>Fase</HeaderCell>
                                    {/* <Cell dataKey="project_product_group_step_table_flow_fk_step_id" className="link-group"></Cell> */}
                                    <Cell>
                                        {(rowData: any) => {
                                            return (
                                                <>{rowData.steps?.step_name}</>
                                            )
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={100}>
                                    <HeaderCell>Prazo</HeaderCell>
                                    <Cell>
                                        {(rowData: any, rowIndex: number) => (
                                            <NumberInput
                                                punctuation={false}
                                                placeholder=""
                                                step={0}
                                                value={rowData.project_product_group_step_table_flow_measure_days}
                                                onChange={(value: number) => this.handleInputChange(value, rowIndex, 'project_product_group_step_table_flow_measure_days')}
                                            />
                                        )}
                                    </Cell>
                                </Column>

                                <Column resizable width={200}>
                                    <HeaderCell>Depende da fase anterior?</HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: number) => {
                                            // console.log({ rowIndex });
                                            if (rowIndex !== 0) {
                                                return (
                                                    <Checkbox
                                                        onChange={(value: boolean, checked: boolean) => this.handleInputChange(checked, rowIndex, "project_product_group_step_table_flow_is_last_phase_dependencie")}
                                                        checked={rowData?.project_product_group_step_table_flow_is_last_phase_dependencie || false}
                                                    />
                                                )
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={350}>
                                    <HeaderCell>Usuários Envolvidos</HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: number) => {
                                            return (
                                                <div className="rs-form-control-wrapper">
                                                    <RSuiteCheckTreePicker
                                                        // ref={this.inputRef}
                                                        data={this.state.data}
                                                        value={this.state.loadValues}
                                                        defaultValue={this.formatTextObject(rowData?.project_product_group_step_table_flow_users)}
                                                        onOpen={() => this.onLoadingOptions()}
                                                        onChange={(value: any) => this.handleInputChange(value, rowIndex, "project_product_group_step_table_flow_users")}
                                                        onClean={(value: any) => this.handleInputChange(null, rowIndex, "project_product_group_step_table_flow_users")}
                                                    />
                                                </div>
                                            )
                                        }}
                                    </Cell>
                                </Column>

                                <Column fixed={"right"} width={100}>
                                    <HeaderCell>Ações</HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: any) => {
                                            return this.renderActions(rowData, rowIndex);
                                        }}
                                    </Cell>
                                </Column>
                            </Table>
                        </DndProvider>
                    </Panel>
                </div>
            </>
        );
    }
}
