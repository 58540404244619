import React from "react";
import { Button, Input, Nav, Panel } from "rsuite";

export class IntegrationMbmConfigPage extends React.Component<any, {}> {
    public state = {
        values: {...this.props.values?.MBM},
    }

    onBlurField = (value: any) => {
        this.setState({
            values: {
                ...this.state.values, ...value
            }
        })
        this.props.onChange({ MBM: { ...this.state.values, ...value } })
    }


    render(): React.ReactNode {
        const { MBM } = this.props.values

        return (<>
            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>MBM Endereço Remoto<a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_host} onChange={(value) => this.onBlurField({ db_host: value })}></Input>
                </div>
                <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>MBM Porta <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_port} onChange={(value) => this.onBlurField({ db_port: value })}></Input>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>MBM Usuario BD <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_user} onChange={(value) => this.onBlurField({ db_user: value })}></Input>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>MBM Senha BD <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_password} onChange={(value) => this.onBlurField({ db_password: value })} type="password"></Input>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>MBM Nome do BD <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_name} onChange={(value) => this.onBlurField({ db_name: value })}></Input>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>MBM Nome do BD (int) <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_name_int} onChange={(value) => this.onBlurField({ db_name_int: value })}></Input>
                </div>
            </div>
            {/* <Button onClick={() => this.props.onChange({ PAO: "batata" })}>TESTEE</Button>
            {JSON.stringify(this.props.values)} */}
        </>)
    }
}