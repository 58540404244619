import * as axiosRoot from "axios";
import { Notification } from "rsuite";
import config from "../globals";
import { callStatusResponse, triggerErrorHandlers } from "./errorHandler";
import { io } from "./io";

var axiosClientChat = axiosRoot.default.create({
    baseURL: config.url,
    timeout: 100000,
    headers: { "x-requested-with": "XMLHttpRequest" },
});

const defaultCacheTime = 60000;

window.onunload = function () {
    sessionStorage.clear();
}

axiosClientChat.interceptors.request.use(
    function (val) {
        if (io.connected) {
            val.headers.socket = io.id;
        }

        var token = localStorage.getItem("chatToken");
        if (token) {
            val.headers.authorization = "bearer " + token;
            // val.headers.company = localStorage.getItem("currentCompany");
            return val;
        } else {
            throw new axiosRoot.default.Cancel("Operation canceled by the user.");
        }
    },
    function (error) {
        return Promise.reject(error);
    },
);

axiosClientChat.interceptors.response.use(
    (res) => {
        if (res.status === 200) {
            // //console.log(res.config?.url,res.config.params,res.config?.url && (res.config?.url.includes("/fields") || res.config.params?.cache === true))
            if (res.config?.url && (res.config?.url.includes("/fields") || res.config.params?.cache === true)) {
                var url = res.config.url + (res.config.params?.cache === true ? '-cached' : '');
                //console.log("SAVED CACHE FOR "+res.config.url);
                sessionStorage.setItem(url, JSON.stringify({ data: res.data, time: new Date().getTime() }));
            }
        }
        return res;
    },
    (err) => {
        //console.log({err});
        if (err.message === "Operation canceled by the user.") {
            return err;
        }
        if (err.message === "Fields cached by user.") {
            return err;
        }
        if (err.response?.data?.message?.includes('Não foi possivel encontrar o crud de')) {
            callStatusResponse('404');
            return err;
        }
        if (err?.response?.status === 429) {
            Notification.error({
                title: "[429] Erro",
                description: "Limite de requisições alcançado!"
            })
        }
        // if(err.response.message) {

        // }
        if (err?.response?.status === 403) {
            // window.location.replace('/dashboard');
        }
        // //
        if (err?.response?.status === 404) {
            // triggerErrorHandlers("Não foi possivel conectar-se ao servidor!");
            // localStorage.removeItem("token");

            // return err;
        }
        if (err?.response?.status === 500) {
            triggerErrorHandlers(err.response);
        }
        return Promise.reject(err);
    },
);

export default axiosClientChat;
