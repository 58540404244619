import React from "react";
import { Button, Modal, Panel, Tooltip, Whisper } from "rsuite";

interface HeaderComponentProps {
    // nextRouter: () => void;
    previousRoute: ({ route, dataParams }: { route: string, dataParams: Record<string, any> }) => void;
    routePrevious: string;
    dataParams: Record<string, any>;
    showInfoButton?: boolean;
}

interface HeaderComponentState {
    showModalInfo: boolean;
}

export class HeaderComponent extends React.Component<HeaderComponentProps, HeaderComponentState> {
    constructor(props: HeaderComponentProps) {
        super(props);
        this.state = {
            showModalInfo: false,
        };
    }

    renderModalInfo = () => {
        return (
            <Modal
                show={this.state.showModalInfo}
                onHide={() => this.setState({ showModalInfo: false })}
            >
                <Modal.Header>
                    <h4>Informativo</h4>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            gap: 15,
                            padding: 2
                        }}
                    >
                        {["Status não Iniciado", "Status Iniciado", "Status Urgente", "Status Finalizado"].map((value: any, index: number) => {
                            const getBackgroundColor = (status: string) => {
                                switch (status) {
                                    case "Status não Iniciado":
                                        return "#0d5452";
                                    case "Status Iniciado":
                                        return "#FFD700";
                                    case "Status Urgente":
                                        return "#C93337";
                                    case "Status Finalizado":
                                        return "#32CD32";
                                    default:
                                        return "#FFF";
                                }
                            };

                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: '100%',
                                        height: '4rem',
                                        borderRadius: 10,
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 'calc(100% - 4rem)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            background: '#f5f5f5',
                                            paddingLeft: 10,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <h6>{value}</h6>
                                    </div>
                                    <div
                                        style={{
                                            color: "#FFF",
                                            width: '4rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: getBackgroundColor(value),
                                            borderTopRightRadius: 10,
                                            borderBottomRightRadius: 10,
                                        }}
                                    >
                                        <i className="fa fa-circle fa-2x"></i>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    render(): React.ReactNode {
        return (
            <>
                <Panel
                    bordered
                >
                    <div
                        style={
                            {
                                display: 'flex',
                                justifyContent: 'space-between',
                            }
                        }
                    >
                        <div>
                            <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Voltar</Tooltip>}>
                                <Button className="ml-2" color={"blue"} onClick={() => this.props.previousRoute(
                                    {
                                        route: this.props.routePrevious,
                                        dataParams: this.props.dataParams,
                                    }
                                )}>
                                    <i className="fas fa-fw fa-angle-left"></i>
                                </Button>
                            </Whisper>
                        </div>
                        <div
                            style={
                                {
                                    display: 'flex',
                                    // flexDirection: 'row',
                                    // background: 'red',
                                    width: '80%',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    minHeight: 35,
                                    padding: 5,
                                    gap: 10
                                }
                            }

                        >
                            {this.props.dataParams.pathUrl && this.props.dataParams.pathUrl.length > 0 && (
                                <>
                                    {this.props.dataParams.pathUrl.map((value: any, index: number) => {
                                        if (value.labelCrud === "Projeto" && value.labelValue.length > 60) {
                                            value.labelValue = `${value.labelValue.substring(0, 60)}...`;
                                        }
                                        if (value.labelCrud === "Itens" && value.labelValue.length > 20) {
                                            value.labelValue = `${value.labelValue.substring(0, 20)}...`;
                                        }
                                        if (value.labelCrud === "Chats" && value.labelValue.length > 20) {
                                            value.labelValue = `${value.labelValue.substring(0, 20)}...`;
                                        }

                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 10
                                            }}>
                                                <div>
                                                    <div style={{
                                                        alignItems: 'center',

                                                    }}>
                                                        <span style={{
                                                            fontWeight: '600',
                                                            fontSize: 12
                                                        }}>
                                                            {value.labelCrud}
                                                        </span>
                                                    </div>
                                                    <span style={{
                                                        fontSize: 14,
                                                    }}>
                                                        {value.labelValue}
                                                    </span>
                                                </div>
                                                <div style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    {index < this.props.dataParams.pathUrl.length - 1 && (
                                                        <span style={{ fontSize: 30, marginLeft: 4, marginRight: 4 }}>/</span>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}

                                </>
                            )}
                        </div>
                        <div>
                            {this.props.showInfoButton && (
                                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Informações</Tooltip>}>
                                    <Button
                                        className="mr-2"
                                        color={"blue"}
                                        onClick={() => this.setState({ showModalInfo: true })}
                                    >
                                        <i className="fas fa-info-circle"></i>
                                    </Button>
                                </Whisper>
                            )}
                            <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Voltar</Tooltip>}>
                                <Button className="ml-2" color={"blue"} onClick={() => this.props.previousRoute(
                                    {
                                        route: 'projects',
                                        dataParams: this.props.dataParams,
                                    }
                                )}>
                                    <i className="fas fa-home"></i>
                                </Button>
                            </Whisper>
                        </div>
                        {/* <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Voltar</Tooltip>}>
                            <Button className="ml-2" color={"blue"} >
                                <i className="fas fa-fw fa-angle-right"></i>
                            </Button>
                        </Whisper> */}
                    </div>
                </Panel>
                {this.renderModalInfo()}
            </>
        )
    }
}