import { motion } from "framer-motion";
import _ from "lodash";
import React from "react";
import { Button, ControlLabel, ErrorMessage, FormGroup, Loader, Modal, Notification, Panel, Placeholder, SelectPicker, Table, Toggle } from 'rsuite';
import axios from "../../../utilities/axios";
import { item } from "../../motion/motionSettings";
import { MaskInput } from "../MaskInput";
import { NumberInput } from "../NumberInput";
const { Column, HeaderCell, Cell } = Table;

export class CustomFieldSubcharacteristicCustom extends React.Component<any, {}> {
    public state: any = {
        isLoading: false,
        isRead: false,
        isEdit: false,
        isModalOpen: false,
        selectedValues: {},
        form: {},
        forms: [],
        formValues: {},
        items: _.cloneDeep(this.props.values.project_product_flow_custom ?? []),
        gridValues: this.props.values.project_product_flow_custom?.[this.props.index]?.characteristic_grid_values || [],
        tableHeight: 100
    }

    public elementRef: any = null;

    componentDidMount(): void {
        setTimeout(() => {
            this.setState({ tableHeight: 300 }); // bug do scroll no grid
        }, 800);
        // console.log("didimount componente frente", this.props.values.project_product_flow_custom);
    }

    componentWillUnmount(): void {
        this.props.onChange();
    }

    componentDidUpdate(prevProps: any) {
        // console.log("debugs", this.props.values.project_product_flow_custom)
    }

    openAdd = () => {
        const values = this.props.parentValues;
        sessionStorage.setItem("currentParent", JSON.stringify(values));
        sessionStorage.setItem("currentParentIndex", values["custom_field_fk_subcharacteristic_id"]);

        const emptyFormValues: any = {};
        this.props.form.characteristic_forms.forEach((characteristic: any) => {
            switch (characteristic.type) {
                case 1: // String input
                    emptyFormValues[characteristic.label] = "";
                    break;
                case 2: // Toggle (boolean)
                    emptyFormValues[characteristic.label] = false;
                    break;
                case 3: // Number input with step 2
                    emptyFormValues[characteristic.label] = 0;
                    break;
                case 4: // Number input with step 0
                    emptyFormValues[characteristic.label] = 0;
                    break;
                default:
                    emptyFormValues[characteristic.label] = null;
            }
        });

        this.setState({ isRead: false, isModalOpen: true, isEdit: null, selectedValues: {}, formValues: emptyFormValues });
    }

    onClickDelete = async (rowIndex: any, rowData: any) => {
        const currentTable = "custom_fields";
        this.setState({ isConfirmDeleteModal: true, tempDeleteItemID: rowIndex, tempDeleteItem: rowData, tempDeleteTable: currentTable });
    };

    onConfirmDelete = async (rowData: any, rowIndex: any, currentTable: any) => {
        // const currentTable = this.props.field['name'];
        axios.post("/api/v1/perm-delete", { id: rowData["custom_field_id"], currentTable: currentTable, customName: "CustomFieldSubcharacteristicCustom", isCustom: true }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado"
            })
            var items = this.state.items;
            // console.log("debugs onConfirmDelete", items[this.props.index].characteristic_grid_values);
            items[this.props.index].characteristic_grid_values.splice(rowIndex, 1);
            this.setState({ gridValues: items[this.props.index].characteristic_grid_values });
            this.setState({ items, isLoading: true }, () => {
                if (this.props.onChange) {
                    this.props.onChange(items);
                }
                if (this.props.onDelete) {
                    this.props.onDelete(items);
                }
                this.setState({ tempDeleteItemID: null, tempDeleteItem: null, tempDeleteTable: null });
            });
        })
            .catch((err) => {
                Notification.warning(
                    {
                        title: 'Aviso',
                        description: err.response.data.message
                    }
                )
            })
            .finally(() => {
                this.setState({ isLoading: false, isConfirmDeleteModal: false });
            })
    }

    onChangeInput = (value: any, field: string, formIndex: number) => {
        const updatedFormValues = { ...this.state.formValues, [field]: value };
        this.setState({ formValues: updatedFormValues });
    };

    public cachedApiItems: any = {};
    onSubmit = async (formValues: any) => {
        // console.log("debugs formValues", formValues, this.state.formValues)
        // if (_.isEmpty(formValues)) {
        //     return;
        // }
        if (!formValues.subcharacteristic_id) {
            return;
        }
        this.setState(
            (oldState: any) => {
                let items = _.cloneDeep(this.props.values.project_product_flow_custom ?? []);
                if (typeof items !== "object") {
                    items = JSON.parse(items);
                }

                // console.log("debugs", items);

                if (this.state.isEdit !== null) {
                    // console.log("debugs editar row", items[this.props.index].custom_field_subcharacteristics[this.state.isEdit]);
                    items[this.props.index].characteristic_grid_values[this.state.isEdit] = formValues;

                    this.props.values.project_product_flow_custom = JSON.stringify(items);
                    this.setState({ formValues: {} });
                } else {
                    if (typeof items[this.props.index] !== 'object') {
                        items[this.props.index] = {};
                    }

                    if (!Array.isArray(items[this.props.index].characteristic_grid_values)) {
                        items[this.props.index].characteristic_grid_values = [];
                    }

                    // console.log("debugs onSubmit", items[this.props.index].characteristic_grid_values, formValues);
                    items[this.props.index].characteristic_grid_values.push(formValues);
                    // console.log("antis", this.props.values.project_product_flow_custom);
                    this.props.values.project_product_flow_custom = JSON.stringify(items);
                    // console.log("depois", this.props.values.project_product_flow_custom);
                    this.setState({ formValues: {} });
                    // console.log("debugs onSubmit 2", items[this.props.index].characteristic_grid_values);
                }

                return { items, gridValues: items[this.props.index].characteristic_grid_values, isModalOpen: false };
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.items);
                    // console.log("componente da frente", this.props.values.project_product_flow_custom)
                }
                if (this.state.isEdit !== null) {
                    if (this.props.onEdit) {
                        this.props.onEdit(this.state.items, this.state.items.length);
                    }
                } else {
                    if (this.props.onAdd) {
                        this.props.onAdd(this.state.items, this.state.items.length);
                    }
                }
            },
        );
    };

    renderRequiredError = (fieldName: string) => {
        if (Object.keys(this.state.errors).includes(fieldName)) {
            return (
                <ErrorMessage show={true} placement={"bottomEnd"}>
                    <span dangerouslySetInnerHTML={{ __html: this.state.errors[fieldName] }}></span>
                </ErrorMessage>
            );
        }
        return null;
    };

    renderGridActions = (rowData: any, rowIndex: any) => {
        return (
            <div style={{ display: "flex" }}>
                <i onClick={() => this.setState({ isRead: true, isModalOpen: true, selectedValues: rowData, formValues: rowData, isEdit: null })} className="fas text-primary fa-fw mr-2 clickable fa-eye"></i>

                {this.props.readOnly !== true && this.props.canEdit !== false && (
                    <i onClick={() => this.setState({ isRead: false, isModalOpen: true, selectedValues: rowData, formValues: rowData, isEdit: rowIndex })} className="fas text-warning fa-fw mr-2 clickable fa-pencil-alt"></i>
                )}

                {this.props.readOnly !== true && this.props.canDelete !== false && (
                    <i onClick={() => this.onClickDelete(rowIndex, rowData)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                )}

                <Modal show={this.state.isConfirmDeleteModal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                            Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && <>Deseja mesmo excluir esse registro? o registro não poderá ser restaurado!</>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} appearance="primary" onClick={() => {
                            this.onConfirmDelete(this.state.tempDeleteItem, this.state.tempDeleteItemID, this.state.tempDeleteTable)
                        }}>
                            <i className="fas fa-check fa-fw mr-2"></i>Confirmar
                        </Button>
                        <Button disabled={this.state.isLoading} color="red" onClick={() => this.setState({ isConfirmDeleteModal: false })}>
                            <i className="fas fa-times fa-fw mr-2"></i>Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    render(): React.ReactNode {
        const { isLoading, items, formValues, gridValues, tableHeight } = this.state;

        // console.log("debugs props ProjectProductFlowSubcharacteristicCustom", this.props);
        // console.log("debugs items", items[this.props.index]?.custom_field_subcharacteristics);

        const gridSchema = items[this.props.index]?.characteristic_forms;

        // const allValuesEmpty = (data: any) => {
        //     return data.every((item: any, index: number) => console.log("debugues", item, index));
        // };

        // const hasNonEmptyValues = !allValuesEmpty(gridValues);

        // console.log({ gridValues });

        return (
            <>
                {isLoading ? (
                    <Placeholder.Grid rows={5} columns={6} active />
                ) : (
                    <motion.div initial="hidden" animate="visible" variants={item}>
                        <Panel bordered bodyFill style={{ padding: 8, marginBottom: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}>
                                <h5 dangerouslySetInnerHTML={{ __html: "" }}></h5>
                                <div>
                                    <Button color="green" onClick={this.openAdd}>
                                        <i className="fas fa-fw fa-plus mr-2"></i>
                                        Adicionar
                                    </Button>
                                </div>
                            </div>
                            <Table
                                headerHeight={30}
                                rowHeight={35}
                                // className="compact"
                                // style={{ overflow: "scroll" }}
                                renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                                height={tableHeight}
                                affixHorizontalScrollbar
                                affixHeader
                                // shouldUpdateScroll
                                data={gridValues}
                            >
                                <Column key="start" align="left" fixed="left" resizable width={150}>
                                    <HeaderCell>Subcaracterística</HeaderCell>
                                    <Cell>
                                        {(rowData: any) => {
                                            const loadedValues = items[this.props.index].loadedValues;
                                            const matchedValue = loadedValues.find((item: any) => item.value === rowData?.subcharacteristic_id);
                                            return matchedValue ? matchedValue.label : null;
                                        }}
                                    </Cell>
                                </Column>

                                {gridSchema?.map((item: any, index: number) => (
                                    <Column key={index} resizable width={325}>
                                        <HeaderCell>{item.label}</HeaderCell>
                                        <Cell>
                                            {(rowData: any) => {
                                                let output = rowData?.[item.label] || "Não/Nenhum";
                                                // console.log({ rowData })

                                                if ([true, false].includes(rowData?.[item.label])) {
                                                    // console.log({output})
                                                    output = rowData[item.label] ? "Sim" : "Não";
                                                }
                                                return output;
                                            }}
                                        </Cell>
                                    </Column>
                                ))}

                                <Column key="end" fixed="right" width={80}>
                                    <HeaderCell>Ações</HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: any) => {
                                            return this.renderGridActions(rowData, rowIndex);
                                        }}
                                    </Cell>
                                </Column>
                            </Table>

                            <Modal overflow={false} size="md" className="rs-modal-min" show={this.state.isModalOpen} onHide={() => this.setState({ isModalOpen: false, formValues: {} })}>
                                <form className="rs-form rs-form-horizontal rs-form-fixed-width p-6" autoComplete="false">
                                    <Modal.Header closeButton={false} className="mb-4">
                                        {/* <h3>Tipos</h3> */}
                                    </Modal.Header>
                                    <Modal.Body className="m-0">
                                        <fieldset disabled={this.props.readOnly === true} className="column">
                                            <React.Fragment key={this.props.index}>
                                                <motion.div initial="hidden" animate="visible" variants={item}>
                                                    <FormGroup style={{ marginBottom: 0 }}>
                                                        <ControlLabel>
                                                            <div dangerouslySetInnerHTML={{ __html: "Tipo" }}></div>
                                                        </ControlLabel>
                                                        <SelectPicker
                                                            // defaultValue={this.props.form.subcharacteristic_id ?? this.state.selectValues?.[this.state.isEdit]?.["subcharacteristic_id"]}
                                                            value={formValues["subcharacteristic_id"] ?? gridValues?.[this.state.isEdit]?.["subcharacteristic_id"]}
                                                            data={this.props.form.loadedValues}
                                                            onChange={(value) => this.onChangeInput(value, "subcharacteristic_id", this.props.index)}
                                                            style={{ width: 500 }}
                                                            placeholder="Selecione..."
                                                        />
                                                        {/* {this.renderRequiredError(field.name)} */}
                                                    </FormGroup>
                                                </motion.div>
                                                {this.props.form?.characteristic_forms?.map((characteristic: any, charIndex: any) => (
                                                    <React.Fragment key={charIndex}>
                                                        {characteristic.type === 1 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <div className="rs-form-control-wrapper" style={{ width: "67%" }}>
                                                                        <MaskInput
                                                                            value={formValues?.[characteristic.label] ?? ""}
                                                                            name={characteristic.label}
                                                                            onChangeEnd={(value: string) => {
                                                                                this.onChangeInput(value, characteristic.label, this.props.index);
                                                                            }}
                                                                        >
                                                                        </MaskInput>
                                                                    </div>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}

                                                        {characteristic.type === 2 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <Toggle
                                                                        defaultChecked={formValues?.[characteristic.label] ?? false}
                                                                        // checked={characteristic.value ?? false}
                                                                        onChange={(value: boolean) => {
                                                                            this.onChangeInput(value, characteristic.label, this.props.index);
                                                                        }}
                                                                    ></Toggle>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}

                                                        {characteristic.type === 3 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <div className="rs-form-control-wrapper" style={{ width: "35%" }}>
                                                                        <NumberInput
                                                                            punctuation={true}
                                                                            step={2}
                                                                            value={formValues?.[characteristic.label] ?? undefined}
                                                                            onChange={(value: string) => this.onChangeInput(value, characteristic.label, this.props.index)}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}

                                                        {characteristic.type === 4 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <div className="rs-form-control-wrapper" style={{ width: "35%" }}>
                                                                        <NumberInput
                                                                            punctuation={true}
                                                                            step={0}
                                                                            value={formValues?.[characteristic.label] ?? undefined}
                                                                            onChange={(value: string) => this.onChangeInput(value, characteristic.label, this.props.index)}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        </fieldset>
                                    </Modal.Body>
                                    {/* {JSON.stringify(formValues)} */}
                                    {/* {JSON.stringify(gridValues)} */}
                                    <Modal.Footer className="mt-1">
                                        <div className="col-md-12 mt-2 d-flex" style={{ marginBottom: 10 }}>
                                            {!this.state.isRead && (
                                                <Button color="green" onClick={() => this.onSubmit(formValues)}>
                                                    <i className="fas fa-fw fa-save mr-2"></i>
                                                    Gravar
                                                </Button>
                                            )}
                                            <Button appearance="subtle" onClick={() => this.setState({ isModalOpen: false, formValues: {} })}>
                                                <i className="fas fa-times fa-fw mr-2"></i>
                                                Cancelar
                                            </Button>
                                        </div>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                        </Panel>
                    </motion.div>
                )}
            </>
        );
    }
}
