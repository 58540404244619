import { Event } from "../../Event";

export class ProjectProductAppointmentFkProjectProductIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState('loadings', [
                'project_product_appointment_fk_step_id'
            ]);

            this.setField('project_product_appointment_fk_step_id', {
                api: {
                    method: "GET",
                    url: `/api/v1/steps/select?project_product_id=[${value}]`,
                    uses: [
                        {
                            type: "prop",
                        }
                    ],
                }
            });

            this.setState('loadings', []);
        }
    }
}