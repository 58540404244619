import { Event } from "../Event";

export class UserEvents extends Event {
  public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
    if (this.globalProps.match.url.includes('/add')) {
      this.setFieldValue('user_name', '‎');

      this.setFieldValue('user_password', '');
    }
  }
}