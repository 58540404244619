import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from 'rsuite'


export class PersonCpfCnpjEvents extends Event {
    onBlur = (value: any, setField: any, setState: any) => {
        console.log('value :::> ', value.length)
        this.setState({
            loadings: [
                "person_cpf_cnpj",
                "person_phone_number",
                "person_email",
                "person_address_street",
                "person_address_number",
                "person_address_complement",
                "person_address_neighborhood",
                "person_fk_state_id",
                "person_fk_city_id",
                "person_cep_number",
                "person_cell_number",
                "person_name",
                "person_fantasy_name"
            ]
        });

        if(value.length >= 14) {
            this.setField('person_fantasy_name',{
                hidden: false
            })
        }

        axios
            .get("/api/v1/consulta-cnpj/" + value, {
                params: {
                    table: 'users',
                    column: 'user_id',
                    whereColumn: 'person_cpf_cnpj',
                    id: this.globalState.formValues?.user_id
                }
            })
            .then(async function (res) {
                const { data } = res.data;
                if (res.data.error) {
                    Notification.warning({
                        title: 'Aviso',
                        description: res.data.error
                    })
                    return;
                }
                if (data.status !== 'ERROR') {
                    const cepNumber = data.cep.replace(/\D/g, "");
                    const cellNumber = data.telefone.split(" ").join("").replace(/\D/g, "");

                    setField("person_cep_number", cepNumber)
                    if(cellNumber.length === 10) {
                        setField("person_phone_number", cellNumber);
                    } else {
                        setField("person_cell_number", cellNumber);
                    }
                    setField("person_email", data.email);
                    setField("person_address_street", data.logradouro)
                    setField("person_address_number", data.numero)
                    setField("person_address_complement", data.complemento)
                    setField("person_address_neighborhood", data.bairro)
                    setField("person_name", data.nome)
                    setField("person_fantasy_name", data.fantasia)
                    await axios
                        .get("/api/v1/states/select", {
                            params: {
                                state: data.uf,
                            },
                        })
                        .then(async (res) => {
                            var { items } = res.data;
                            const { label, value } = items[0] ?? undefined;
                            if (label && value) {
                                setField("person_fk_state_id", { label, value: value.toString() });

                                await axios
                                    .get("/api/v1/cities/select", {
                                        params: {
                                            search: data.municipio,
                                            state: value,
                                        },
                                    })
                                    .then((responseCity) => {
                                        const { items } = responseCity.data;
                                        console.log({ items })
                                        const { label, value } = items[0] ?? undefined;
                                        if (label && value) {
                                            setField("person_fk_city_id", { label, value: value.toString() });
                                        }
                                    })

                            }
                        })
                }
            })
            .catch((e) => {
                Notification.error(e)
                setState("loadings", []);
            })
            .finally(() => {
                this.setState('loadings', [
                    'person_fk_city_id'
                ])
                this.setState("loadings", []);
            });
    };
}
