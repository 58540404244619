import React from "react";
import { Loader, Tooltip, Whisper,Button , Notification, Table, Panel } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import {CustomModal} from "../../customModal/CustomModal"


const { Column, HeaderCell, Cell } = Table;

export class CreateTablesComplement extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        isImportModalOmie: false,
        omieData: [],
        OmieItens: [],
        confirmCancelModal: false,
        omieSelectitem: [],
        omieDataSelectDelete: [],
        propsPsm: null,
    }

    componentDidMount = async() =>{

    }

    public select: any = {};

    onChange = (item: any, valuesOmie: any) => {

    };

    isExecuteQuery = () => {
        let props = this.props.values;
        if((props.development_check_tables == false) 
        && (props.development_redo_views == false) 
        && (props.development_table_name == "")
        ){
            Notification.error({
                title: "error!",
                description: "Não foi selecionado nenhum valor para criação das tabelas!",
                duration: 5600,
            });
            return
        }
        if(props.development_check_tables == true && props.development_redo_views == true){
            Notification.error({
                title: "error!",
                description: "Selecione apenas uma ação por vez!!",
                duration: 5600,
            });
            return
        }
        
        this.setState({
            loading: true,
        })
        // console.log("props :::",props?.development_table_dad !== undefined ? props.development_table_dad : '')
        // axios
        //     .post("/api/v1/dev/create-tables",{
        //         checkTables: props.development_check_tables,
        //         redoViws: props.development_redo_views,
        //         tableName: props.development_table_name,
        //         tableDad: props?.development_table_dad !== undefined ? props.development_table_dad : '',
        //         checkSlideDad: props.development_dependecies,
        //         checkIfConsummerAll: props.development_consumer_is_all_companies
        //     })
        //     .then((res => {
        //         if(res.data.status == 200){
        //             Notification.success({
        //                 title: "Sucesso!",
        //                 description: res.data.message,
        //                 duration: 6600,
        //             });
        //             setTimeout(this.props.history.go(0),7000)
        //         }
        //         if(res.data.status == 201){
        //             Notification.success({
        //                 title: "Erro!",
        //                 description: res.data.message,
        //                 duration: 6600,
        //             });
        //         }
                
        //     }))
        //     .finally(() => {
        //         this.setState({ loading: false });
        //     });
        axios
        .post("/api/v1/dev/create-tables",{
            dataInsert: {
                checkTables: props.development_check_tables,
                redoViews: props.development_redo_views,
                tableName: props.development_table_name,
                tableDad: props?.development_table_dad !== undefined ? props.development_table_dad : '',
                checkSlideDad: props.development_dependecies,
                checkIfConsumerAll: props.development_consumer_is_all_companies
            }
        })
        .then((res => {
            if(res.data.status == 200){
                Notification.success({
                    title: "Sucesso!",
                    description: res.data.message,
                    duration: 6600,
                });
                setTimeout(this.props.history.go(0),7000)
            }
            if(res.data.status == 201){
                Notification.success({
                    title: "Erro!",
                    description: res.data.message,
                    duration: 6600,
                });
            }
            
        }))
        .finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        console.log('Props development ::: ',this.props)
        return (<>
            <Button color="violet" size={"lg"} loading={this.state.loading} style={{marginTop: 20, width: "100%"}} onClick={() => this.isExecuteQuery()}>
                <i className="fal fa-folder-plus"></i> Executar
            </Button>
        </>)
    }
}
