import { motion } from "framer-motion";
import _ from "lodash";
import React from "react";
import { ControlLabel, FormGroup, Nav, Panel, Placeholder, Table, Toggle } from 'rsuite';
import axios from "../../../utilities/axios";
import { item } from "../../motion/motionSettings";
import { MaskInput } from "../MaskInput";
import { NumberInput } from "../NumberInput";
import { CustomFieldSubcharacteristicCustom } from "./CustomFieldSubcharacteristicCustom";
const { Column, HeaderCell, Cell } = Table;

export class ProjectProductFlowCustom extends React.Component<any, {}> {
    public state: any = {
        isLoading: false,
        forms: [],
        project_product_fk_product_id: null,
        formValues: {},
        activeTab: null
    }

    componentDidMount(): void {
        if (this.props.values.project_product_flow_custom && typeof this.props.values.project_product_flow_custom === "string") {
            this.props.values.project_product_flow_custom = JSON.parse(this.props.values.project_product_flow_custom);
            // console.log('componentDidMount', this.props.values.project_product_flow_custom);
            this.setState({ forms: this.props.values.project_product_flow_custom });
        }

        if (this.props.values.project_product_fk_product_id && !this.props.values.project_product_flow_custom) {
            this.setState({ isLoading: true });
            axios
                .get(`/api/v1/custom/flowserize/${this.props.values.project_product_fk_product_id}/${this.props.parentValues.project_id}/${this.props.values.project_product_id}`)
                .then((res) => {
                    if (res && res.data) {
                        const items: any = res.data?.items;

                        this.setState({ forms: items, activeTab: items[0]?.characteristic_description });
                        this.props.values.project_product_flow_custom = items;
                    }
                }).finally(() => {
                    this.setState({ isLoading: false });
                });
        } else {
            // console.log("debugs componentDidMount", this.props.values.project_product_flow_custom)
            this.setState({ forms: this.props.values.project_product_flow_custom });
        }
    }

    componentWillUnmount(): void {
        this.props.onChange();
    }

    componentDidUpdate(prevProps: any) {
        // console.log("componente de tras", this.props.values.project_product_flow_custom)
        this.props.values.project_product_flow_custom = this.props.values.project_product_flow_custom || this.state.formValues;

        // console.log("as vezes aparece as vezes nao aparece", prevProps.values.project_product_fk_product_id, this.state.project_product_fk_product_id);
        if (prevProps.values.project_product_fk_product_id !== this.state.project_product_fk_product_id) {
            const flow_id = this.props.values.project_product_fk_product_id;

            if (typeof this.props.values.project_product_flow_custom !== 'object' || this.props.values.project_product_flow_custom === null) {
                this.props.values.project_product_flow_custom = {};
            }

            this.props.values.project_product_flow_custom.product_id = flow_id;
            this.props.values.project_product_flow_custom.project_id = this.props.parentValues.project_id;

            this.setState({ project_product_fk_product_id: flow_id });

            const hasValues = _.some(this.props.values.project_product_flow_custom, (flow) => {
                // console.log("buscando flowserize", flow?.characteristic_forms);
                if (flow?.characteristic_grid_values && flow?.characteristic_grid_values !== undefined) {
                    return true;
                }
            });

            if (hasValues) {
                this.setState({ forms: this.props.values.project_product_flow_custom });
            }

            // console.log('componentDidUpdate', this.props.values.project_product_flow_custom);
            if (flow_id && !hasValues) {
                axios
                    .get(`/api/v1/custom/flowserize/${flow_id}/${this.props.parentValues.project_id}/${this.props.values.project_product_id}`)
                    .then((res) => {
                        this.setState({ isLoading: true });
                        if (res && res.data) {
                            const items: any = res.data?.items;

                            this.setState({ forms: items });
                            this.props.values.project_product_flow_custom = items;
                            this.setState({ isLoading: false });
                        }
                    });
            }
        }
    }

    capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    updateFormValues = (field: string, value: any) => {
        this.setState((prevState: any) => ({
            formValues: {
                ...prevState.formValues,
                [field]: value,
            }
        }));
    };

    selectTab = (tabKey: string) => {
        this.setState({ activeTab: tabKey });
    }

    onChangeInput = (value: any, field: any, formIndex: any) => {
        const uniqueField = `${field}_${formIndex}`;
        const updatedFormValues = { ...this.state.formValues, [uniqueField]: value };
        this.setState({ formValues: updatedFormValues });

        // const formsProps = typeof this.props.values.project_product_flow_custom !== "object" ? JSON.parse(this.props.values.project_product_flow_custom) : this.props.values.project_product_flow_custom;

        let formsBatata = _.cloneDeep(this.props.values.project_product_flow_custom);
        if (typeof formsBatata !== "object") {
            formsBatata = JSON.parse(formsBatata);
        }

        // console.log("debugs 1", formsBatata);
        const updatedForms = formsBatata.map((form: any, index: any) => {
            // console.log("debugs 2", form);
            if (index === formIndex) {
                let updatedForm = { ...form };

                const updatedCharacteristics = updatedForm.characteristic_forms.map((characteristic: any) => {
                    if (characteristic.label === field) {
                        return { ...characteristic, value };
                    }
                    // console.log("debugs 3", characteristic);
                    return characteristic;
                });

                // console.log("debugs set", updatedForm, form);
                updatedForm.characteristic_forms = updatedCharacteristics;
                updatedForm.characteristic_grid_values = form.characteristic_grid_values;

                // console.log("debugs 4", updatedForm);
                return updatedForm;
            }
            return form;
        });

        this.setState({ forms: updatedForms });
        this.props.values.project_product_flow_custom = JSON.stringify(updatedForms);
    };

    render(): React.ReactNode {
        const { project_product_fk_product_id, isLoading, forms, activeTab } = this.state;

        if (!project_product_fk_product_id) {
            return null;
        }

        console.log({activeTab})
        if (activeTab === null && forms?.[0]?.characteristic_description) {
            this.setState({ activeTab: forms?.[0]?.characteristic_description });
        }
        // console.log("debugs global", this.props.values.project_product_flow_custom);

        return (
            <>
                {isLoading ? (
                    <div className="mt-4">
                        <Placeholder.Grid rows={5} columns={6} active />
                    </div>
                ) : (
                    forms?.length > 0 && (
                        <div className="mt-4">
                            <h3>Características</h3>
                            <motion.div initial="hidden" animate="visible" variants={item}>
                                <Nav appearance="subtle" activeKey={activeTab} onSelect={this.selectTab}>
                                    {forms?.map((tab: any, index: number) => (
                                        <Nav.Item
                                            eventKey={tab.characteristic_description}
                                            key={index}
                                        >
                                            {this.capitalizeFirstLetter(tab.characteristic_description)}
                                        </Nav.Item>
                                    ))}
                                </Nav>

                                <Panel bordered>
                                    {forms?.map((form: any, formIndex: any) => (
                                        <div key={formIndex} style={{ display: form.characteristic_description === activeTab ? 'block' : 'none' }}>
                                            {form?.characteristic_is_registrable && (
                                                <CustomFieldSubcharacteristicCustom
                                                    {...this.props}
                                                    // key={`${activeTab}-${formIndex}`}
                                                    forms={forms}
                                                    form={form}
                                                    onChangeInput={this.onChangeInput}
                                                    index={formIndex}
                                                />
                                            )}
                                            {!form?.characteristic_is_registrable && (
                                                form?.characteristic_forms?.map((characteristic: any, charIndex: any) => (
                                                    <React.Fragment key={charIndex}>
                                                        {characteristic.type === 1 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <div className="rs-form-control-wrapper">
                                                                        <MaskInput
                                                                            value={characteristic.value ?? ""}
                                                                            name={characteristic.label}
                                                                            onChangeEnd={(value: string) => {
                                                                                this.onChangeInput(value, characteristic.label, formIndex);
                                                                            }}
                                                                        >
                                                                        </MaskInput>
                                                                    </div>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}

                                                        {characteristic.type === 2 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <Toggle
                                                                        defaultChecked={characteristic.value ?? false}
                                                                        checked={characteristic.value ?? false}
                                                                        onChange={(value: boolean) => {
                                                                            this.onChangeInput(value, characteristic.label, formIndex);
                                                                        }}
                                                                    ></Toggle>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}

                                                        {characteristic.type === 3 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <div className="rs-form-control-wrapper">
                                                                        <NumberInput
                                                                            punctuation={true}
                                                                            step={2}
                                                                            value={characteristic.value ?? undefined}
                                                                            onChange={(value: string) => this.onChangeInput(value, characteristic.label, formIndex)}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}

                                                        {characteristic.type === 4 && (
                                                            <motion.div initial="hidden" animate="visible" variants={item}>
                                                                <FormGroup style={{ marginBottom: 0 }}>
                                                                    <ControlLabel>
                                                                        <div dangerouslySetInnerHTML={{ __html: characteristic.label }}></div>
                                                                    </ControlLabel>
                                                                    <div className="rs-form-control-wrapper">
                                                                        <NumberInput
                                                                            punctuation={true}
                                                                            step={0}
                                                                            value={characteristic.value ?? undefined}
                                                                            onChange={(value: string) => this.onChangeInput(value, characteristic.label, formIndex)}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </motion.div>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            )}
                                        </div>
                                    ))}
                                </Panel>
                                {/* {console.log({ forms })} */}
                                {/* {console.log({ formValues })} */}
                            </motion.div>
                        </div>
                    )
                )}
            </>
        );
    }
}
