import React from "react";
import { Container, Header, Content, Button, Placeholder, Avatar } from "rsuite";
import axiosClientChat from "../../utilities/axiosClientChat";
import config from "../../globals";
import { HeaderComponente } from "../../components/chatClient/Header";
import { ModalInfoChatStatus } from "../../components/formfields/custom/chatUsers/components/ModalInfoChatStatus";
import { ChatProviderContext } from "../../components/chatClient/chatProvider/main";
import { ChatClientRender } from "../../components/formfields/custom/chatUsers/components/ChatClientRender";

export class ReadListItems extends React.Component<any, {}> {
    static contextType = ChatProviderContext;
    state = {
        data: [
        ]
    }

    componentDidMount(): void {
        axiosClientChat.get(`/chat-client/project_products/${this.props.match.params.projectId}/get`)
            .then((response) => {
                this.setState({ data: response.data.item })
            })
    }


    TwentyTimesParagraphs = () => {
        // Cria um array com 20 elementos vazios
        const array = Array.from({ length: 20 });

        return (
            <>
                {array.map((_, index) => (
                    <Placeholder.Paragraph key={index} graph='square' active />
                ))}
            </>
        );
    };

    // selectItem = ({ data }: { data: Record<string, any> }) => {
    //     const { setPathUrl } = this.context;
    //     setPathUrl(
    //         {
    //             path: 'Item',
    //             labelValue: data.product_name
    //         }
    //     )
    //     const currentRouteSpliced = this.props.history.location.pathname
    //     // const currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");
    //     // console.log({currentRouteSpliced})
    //     this.props.history.push(`${currentRouteSpliced}/chats/${data.project_product_id}/${data.project_product_fk_product_id}`)
    // }


    selectChatClient = ({ data }: { data: Record<string, any> }) => {
        const { setPathUrl } = this.context;
        setPathUrl(
            {
                path: 'Chat',
                labelValue: data.steps[0].step_name
            }
        )
        const currentRouteSpliced = this.props.history.location.pathname;
        console.log({ currentRouteSpliced })
        localStorage.setItem('step_item', JSON.stringify(
            {
                step_color: data.steps[0].step_color,
                step_created_at: data.steps[0].step_created_at,
                step_created_by: data.steps[0].step_created_by,
                step_fk_company_id: data.steps[0].step_fk_company_id,
                step_id: data.steps[0].step_id,
                step_name: data.steps[0].step_name,
                step_updated_at: data.steps[0].step_updated_at,
            }
        ));
        this.props.history.push(`${currentRouteSpliced}/chat-client/${data.steps[0].step_id}`);
    }

    renderProjects = () => {
        return this.state.data.map((value: any, index) => {
            return (<>

                <div
                    style={
                        {
                            width: '100%',
                            minHeight: '4rem',
                            borderRadius: 10,
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: '#131047',
                            // cursor: 'pointer'
                        }
                    }
                >
                    <div
                        style={
                            {
                                width: '85%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                flexWrap: 'wrap',
                                background: '#f5f5f5',
                                paddingLeft: 10,
                                borderRadius: 10,
                                // alignItems: "center",
                            }
                        }
                    >
                        <div style={{ display: "flex", flexDirection: "row" }}>

                            <Avatar
                                src={`http://${window.location.host.split('25565').join('25567')}${value.product_file[0]?.file_path}`}
                                circle
                            />

                            <div style={{ display: "flex", flexDirection: "column", marginLeft: 12 }}>

                                <label>Item</label>

                                <h6>
                                    {value.product_name}
                                </h6>
                            </div>

                        </div>

                    </div>
                    <div
                        style={
                            {
                                width: '16%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }
                        }
                    >
                        <p
                            style={{ color: "#fff" }}
                        >

                            <ModalInfoChatStatus
                                onClose={() => this.setState({ showModal: false })}
                                params={
                                    {
                                        project_product_id: value.project_product_id,
                                        project_product_fk_product_id: value.project_product_fk_product_id,
                                    }
                                }
                                typeCall="webPerson"
                            />
                        </p>
                    </div>
                </div>


            </>)
            // return (
            //     <div
            //         style={
            //             {
            //                 // width: '12rem',
            //                 width: '100%',
            //                 minHeight: '10rem',
            //                 background: '#e9e9e9',
            //                 borderRadius: 10,
            //                 display: 'grid',
            //                 gridTemplateRows: '2fr 1fr',
            //                 padding: 10,
            //             }
            //         }
            //     >
            //         <div
            //             style={
            //                 {
            //                 }
            //             }
            //         >
            //             <div>
            //                 <Avatar
            //                     src={`http://${window.location.host.split('25565').join('25567')}${value.product_file[0]?.file_path}`}
            //                     circle
            //                 />
            //             </div>
            //             <div>
            //                 <label>Item</label>
            //                 <h6>
            //                     {value.product_name}
            //                 </h6>
            //             </div>
            //         </div>
            //         <div
            //             style={
            //                 {
            //                     display: "flex",
            //                     alignItems: 'flex-end',
            //                     gap: 5
            //                 }
            //             }
            //         >
            //             {/* <Button
            //                 color="blue"
            //                 style={
            //                     {
            //                         width: '70%',
            //                         height: '2rem',                                }
            //                 }
            //                 onClick={() => this.selectItem({ data: value })}
            //             >
            //                 Acessar
            //             </Button> */}
            //             <div

            //                 style={
            //                     {
            //                         width: '100%'
            //                     }
            //                 }
            //             >
            //                 <ModalInfoChatStatus
            //                     onClose={() => this.setState({ showModal: false })}
            //                     params={
            //                         {
            //                             project_product_id: value.project_product_id,
            //                             project_product_fk_product_id: value.project_product_fk_product_id,
            //                         }
            //                     }
            //                     typeCall="webPerson"
            //                 />
            //             </div>
            //         </div>
            //     </div>
            // )
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Container
                    style={
                        {
                            gap: 10
                        }
                    }
                >
                    <HeaderComponente
                        title="Itens do Projeto"
                        useBack={true}
                        useInfo={true}
                        onBack={() => {
                            const { setPathUrl } = this.context;
                            setPathUrl(
                                {
                                    path: '',
                                    labelValue: '',
                                    direction: 'back'
                                }
                            )
                            this.props.history.go(-1)
                        }}
                    />
                    <Content
                        style={
                            {
                                // display: 'flex',
                                // margin: 'auto',
                                paddingTop: 10,
                                justifyContent: 'center',
                                alignContent: 'center'
                            }
                        }
                    >
                        <div
                            style={
                                {

                                    margin: 'auto',
                                }
                            }
                        >
                            <div
                                style={
                                    {
                                        display: 'flex',
                                        gap: 30,
                                        flexDirection: 'column',
                                        flexWrap: 'wrap',
                                        justifyContent: 'start',
                                    }
                                }
                            >
                                {this.state.data.length > 0 ? (
                                    <>
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    gap: 5,
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'start',
                                                }
                                            }
                                        >
                                            {this.renderProjects()}
                                        </div>
                                        <ChatClientRender
                                            paramsRoute={this.props.match.params}
                                            typeCall="webPerson"
                                            selectItem={this.selectChatClient}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Placeholder.Paragraph rows={12} active />
                                        {/* {this.TwentyTimesParagraphs()} */}
                                    </>
                                )}
                            </div>
                        </div>
                    </Content>
                </Container>
            </>
        )
    }
}