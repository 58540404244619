import React from "react";
import { Button } from "rsuite";
// import axios from "axios";
import axios from "axios";
import axiosClientChat from "../../utilities/axiosClientChat";
import { AxiosInstance } from "axios";
import config from "../../globals";

interface DownloadImageProps {
    urlImage: string;
}

export class DownloadImage extends React.Component<DownloadImageProps> {

    downloadImage = () => {
        let definedAxios: AxiosInstance = axios;
            if (localStorage.getItem('chatToken')) {
                definedAxios = axiosClientChat;
            }

            let baseUrl = config.url;
            let extractedPath = this.props.urlImage;
            if (config.url.includes("localhost")) {
                baseUrl = config.url.split('25565').join('25567');
                extractedPath = this.props.urlImage.substring(this.props.urlImage.indexOf(baseUrl) + baseUrl.length);
            }

            // console.log("debugs salvar", config.url, baseUrl, extractedPath);

            definedAxios.get(extractedPath, {
                responseType: 'blob'
            }).then((response: any) => {
                console.log("resposatataat", response)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `chat_image.${response.data.type.replace('image/', '')}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }

    render(): React.ReactNode {
        return (
            <Button
                color="green"
                onClick={this.downloadImage}
            >
                Download <i className="fas fa-download"></i>
            </Button>
        );
    }
}