import React from "react"
import { withRouter } from "react-router-dom"
import { Button, Container, Content, Divider, FlexboxGrid, Header, Modal, Notification } from "rsuite"
import { Placeholder } from 'rsuite';
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import dayjs from "dayjs";

const { Paragraph } = Placeholder;

interface IBrowserPage {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    // previousRoute: () => void;
}


export class BrowserPage extends React.Component<IBrowserPage, {}> {

    state = {
        data: [
        ],
        loading: true,
        showModalInfo: false
    }

    componentDidMount(): void {
        axios.get(`/api/v1/project/list`)
            .then(async (response) => {
                this.setState({ data: response.data.items })
            })
            .catch((e) => {
                // Notification.error(
                //     {
                //         title: 'Ops, parece que algo deu errado!',
                //         description: e.response.data.message
                //     }
                // )
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }


    selectItem = ({ data }: { data: any }) => {
        this.props.nextRouter(
            {
                route: 'products',
                paramsRoute: {
                    project_id: data.project_id,
                    pathUrl: [{
                        labelCrud: 'Projeto',
                        labelValue: data.project_code + " - " + data.project_description + " - " + data.people.person_name,
                        value: 0
                    }],
                    data
                }
            }
        )
    }

    TwentyTimesParagraphs = () => {
        // Cria um array com 20 elementos vazios
        const array = Array.from({ length: 10 });

        return (
            <>
                {array.map((_, index) => (
                    <Paragraph
                        style={{
                            width: '12rem',
                            height: '12rem',
                            background: '#e9e9e9',
                            borderRadius: 10,
                            display: 'grid',
                            gridTemplateRows: '25px 1fr 30px',
                            padding: 8
                        }} key={index} active>
                        <Placeholder.Grid active rows={5} />
                    </Paragraph>
                ))}
            </>
        );
    };

    renderModalInfo = () => {
        return (
            <Modal
                show={this.state.showModalInfo}
                onHide={() => this.setState({ showModalInfo: false })}
            >
                <Modal.Header>
                    <h4>Informativo</h4>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            gap: 15,
                            padding: 2
                        }}
                    >
                        {["Status Iniciado", "Status Urgente", "Status Finalizado", "Arquivado/Cancelado"].map((value: any, index: number) => {
                            const getBackgroundColor = (status: string) => {
                                switch (status) {
                                    case "Status Iniciado":
                                        return "#e9e9e9";
                                    case "Status Urgente":
                                        return "#C93337";
                                    case "Status Finalizado":
                                        return "#32CD32";
                                    case "Arquivado/Cancelado":
                                        return "#9fa3a4";
                                    default:
                                        return "#FFF";
                                }
                            };

                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: '100%',
                                        height: '4rem',
                                        borderRadius: 10,
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 'calc(100% - 4rem)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            background: '#f5f5f5',
                                            paddingLeft: 10,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <h6>{value}</h6>
                                    </div>
                                    <div
                                        style={{
                                            color: "#FFF",
                                            width: '4rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: getBackgroundColor(value),
                                            borderTopRightRadius: 10,
                                            borderBottomRightRadius: 10,
                                        }}
                                    >
                                        <i className="fa fa-circle fa-2x"></i>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    renderProjects = () => {
        const getBackgroundColor = (status: string) => {
            console.log({ status })
            switch (status) {
                case "INICIADO":
                    return "#e9e9e9";
                case "URGENTE":
                    return "#C93337";
                case "FINALIZADO":
                    return "#32CD32";
                case "ARQUIVADO/CANCELADO":
                    return "#9fa3a4";
                default:
                    return "#FFF";
            }
        };

        return this.state.data.map((value: any, index) => {
            return (
                <div

                    style={
                        {
                            width: '90%',
                            display: "flex",
                            flexDirection: "column",
                            background: "#fafafa",
                            borderRadius: 10,
                            border: '1px solid rgba(0, 0, 0, 0.1)',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                            gap: 5
                        }
                    }
                >
                    <div
                        style={
                            {
                                width: '100%',
                                minHeight: '4rem',
                                borderRadius: 10,
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: getBackgroundColor(value.situations.situation_name),
                                cursor: 'pointer'
                            }
                        }
                        onClick={() => this.selectItem({ data: value })}
                    >
                        <div
                            style={
                                {
                                    width: '85%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    flexWrap: 'wrap',
                                    background: '#f5f5f5',
                                    paddingLeft: 10,
                                    borderRadius: 10,
                                    // alignItems: "center",
                                }
                            }
                        >
                            <FlexboxGrid>
                                <FlexboxGrid.Item order={1} colspan={8}>
                                    <div >
                                        <label>Projeto</label>
                                        <h6>
                                            {value.project_code} - {value?.project_description}
                                            {/* - {value.companies.company_fantasy} */}
                                        </h6>
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item order={2} colspan={8}>
                                    <div >
                                        <label>Cliente</label>
                                        <h6>
                                            {/* {(value?.people?.person_cpf_cnpj)} |  */}
                                            {value?.people?.person_name}
                                        </h6>
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item order={3} colspan={8}>
                                    <div >
                                        <label>Endereço</label>
                                        <h6>
                                            {value?.project_street} , {value?.project_number} {value?.project_district} - {value?.cities?.city_name}-{value?.states?.state_name}
                                        </h6>

                                    </div>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </div>
                        <div
                            style={
                                {
                                    width: '16%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }
                            }
                        >
                            <i className="far fa-comments fa-2x " style={{ color: '#575757' }}></i>
                            <p
                                style={{ color: "#575757" }}
                            >
                                Chat
                                {/* 0 */}
                            </p>
                        </div>
                    </div>
                    <div
                        style={
                            {
                                width: "85%",
                                minHeight: "20px",
                                paddingLeft: 10,
                                color: "#000",
                            }
                        }
                    >
                        {value.remainingProjectTime >= 1 && (
                            <p
                                style={
                                    {
                                        color: "rgba(0, 0, 0, 0.6)"
                                    }
                                }
                            >
                                Prazo restante do Projeto: {value.remainingProjectTime} dias
                            </p>
                        )}
                    </div>
                </div>
            )
        })
    }

    render(): React.ReactNode {
        return (
            <>
                {/* <HeaderComponent
                    // nextRouter={this.props.nextRouter}
                    previousRoute={this.props.previousRoute}
                /> */}
                <Content
                    style={
                        {
                            // display: 'flex',
                            margin: 'auto',
                            paddingTop: 10,
                            justifyContent: 'center',
                            alignContent: 'center'
                        }
                    }
                >
                    <div
                        style={
                            {

                                margin: 'auto'
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    display: 'flex',
                                    gap: 5,
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center'
                                }
                            }
                        >
                            {!this.state.loading ? (
                                <>
                                    {this.state.data.length == 0 ? (
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    // height: '50vh',
                                                    justifyContent: 'center',
                                                    alignItems: "center",
                                                    gap: 5,
                                                    // flexWrap: 'wrap',
                                                    // textAlign: "center",
                                                    // background: '#131047',
                                                    // padding: 15,
                                                    // borderRadius: 20,
                                                    // boxShadow: '20px 20px 10px 5px rgba(0, 0, 0, 0.2)',
                                                    // color: '#fff'
                                                }
                                            }
                                        >
                                            <h6 style={{ color: "black" }}>
                                                Nenhum Projeto Encontrado!
                                            </h6>
                                            {/* <h1>
                                                Sem Projetos para esse Usuario
                                            </h1>
                                            <i className="fas fa-times fa-5x"></i> */}
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                style={
                                                    {
                                                        width: '100%',
                                                        minHeight: '1rem',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        backgroundColor: '#FFF',
                                                        marginBottom: "6px"
                                                    }
                                                }
                                            >
                                                <div
                                                    style={
                                                        {
                                                            width: '85%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: "center",
                                                            flexWrap: 'wrap',
                                                            background: '#FFF',
                                                            paddingLeft: 10,
                                                            borderRadius: 10,
                                                        }
                                                    }
                                                >

                                                </div>
                                                <div
                                                    style={
                                                        {
                                                            color: "#575757",
                                                            width: '16%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }
                                                    }
                                                >
                                                    <Button size="md" appearance="primary" onClick={() => this.setState({ showModalInfo: true })}>
                                                        <i className="far fa-info-circle"></i>
                                                    </Button>
                                                </div>
                                            </div>
                                            {this.renderModalInfo()}
                                            {this.renderProjects()}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Placeholder.Graph active rows={12} />
                                    {/* {this.TwentyTimesParagraphs()} */}
                                </>
                            )}
                        </div>

                    </div>
                </Content>
            </>
        )
    }
}