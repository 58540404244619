import dayjs from "dayjs";
import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class ProjectProductAppointmentEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any) => {
        this.setState('loadings', [
            'project_product_appointment_fk_user_id',
            'project_product_appointment_fk_project_product_id',
            'project_product_appointment_init'
        ]);

        this.setField('project_product_appointment_fk_project_product_id', {
            api: {
                method: "GET",
                url: `/api/v1/project_products/select?project_id=[${this.globalProps.parentValues.project_id}]`,
                uses: [
                    {
                        type: "prop",
                    }
                ],
            },
            // displayLabel: "products.product_name",
            // browseLabel: "products.products.product_name",
            // useActive: false
        });

        // console.log("debugs globalProps", this.globalProps.values)

        if (this.globalProps.isEdit === null) {
            this.setFieldValue('project_product_appointment_init', dayjs().format("DD/MM/YYYY HH:mm"));
            axios
                .get("/api/v1/me")
                .then((res) => {
                    this.setFieldValue('project_product_appointment_fk_user_id', res.data.user.user_id);
                });
        }

        setTimeout(() => {
            this.setState('loadings', []);
        }, 1000);
    }
}