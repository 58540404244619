import React from "react";
import { Whisper, Popover, Badge, Button } from "rsuite";
import { NotificationsComponent } from "./NotificationComponent";
import axios from "../../utilities/axios";
import _ from "lodash";
import { AxiosResponse } from "axios";



interface stateData {
    messages: any[],
    loading: boolean,
    hasNotification: boolean,
    isOpen: boolean,
}


interface NotificationMessageProps {

}


export class NotificationMessage extends React.Component<any, stateData> {

    constructor(props: any) {
        super(props);
        this.state = {
            messages: [],
            hasNotification: false,
            isOpen: false,
            loading: false,
        }
    }


    componentDidMount(): void {
        this.loadMessagesChat()
    }


    redirect({ itemMessage }: { itemMessage: Record<string, any> }) {
        const item = {
            route: 'chatPage',
            params: itemMessage.params_custom
        }
        localStorage.setItem('chatWithNotification', JSON.stringify(item))
        this.props.history.push('/dashboard/project/chats')
    }

    loadMessagesChat = () => {
        this.setState({ loading: true })
        axios.get("/api/notification/messages")
            .then((res) => {
                this.setState(
                    {
                        messages: [...res.data.items]
                    }
                )
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    delete = (id: string | number) => {
        const { messages } = this.state;
        const filterMessage = _.filter(messages, { 'id': id });

        axios.get("/api/notification/messages/read", {
            params: {
                message_id: id
            }
        }).then((res) => {
            if (res.data.status === 200) {
                // var messages = [...this.state.messages];
                // messages.splice(index,1);
                // this.redirect(
                //     {
                //         itemMessage: filterMessage[0]
                //     }
                // )
                this.loadMessagesChat();
            }
        });
    };

    deleteAllMessages = () => {
        axios.get('/api/notification/messages/all-read')
            .then((res: AxiosResponse) => {
                this.loadMessagesChat();
            })
    }

    render(): React.ReactNode {
        return (
            <Whisper
                speaker={
                    <Popover>
                        {(!this.state.loading && this.state.messages.length > 0) && (
                            <Button
                                color="cyan"
                                onClick={() => {
                                    this.deleteAllMessages();
                                    this.setState({ isOpen: false })
                                }}
                            >
                                Limpar todas as mensagens!
                            </Button>
                        )}
                        <NotificationsComponent loading={this.state.loading} onClick={this.delete} messages={this.state.messages} />
                    </Popover>
                }
                trigger={"click"}
                onClose={() => {
                    // this.deleteAllMessages()
                    this.setState({ isOpen: false })
                }}
                onClick={() => {
                    this.setState({ hasNotification: false, isOpen: true });
                    this.loadMessagesChat();
                }}
                // open={true}
                placement={"bottomStart"}>
                <span className="mr-4">
                    {/* {this.state.isOpen === false && this.state.hasNotification === true && (
                    <Tooltip visible={true} placement="bottomEnd">
                        Você possui novas notificações!
                    </Tooltip>
                )} */}
                    <Badge maxCount={40} content={this.state.messages.length > 0 ? this.state.messages.length : false}>
                        <i className="fas ml-4 mr-2 fa-fw fa-comment"></i>
                    </Badge>
                    {/* <i className={classNames("fas fa-fw ml-4 fa-bell")}>{this.state.hasNotification === true && <div className="bell-ball">•</div>}</i> */}
                </span>
            </Whisper>
        )
    }
}