import React from "react";
import { Panel } from "rsuite";
import { AppointmentReport } from "./reportPages/AppointmentReport";
import { Helmet } from "react-helmet";

export class ReportPage extends React.Component<any, {}> {
    render(): React.ReactNode {
        return (
            <div className="container" style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }}>
                 <Helmet>
                    <title>FastFlow - Relatórios</title>
                </Helmet>
               
                <div style={{ width: "100%", margin: 'auto', display: "flex", gap: 5 }}>
                    <Panel header={<b>Apontamentos de Projeto</b>} collapsible defaultExpanded bordered style={{ background: "white", width: "100%" }}>
                        <AppointmentReport />
                    </Panel>
                </div>
            </div>
        )
    }
}
