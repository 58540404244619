import { Notification } from "rsuite";
import axios from "../../utilities/axios";
import { Event } from "../Event";

export class ProjectZipCodeEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings',[
            'project_street',
            'project_number',
            'project_complement',
            'project_district',
            'project_fk_state_id',
            'project_fk_city_id'
        ])
        
        await axios.get('/api/v1/search/cep', {
            params: {
                cepNumber: value
            }
        })
        .then((response) => {
            if(response.data) {
                this.setFieldValue('project_street', response.data.item.logradouro)
                this.setFieldValue('project_complement', response.data.item.complemento)
                this.setFieldValue('project_district', response.data.item.bairro)
                this.setFieldValue('project_fk_state_id', response.data.item.state_id)
                // this.setField('project_fk_city_id', response.data.item.city_id)
                setField("project_fk_city_id", { label: response.data.item.cities.city_name, value: response.data.item.cities.city_id.toString() });
            }
        })
        .catch((e: any) => {
            if(e.response) {
                Notification.info({
                    title: 'Info',
                    description: e?.response.data.message
                })
            }
        })
        .finally(() => {
            this.setState('loadings',[])
        })
    }
}