import React, { RefObject, createRef } from "react";
import { Container, Content, Dropdown, IconButton, Modal, Notification, Placeholder } from "rsuite";
import { HeaderComponente } from "../../components/chatClient/Header";
import { AttachmentComponent } from '../../components/chatClient/AttachmentComponent';
import axiosClientChat from "../../utilities/axiosClientChat"
import { Button, Input } from "rsuite";
import dayjs from "dayjs";
import { io } from "../../utilities/io";
import config from "../../globals";
import _ from "lodash";
import { DownloadImage } from "../../components/chatClient/DownloadImage";
import { CameraComponent } from "../../components/chatClient/CameraComponent";
import { ChatProviderContext } from "../../components/chatClient/chatProvider/main";
import AudioRecorder from "../../components/formfields/custom/chatUsers/components/ChatAudioRecorder";
import ChatFileUploader from "../../components/formfields/custom/chatUsers/components/ChatFileUploader";


interface MessageType {
    forward?: boolean;
    text: string;
    createdAt: string;
    user: {
        name: string;
        _id: number;
    }
}

interface ChatState {
    messages: MessageType[];
    newMessage: string;
    lastMessageRef: RefObject<HTMLDivElement> | null;
    data: any;
    title: any;
    isOpenChat: boolean;
    history: any;
    match: any;
    voiceRecording: boolean,
    audioUrl?: String | null,
    senderAudio: any,
    uploadFileOn?: any
}

export class ChatPage extends React.Component<ChatState> {
    static contextType = ChatProviderContext;

    state = {
        messages: [],
        newMessage: "",
        loading: true,
        lastMessageRef: createRef<HTMLDivElement>(),
        isOpenChat: false,
        idRoom: null,
        onStarted: false,
        userData: {
            person_name: '',
            person_id: null
        },
        modalImage: false,
        modalUrlImage: '',
        offSet: 1,
        cameraIsOn: false,
        voiceRecording: false,
        audioUrl: null,
        senderAudio: 1,
        uploadFileOn: false
    }


    componentDidMount(): void {
        axiosClientChat.get('/chat-client/me')
            .then((response): any => {
                this.setState({ userData: response.data.user })
            })
        this.startedSockets({ userToken: localStorage.getItem('chatToken') })
    }

    formatMessages = ({ messagesArray }: { messagesArray: Array<Record<string, any>> }) => {
        const newArray: any = [];
        for (const message of messagesArray) {
            let messageFormat: any = {
                _id: message.chat_message_content_id,
                text: message.chat_message_content_text,
                createdAt: message.chat_message_content_created_at,
                user: {
                    _id: message.chat_message_content_fk_person_id ? message.chat_message_content_fk_person_id : message.chat_message_content_fk_user_id,
                    name: message.person_name ? message.person_name : message.user_name,
                    type: message.person_name ? "person" : "user"
                }
            }

            // if (message.chat_message_files) {
            //     messageFormat.image = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
            // }

            if (message.chat_message_content_text && message.chat_message_files) {
                if (message.chat_message_content_text.includes("image")) {
                    message.chat_message_content_text = "image"
                }
                messageFormat[message.chat_message_content_text] = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
            }
            if (message.chat_message_content_forward) {
                messageFormat.forward = true;
            }
            newArray.push(messageFormat)
        }
        return newArray
    }

    startedSockets = async ({ ...props }: { userToken: any }) => {
        const paramSession: any = localStorage.getItem('step_item');
        const paramsRoute = JSON.parse(paramSession)
        io.emit("ChoseRoomChat", {
            params: {
                product_id: this.props.match.params.projectProductFkProductId ? this.props.match.params.projectProductFkProductId : null,
                project_id: this.props.match.params.projectId,
                project_product_id: this.props.match.params.projectProductId ? this.props.match.params.projectProductId : null,
                step_item: {
                    ...paramsRoute
                }
            },
            token: localStorage.getItem('chatToken'),
            isWeb: true
        }, (response: any) => {
            const messagesFormats = this.formatMessages({ messagesArray: response.messages })
            this.setState(
                {
                    messages: _.orderBy(messagesFormats, ['_id', 'asc']),
                    idRoom: response.idRoom,
                    loading: false
                }
            )

        })

        io.on('receiveMessagesChat', (values: any) => {
            const messagesFormats = this.formatMessages({ messagesArray: values })
            this.setState(
                {
                    messages: [...this.state.messages, ...messagesFormats]
                }
            )
            this.scrollToBottom()
        })


        this.setState({ onStarted: true })
    }

    scrollToBottom() {
        if (this.state.lastMessageRef && this.state.lastMessageRef.current) {
            this.state.lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    socketSend = ({ messageFormat }: { messageFormat: any }) => {
        const paramSession: any = localStorage.getItem('step_item');
        const paramsRoute = JSON.parse(paramSession);
        const params = {
            data: {
                message_body: [messageFormat],
                params: {
                    product_id: this.props.match.params.projectProductFkProductId ? this.props.match.params.projectProductFkProductId : null,
                    project_id: this.props.match.params.projectId,
                    project_product_id: this.props.match.params.projectProductId ? this.props.match.params.projectProductId : null,
                    step_item: {
                        ...paramsRoute
                    }
                },
            },
            token: localStorage.getItem('chatToken')
        }
        io.emit('receiveMessagesChat', params);

        this.handleSendMessage({ message: messageFormat })
        this.scrollToBottom()
    }

    onSendMessage = () => {
        const { newMessage }: any = this.state;
        if ((newMessage.replace(/[^a-zA-Z0-9 ]/g, ""))?.length <= 0) {
            return
        }
        const messageFormat = {
            text: newMessage,
            createdAt: dayjs(),
            user: {
                _id: this.state.userData.person_id,
                name: this.state.userData.person_name,
                type:"person"
            },
            customProps: {
                person: true
            }
        }
        this.socketSend({ messageFormat: messageFormat })
    }

    handleSendMessage = ({ message }: { message: Record<string, any> }) => {
        const { newMessage }: any = this.state;
        const messageUpdate = message ? message : newMessage.trim();
        if (messageUpdate) {
            this.setState((prevState: any) => ({
                messages: [
                    ...prevState.messages,
                    {
                        ...message
                    }
                ],
            }));

            this.setState({ newMessage: "" });
        }
    };

    handleUploadFiles = (responseUpload: any) => {
        const newMessage =
        {
            _id: Math.round(Math.random() * 10000000).toString(),
            image: `${config.api_url}${responseUpload.path}`,
            text: 'files',
            createdAt: new Date(),
            user: {
                _id: this.state.userData.person_id,
                name: this.state.userData.person_name,
                type:"person"
            },
            customProps: {
                image_unique_id: responseUpload.meta.chat_message_file_unique_key,
                person: true
            }
        }

        this.socketSend({ messageFormat: newMessage })

    };

    handleUploadImage = ({ image }: { image: any }) => {
        const imageSplit = image.split("base64,")
        const imageFile = {
            exif: {

            },
            uri: imageSplit.shift(),
            base64: imageSplit.pop().trim()
        }
        axiosClientChat
            .post('/chat-client/file/upload', {
                file: imageFile
            }).then((response: any) => {
                const newMessage =
                {
                    _id: Math.round(Math.random() * 10000000).toString(),
                    image: `${config.api_url}${response.data.path}`,
                    text: 'imagem',
                    createdAt: new Date(),
                    user: {
                        _id: this.state.userData.person_id,
                        name: this.state.userData.person_name,
                        type:"person"
                    },
                    customProps: {
                        image_unique_id: response.data.meta.chat_message_file_unique_key,
                        person: true
                    }
                }

                this.socketSend({ messageFormat: newMessage })
            }).catch((e: any) => {
                Notification.warning(
                    {
                        title: 'Se você esta vendo esse erro, envie para o desenvolvedor',
                        description: `Link do erro: ${e.response.data.file.split('build').pop()}`
                    }
                )
            })
    };

    handleUploadAudio = async (audioBlob: any) => {
        try {
            if (typeof this.state.audioUrl === 'string') {
                let responseBlob = await fetch(this.state.audioUrl)
                audioBlob = await responseBlob.blob();
            } else {
                audioBlob = this.state.audioUrl
            }
            let audioFile = new FormData();
            audioFile.append('file', audioBlob, 'audio.mp3');

            const response = await axiosClientChat.post('/chat-client/file/upload', audioFile, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const newMessage = {
                _id: Math.round(Math.random() * 10000000).toString(),
                audio: `${config.api_url}${response.data.path}`,
                text: 'audio',
                createdAt: new Date(),
                user: {
                    _id: this.state.userData.person_id,
                    name: this.state.userData.person_name,
                    type:"person"
                },
                customProps: {
                    image_unique_id: response.data.meta.chat_message_file_unique_key,
                    person: true
                }
            };

            this.socketSend({ messageFormat: newMessage });

            this.setState({
                audioUrl: null,
                voiceRecording: false,
                senderAudio: this.state.senderAudio + 1
            })
        } catch (e: any) {
            console.log("erro aqui", { e })
            Notification.warning({
                title: 'Se você está vendo esse erro, envie para o desenvolvedor',
                description: `Link do erro: ${e?.response?.data?.file?.split('build')?.pop()}`
            });
        }
        return
    };

    handleKeyPress = (e: any) => {
        if (e.key === "Enter") {
            this.onSendMessage()
        }
    };

    handleScroll = (e: any) => {
        const { target } = e;
        const scrolledToTop = target.scrollTop === 0;
        if (scrolledToTop) {
            axiosClientChat.get(`/chat-client/loader/message/${this.state.idRoom}/${this.state.messages[this.state.messages.length - 1]['_id']}/${this.state.offSet}`)
                .then((response: any) => {
                    const formatMessages = this.formatMessages({ messagesArray: response.data.items });
                    this.setState({ offSet: response.data.offset })
                    this.setState((prevState: any) => ({
                        messages: [
                            ...prevState.messages,
                            ...formatMessages

                        ],
                    }));
                }).catch((err: any) => {
                    Notification.warning({
                        title: 'Aviso',
                        description: err.response.data.message
                    })
                })
        }
    };

    onHideCam = () => {
        this.setState({ cameraIsOn: false })
    }

    typeRender = ({ message }: { message: Record<string, any> }) => {
        const output: any = []

        switch (message.text) {
            case "imagem":
                output.push(
                    <div>
                        <div
                            style={
                                {
                                    position: 'relative'
                                }
                            }
                            onClick={() => {
                                this.setState({ modalImage: true, modalUrlImage: `https://${window.location.host.split('25565').join('25567')}${message?.image?.split('undefined').pop()}` })
                            }}
                        >
                            <img
                                alt={message.text}
                                src={`https://${window.location.host.split('25565').join('25567')}${message?.image?.split('undefined').pop()}`}
                                style={{ width: 250, height: 300, objectFit: 'cover' }}
                            />
                            <div
                                style={
                                    {
                                        position: 'absolute',
                                        bottom: "12px",
                                        right: "5px",
                                    }
                                }
                            >
                                <DownloadImage
                                    urlImage={`https://${window.location.host.split('25565').join('25567')}${message?.image?.split('undefined').pop()}`}
                                />
                            </div>
                        </div>
                    </div>
                )
                break;

            case "audio":
                output.push(
                    <div>
                        <div
                            style={
                                {
                                    position: 'relative'
                                }
                            }
                        >
                            <audio style={{ width: "100%", minWidth: "230px" }} controls preload="auto">
                                <source src={`https://${window.location.host.split('25565').join('25567')}${message?.audio?.split('undefined')?.pop()}`} type="audio/wav" />
                                Seu navegador não suporta o elemento de áudio.
                            </audio>
                            <div
                                style={
                                    {
                                        position: 'absolute',
                                        bottom: "12px",
                                        right: "5px",
                                    }
                                }
                            >

                            </div>
                        </div>
                    </div>
                )
                break;

            case "files":
                output.push(
                    <div>
                        <div
                            style={
                                {
                                    position: 'relative'
                                }
                            }
                        >
                            <ChatFileUploader showOnly={true} message={message} />
                            <div
                                style={
                                    {
                                        position: 'absolute',
                                        bottom: "12px",
                                        right: "5px",
                                    }
                                }
                            >

                            </div>
                        </div>
                    </div>
                )
                break;
            default:
                output.push(
                    <p style={{ margin: "0", color: "#075e54" }}>
                        {message.text}
                    </p>
                )
        }
        return output
    }


    onRecording = async () => {
        this.setState({
            voiceRecording: !this.state.voiceRecording
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.state.modalImage} onHide={() => {
                    this.setState({ modalImage: false })
                }}
                    style={{

                    }}
                >
                    <Modal.Body>
                        <img
                            alt="Foto do arquivo"
                            src={this.state.modalUrlImage}
                            style={
                                {
                                    width: 'clamp(12rem, 100%, 100%)',
                                    height: "600px",
                                    objectFit: 'cover'
                                }
                            }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="red"
                            onClick={() => {
                                this.setState({ modalImage: false })
                            }}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container
                    style={
                        {
                            maxHeight: '100vh',
                            minHeight: '100vh'
                        }
                    }
                >
                    {this.state.cameraIsOn ? (
                        <CameraComponent
                            onHideCam={this.onHideCam}
                            onSendImage={({ image }) => this.handleUploadImage({ image: image })}
                        />
                    ) : (
                        <>
                            <HeaderComponente
                                title="Chats"
                                useBack={true}
                                useInfo={false}
                                onBack={() => {
                                    const { setPathUrl } = this.context;
                                    setPathUrl(
                                        {
                                            path: '',
                                            labelValue: '',
                                            direction: 'back'
                                        }
                                    )
                                    this.props.history.go(-1)
                                }}
                            />
                            <Content
                                style={
                                    {
                                        display: 'flex',
                                        // height: '30%',
                                    }
                                }
                            >
                                {this.state.onStarted && this.state.loading && (<>
                                    <Placeholder.Graph active rows={15} height={350} />
                                </>)}
                                {this.state.onStarted && !this.state.loading && (
                                    <div
                                        style={{ display: "flex", flexDirection: "column", marginTop: 5, width: '100%' }}
                                    >
                                        <>
                                            <div
                                                style={{
                                                    // flex: "1",
                                                    overflowY: "auto",
                                                    height: '79vh',
                                                    padding: "20px",
                                                    // background: "#f2f2f2",
                                                    // boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                    scrollbarWidth: "thin", // Para navegadores Firefox
                                                    scrollbarColor: "transparent transparent", // Para navegadores Chrome

                                                }}
                                                onScroll={this.handleScroll}
                                            >
                                                <>
                                                    {this.state.messages.map((message: MessageType, index: number) => {
                                                        console.log({ message })
                                                        return (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        flexWrap: "wrap",
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    <div
                                                                        key={index}
                                                                        ref={index === this.state.messages.length - 1 ? this.state.lastMessageRef : null}
                                                                        style={{
                                                                            backgroundColor:
                                                                                message.user.name === this.state.userData.person_name
                                                                                    ? "#dcf8c6"
                                                                                    : "#fff",
                                                                            borderRadius: "6px",
                                                                            padding: "10px",
                                                                            marginBottom: "10px",
                                                                            maxWidth: "90%",
                                                                            // minWidth: '30%',
                                                                            alignSelf:
                                                                                message.user.name === this.state.userData.person_name
                                                                                    ? "flex-end"
                                                                                    : "flex-start",
                                                                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                                                                            gap: 5,

                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: 'column',
                                                                                // justifyContent: "space-between",
                                                                                // alignItems: "center",
                                                                                justifyContent: 'start',
                                                                                marginBottom: "5px",
                                                                            }}
                                                                        >
                                                                            {message?.forward && (
                                                                                <span style={{ fontWeight: "100", fontStyle: 'italic', color: "rgba(7, 94, 84, 0.5)", fontSize: 11 }}>
                                                                                    <i className="fas fa-share fa-xs"></i> encaminhada....
                                                                                </span>
                                                                            )}
                                                                            <span style={{ fontWeight: "bold", color: "#075e54" }}>
                                                                                {message.user.name}
                                                                            </span>
                                                                        </div>
                                                                        {this.typeRender(
                                                                            {
                                                                                message: message
                                                                            }
                                                                        )}

                                                                        <div
                                                                            style={
                                                                                {
                                                                                    width: '100%',
                                                                                    display: 'flex',
                                                                                    justifyContent: 'end'
                                                                                }
                                                                            }
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: "10px",
                                                                                    color: "#075e54",
                                                                                }}
                                                                            >
                                                                                {dayjs(message.createdAt).format("HH:mm")}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    )}
                                                </>

                                            </div>
                                            <div
                                                style={{
                                                    height: '10vh',
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    // background: "transparent",
                                                    backgroundColor: "#fff",
                                                    gap: 10,
                                                    padding: "10px",
                                                    borderTopLeftRadius: "15px",
                                                    borderTopRightRadius: "15px",
                                                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
                                                    position: 'fixed',
                                                    bottom: 0,
                                                    width: '100%'
                                                }}
                                            >
                                                {(!this.state.voiceRecording && !this.state.audioUrl) ? (
                                                    <>
                                                        <Dropdown
                                                            placement="topStart"
                                                            renderTitle={() => {
                                                                return <IconButton
                                                                    appearance="primary"
                                                                    style={
                                                                        {
                                                                            backgroundColor: 'rgb(0, 179, 171)'
                                                                        }
                                                                    }
                                                                    icon={<i className="fas fa-paperclip fa-lg"></i>} circle />;
                                                            }}
                                                        >
                                                            <Dropdown.Item
                                                                onSelect={() => {
                                                                    this.setState({ cameraIsOn: true })
                                                                }}
                                                            >
                                                                <i className="fas fa-camera"></i> Camera
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onSelect={() => {
                                                                    this.setState({ uploadFileOn: true })
                                                                }}
                                                            >
                                                                <i className="fas fa-folder-open"></i> Arquivos

                                                            </Dropdown.Item>

                                                            {/* <Dropdown.Item>
                                                        <i className="fas fa-folder-open"></i> Arquivos
                                                    </Dropdown.Item> */}
                                                        </Dropdown>

                                                        {this.state.uploadFileOn && (
                                                            <ChatFileUploader closeStateModal={(modalState: any) => this.setState({ uploadFileOn: modalState })} sendUploadFile={(value: any) => this.handleUploadFiles(value)} oldState={this.state} params={this.props} />
                                                        )}
                                                        <Input
                                                            type="text"
                                                            placeholder="Digite sua mensagem..."
                                                            value={this.state.newMessage}
                                                            onChange={(e) => this.setState({ newMessage: e })}
                                                            onKeyPress={this.handleKeyPress}
                                                            style={{
                                                                flex: "1",
                                                                padding: "10px",
                                                                border: "none",
                                                                borderRadius: "10px",
                                                                marginRight: "10px",
                                                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                                fontSize: "16px"
                                                            }}
                                                        />
                                                    </>) : ""}


                                                <AudioRecorder reset={this.state.senderAudio} setAudio={(url: any) => this.setState({ audioUrl: url })} action={this.state.voiceRecording ? "start" : "stop"} />


                                                {!this.state.audioUrl && (<Button
                                                    onClick={this.onRecording}
                                                    appearance="primary"
                                                    // loading={this.state.voiceRecording}
                                                    style={{
                                                        width: 80,
                                                        background: this.state.voiceRecording ? "rgb(124, 113, 255)" : "rgb(0, 179, 171)",
                                                        color: "#fff",
                                                        padding: "10px",
                                                        border: "none",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                >
                                                    <i className="fa-solid fas fa-microphone"></i>
                                                </Button>)}

                                                {!this.state.voiceRecording && (<Button
                                                    onClick={this.state.audioUrl ? this.handleUploadAudio : this.onSendMessage}
                                                    appearance="primary"
                                                    style={{
                                                        background: "rgb(0, 179, 171)",
                                                        color: "#fff",
                                                        padding: "10px",
                                                        border: "none",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                        minWidth: "50px"
                                                    }}
                                                >
                                                    <i className="far fa-paper-plane"></i>
                                                </Button>)}
                                            </div>
                                        </>
                                    </div>

                                )}
                                {/* <ChatNecComponent 
                            messages={this.state.messages}
                            newMessage={this.state.newMessage}
                            sendNewMessage={this.handleSendMessage}
                        /> */}
                            </Content>
                        </>
                    )}


                </Container>
            </>
        )
    }
}