import React from "react";
import { Button, CheckPicker, DatePicker, DateRangePicker, Input, Panel, SelectPicker } from "rsuite";
import ApexCharts from 'apexcharts'
import ReactApexChart from "react-apexcharts";
import axios from "../../../utilities/axios";
import dayjs from "dayjs";
import { locale } from "../../../utilities/locale/DateRangePicker";

export class StateReportTopUrgent extends React.Component<any, {}> {
    public state: any = {
        total: 0,
        series: [
            {
                name: "MARCADO COMO URGENTE",
                data: [],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    horizontal: true,
                    barHeight: '80%',
                    isFunnel: true,
                    // distributed:true
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val: any, opt: any) {
                    return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
                },
                dropShadow: {
                    enabled: true,
                },
            },
            // title: {
            //   text: 'Recruitment Funnel',
            //   align: 'middle',
            // },
            xaxis: {
                categories: [
                    'Carregando...'
                ],
            },
            legend: {
                show: false,
            },
        },
        filter: {
            status: [],
            date: [dayjs().startOf('month'), dayjs().endOf('month')]
        },
        isLoading: false
    }

    componentDidMount(): void {
        this.onSubmitButton()
    }

    onSubmitButton = () => {
        this.setState({ isLoading: true })
        axios.get("/api/v1/projects/reports/topurgentprojects", {
            params: {
                filter: this.state.filter
            }
        }).then((res) => {
            if (res?.status == 200) {
                this.setState({
                    series: res.data?.series,
                    options: {
                        ...this.state.options,
                        xaxis: res.data?.xaxis
                    }
                })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }
    render() {
        return (
            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex", margin: "auto", gap: 5, marginBottom: 32 }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>De/Até</label>
                        <DateRangePicker
                            preventOverflow
                            format="DD/MM/YYYY"
                            locale={locale}
                            defaultValue={this.state.filter?.date}
                            disabled={this.state.isLoading}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        date: value
                                    }
                                })
                            }} />
                    </div>



                    <div style={{ display: "flex", flexDirection: "column", width: "50%", marginTop: 20 }}>
                        <Button loading={this.state.isLoading} onClick={() => this.onSubmitButton()} appearance="primary" color="blue"> <i className="fas fa-fw fa-search"></i> Buscar</Button>
                    </div>


                </div>
                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type={this.state.options.chart.type} width={380} />
                </div>
                <Button appearance="primary" color="green" className="mr-2"> <i className="fas fa-fw fa-file-excel"></i> Relatório Excel</Button>
                <Button appearance="primary" color="blue"> <i className="fas fa-fw fa-file-pdf"></i> Relatório PDF</Button>
            </div>
        )
    }
}
