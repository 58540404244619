import React from "react";
import { Route, Switch, withRouter } from "react-router";
import { Helmet } from "react-helmet";
import BrowsePageChat from "./BrowsePageChat";
import { ReadListItems } from "./ReadListItems";
import { ChatList } from "./ChatLIst";

class DefaultDashboardRouteChat extends React.Component<any, {}> {
    public state = {
        isLoading: false,
        usuario: null,
    };

    componentDidUpdate(props: any) {
       
    }

    componentDidMount = () => {
        
    };

    render() {
        const { path } = this.props.match;
        const { module } = this.props.match.params;
        if (this.state.isLoading === true) {
            return null;
        }
        return (
            <div>
                <Helmet>
                    <title>FastFlow Chat</title>
                </Helmet>
                <div>
                    <Switch>
                        <Route exact path={path} component={BrowsePageChat} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(DefaultDashboardRouteChat);
