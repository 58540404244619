import { Event } from "../Event";

export class ProjectEvents extends Event {
    public async onInit (props: any) {
        await this.setFieldValue('project_users', [this.userContextProps.user_id])
        this.setState('loadings',[
            'project_users'
        ])
        this.setState('loadings',[])
    }
}