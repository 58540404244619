import React, { RefObject, createRef } from "react";

import { Container, Content, Dropdown, IconButton, Modal, Notification, Placeholder, Popover, Whisper } from "rsuite";

import { Button, Input } from "rsuite";
import dayjs from "dayjs";
import { io } from "../../../../../utilities/io";
import config from "../../../../../globals";
import _ from "lodash";
import { DownloadImage } from "../../../../chatClient/DownloadImage";
import { CameraComponent } from "../../../../chatClient/CameraComponent";
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import { ChatUpdateStatus, typeSendDate } from "../components/ChatUpdateStatus";
import { ChatForward } from "../components/ChatForward";
import AudioRecorder from "../components/ChatAudioRecorder";
import { ChatForwardHolder } from "../components/forward/ChatForwardHolder";
import ChatFileUploader from "../components/ChatFileUploader";



interface ChatPageProps {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    previousRoute: ({ route, dataParams }: { route: string, dataParams: Record<string, any> }) => void;
    paramsRoute: Record<string, any>;
}


interface MessageType {
    text: string;
    createdAt: string;
    user: {
        name: string;
        _id: number;
    };
    forward?: boolean;
}

interface ChatState {
    messages: MessageType[];
    newMessage: string;
    lastMessageRef: RefObject<HTMLDivElement> | null;
    data: any;
    title: any;
    isOpenChat: boolean;
    history: any;
    match: any;
    idRoom: any;
    oldState?: any;
    onStarted: boolean;
    userData: {
        user_name: string,
        user_id: number | null
    };
    modalImage: boolean;
    modalUrlImage: string;
    offSet: number;
    cameraIsOn: boolean;
    loading: boolean;
    chatsForward: any[],
    voiceRecording: boolean,
    audioUrl?: String | null,
    senderAudio: any,
    uploadFileOn: boolean

}

export class ChatPage extends React.Component<ChatPageProps, ChatState> {
    constructor(props: ChatPageProps) {
        super(props);
        this.state = {
            messages: [],
            newMessage: "",
            lastMessageRef: createRef<HTMLDivElement>(),
            idRoom: null,
            oldState: this.props?.paramsRoute || {},
            onStarted: false,
            userData: {
                user_name: '',
                user_id: null
            },
            modalImage: false,
            modalUrlImage: '',
            offSet: 1,
            cameraIsOn: false,
            data: null,
            title: null,
            isOpenChat: false,
            history: null,
            match: null,
            loading: true,
            chatsForward: [],
            voiceRecording: false,
            audioUrl: null,
            senderAudio: 1,
            uploadFileOn: false
        };
        this.sendDateMessage = this.sendDateMessage.bind(this);
    }


    componentDidMount(): void {
        axios
            .get('/api/v1/me')
            .then((response): any => {
                this.setState({ userData: response.data.user })
            })
        if (this.props.paramsRoute.project_product_id) {
            axios
                .get(`/api/mobile/steps/${this.props.paramsRoute.project_product_id}/${this.props.paramsRoute.product_id}/get`)
                .then((res: any) => {

                    let newData = res.data.item
                    if (res.data.item) {
                        newData.push({
                            ...newData?.[0],
                            project_product_group_step_table_flow_fk_step_id: 9,
                            toChatClient: true,
                            steps: [
                                {
                                    step_id: 9,
                                    step_name: "CHAT DO CLIENTE",

                                }
                            ]
                        })
                        this.setState({
                            chatsForward: newData
                        })
                    }
                })
        } else {
            axios
                .get(`/api/v1/forward-chat/client/get`, {
                    params: {
                        project_id: this.props.paramsRoute.project_id
                    }
                })
                .then((res: any) => {
                    this.setState(
                        {
                            chatsForward: res.data.items
                        }
                    )
                })
        }
        this.startedSockets({ userToken: localStorage.getItem('chatToken') })
    }

    formatMessages = ({ messagesArray }: { messagesArray: Array<Record<string, any>> }) => {
        const newArray: any = [];
        for (const message of messagesArray) {
            let messageFormat: any = {
                _id: message.chat_message_content_id,
                text: message.chat_message_content_text,
                createdAt: message.chat_message_content_created_at,
                user: {
                    _id: message.chat_message_content_fk_person_id ? message.chat_message_content_fk_person_id : message.chat_message_content_fk_user_id,
                    name: message.person_name ? message.person_name : message.user_name,
                    type: message.person_name ? "person" : "user"
                }
            }

            // if (message.chat_message_files) {
            //     messageFormat.image = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
            // }

            if (message.chat_message_content_text && message.chat_message_files) {
                if (message.chat_message_content_text.includes("image")) {
                    message.chat_message_content_text = "image"
                }
                messageFormat[message.chat_message_content_text] = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
            }
            if (message.chat_message_content_forward) {
                messageFormat.forward = true;
            }
            newArray.push(messageFormat)
        }
        return newArray
    }

    startedSockets = async ({ ...props }: { userToken: any }) => {
        const paramsRoute: any = this.props.paramsRoute.step_item
        io.emit("ChoseRoomChat", {
            params: {
                product_id: this.props.paramsRoute.project_product_fk_product_id ? this.props.paramsRoute.project_product_fk_product_id : null,
                project_id: this.props.paramsRoute.project_id,
                project_product_id: this.props.paramsRoute.project_product_id ? this.props.paramsRoute.project_product_id : null,
                step_item: {
                    ...paramsRoute
                }
            },
            token: localStorage.getItem('token'),
            isWebUser: true
        }, (response: any) => {
            const messagesFormats = this.formatMessages({ messagesArray: response.messages })
            this.setState(
                {
                    messages: _.orderBy(messagesFormats, ['_id', 'asc']),
                    idRoom: response.idRoom,
                    loading: false
                }
            )

        })

        io.on('receiveMessagesChat', (values: any) => {
            const messagesFormats = this.formatMessages({ messagesArray: values })
            this.setState(
                {
                    messages: [...this.state.messages, ...messagesFormats]
                }
            )
            this.scrollToBottom()
        })


        this.setState({ onStarted: true })
    }

    scrollToBottom() {
        if (this.state.lastMessageRef && this.state.lastMessageRef.current) {
            this.state.lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    socketSend = ({ messageFormat }: { messageFormat: any }) => {
        const paramsRoute: any = this.props.paramsRoute.step_item;
        const params = {
            data: {
                message_body: [messageFormat],
                params: {
                    product_id: this.props.paramsRoute.project_product_fk_product_id ? this.props.paramsRoute.project_product_fk_product_id : null,
                    project_id: this.props.paramsRoute.project_id,
                    project_product_id: this.props.paramsRoute.project_product_id ? this.props.paramsRoute.project_product_id : null,
                    step_item: {
                        ...paramsRoute
                    }
                },
            },
            token: localStorage.getItem('chatToken')
        }
        io.emit('receiveMessagesChat', params);

        this.handleSendMessage({ message: messageFormat })
        this.scrollToBottom()
    }

    onSendMessage = () => {
        const { newMessage }: any = this.state;
        if ((newMessage.replace(/[^a-zA-Z0-9 ]/g, ""))?.length <= 0) {
            return
        }
        const messageFormat = {
            text: newMessage,
            createdAt: dayjs(),
            user: {
                _id: this.state.userData.user_id,
                name: this.state.userData.user_name,
                type: "user"
            }
        }
        this.socketSend({ messageFormat: messageFormat })
    }

    handleSendMessage = ({ message }: { message: Record<string, any> }) => {
        const { newMessage }: any = this.state;
        const messageUpdate = message ? message : newMessage.trim();
        if (messageUpdate) {
            this.setState((prevState: any) => ({
                messages: [
                    ...prevState.messages,
                    {
                        ...message
                    }
                ],
            }));

            this.setState({ newMessage: "" });
        }
    };


    handleUploadFiles = (responseUpload: any) => {
        const newMessage =
        {
            _id: Math.round(Math.random() * 10000000).toString(),
            image: `${config.api_url}${responseUpload.path}`,
            text: 'files',
            createdAt: new Date(),
            user: {
                _id: this.state.userData.user_id,
                name: this.state.userData.user_name,
                type: "user"
            },
            customProps: {
                image_unique_id: responseUpload.meta.chat_message_file_unique_key
            }
        }

        this.socketSend({ messageFormat: newMessage })

    };

    handleUploadImage = ({ image }: { image: any }) => {
        const imageSplit = image.split("base64,")
        const imageFile = {
            exif: {

            },
            uri: imageSplit.shift(),
            base64: imageSplit.pop().trim()
        }
        axios
            .post('/chat-client/file/upload', {
                file: imageFile
            }).then((response: any) => {
                const newMessage =
                {
                    _id: Math.round(Math.random() * 10000000).toString(),
                    image: `${config.api_url}${response.data.path}`,
                    text: 'imagem',
                    createdAt: new Date(),
                    user: {
                        _id: this.state.userData.user_id,
                        name: this.state.userData.user_name,
                        type: "user"
                    },
                    customProps: {
                        image_unique_id: response.data.meta.chat_message_file_unique_key
                    }
                }

                this.socketSend({ messageFormat: newMessage })
            }).catch((e: any) => {
                Notification.warning(
                    {
                        title: 'Se você esta vendo esse erro, envie para o desenvolvedor',
                        description: `Link do erro: ${e.response.data.file.split('build').pop()}`
                    }
                )
            })
    };

    handleUploadAudio = async (audioBlob: any) => {
        try {
            if (typeof this.state.audioUrl === 'string') {
                let responseBlob = await fetch(this.state.audioUrl)
                audioBlob = await responseBlob.blob();
            } else {
                audioBlob = this.state.audioUrl
            }
            let audioFile = new FormData();
            audioFile.append('file', audioBlob, 'audio.wav');

            const response = await axios.post('/chat-client/file/upload', audioFile, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const newMessage = {
                _id: Math.round(Math.random() * 10000000).toString(),
                audio: `${config.api_url}${response.data.path}`,
                text: 'audio',
                createdAt: new Date(),
                user: {
                    _id: this.state.userData.user_id,
                    name: this.state.userData.user_name,
                    type: "user"
                },
                customProps: {
                    image_unique_id: response.data.meta.chat_message_file_unique_key
                }
            };

            this.socketSend({ messageFormat: newMessage });

            this.setState({
                audioUrl: null,
                voiceRecording: false,
                senderAudio: parseInt(this.state.senderAudio) + 1
            })
        } catch (e: any) {
            console.log("erro aqui", { e })
            Notification.warning({
                title: 'Se você está vendo esse erro, envie para o desenvolvedor',
                description: `Link do erro: ${e?.response?.data?.file?.split('build')?.pop()}`
            });
        }
        return
    };


    sendDateMessage({ ...propsSendDate }: typeSendDate) {
        const newMessage: any =
        {
            _id: Math.round(Math.random() * 10000000).toString(),
            text: `${propsSendDate.date_message}\n ${propsSendDate?.obs_message ? `Observação: ${propsSendDate.obs_message}` : ""}`,
            createdAt: new Date(),
            user: {
                _id: this.state.userData.user_id,
                name: this.state.userData.user_name,
                type: "user"
            }
        }
        this.socketSend({ messageFormat: newMessage })
    }

    handleKeyPress = (e: any) => {
        if (e.key === "Enter") {
            this.onSendMessage()
        }
    };

    handleScroll = (e: any) => {
        const { target } = e;
        const scrolledToTop = target.scrollTop === 0;
        if (scrolledToTop) {
            axios.get(`/api/v1/loader/message/${this.state.idRoom}/${this.state.messages[this.state.messages.length - 1].user['_id']}/${this.state.offSet}`)
                .then((response: any) => {
                    const formatMessages = this.formatMessages({ messagesArray: response.data.items });
                    this.setState({ offSet: response.data.offset })
                    this.setState((prevState: any) => ({
                        messages: [
                            ...prevState.messages,
                            ...formatMessages

                        ],
                    }));
                }).catch((err: any) => {
                    Notification.warning({
                        title: 'Aviso',
                        description: err.response.data.message
                    })
                })
        }
    };

    onHideCam = () => {
        this.setState({ cameraIsOn: false })
    }

    typeRender = ({ message }: { message: Record<string, any> }) => {
        const output: any = []

        switch (message.text) {
            case "imagem":
                output.push(
                    <div>
                        <div
                            style={
                                {
                                    position: 'relative'
                                }
                            }
                            onClick={() => {
                                this.setState({ modalImage: true, modalUrlImage: `https://${window.location.host.split('25565').join('25567')}${message?.image?.split('undefined').pop()}` })
                            }}
                        >
                            <img
                                alt={message.text}
                                src={`https://${window.location.host.split('25565').join('25567')}${message?.image?.split('undefined').pop()}`}
                                style={{ width: 250, height: 300, objectFit: 'cover' }}
                            />
                            <div
                                style={
                                    {
                                        position: 'absolute',
                                        bottom: "12px",
                                        right: "5px",
                                    }
                                }
                            >
                                <DownloadImage
                                    urlImage={`https://${window.location.host.split('25565').join('25567')}${message?.image?.split('undefined').pop()}`}
                                />
                            </div>
                        </div>
                    </div>
                )
                break;

            case "audio":
                output.push(
                    <div>
                        <div
                            style={
                                {
                                    position: 'relative'
                                }
                            }
                        >
                            <audio style={{ width: "100%", minWidth: "230px" }} controls preload="metadata">
                                <source src={`https://${window.location.host.split('25565').join('25567')}${message?.audio?.split('undefined')?.pop()}`} type="audio/wav" />
                                Seu navegador não suporta o elemento de áudio.
                            </audio>
                            <div
                                style={
                                    {
                                        position: 'absolute',
                                        bottom: "12px",
                                        right: "5px",
                                    }
                                }
                            >

                            </div>
                        </div>
                    </div>
                )
                break;
            case "video":
                output.push(
                    <div>
                        <div
                            style={
                                {
                                    position: 'relative'
                                }
                            }
                        >
                            {/* <iframe allowFullScreen frameBorder={0} src={`https://${window.location.host.split('25565').join('25567')}${message?.video?.split('undefined')?.pop()}`} /> */}

                            <video width="320" height="240" controls autoPlay={false} >
                                <source src={`https://${window.location.host.split('25565').join('25567')}${message?.video?.split('undefined')?.pop()}`} type="video/mp4" />
                            </video>

                            <div
                                style={
                                    {
                                        position: 'absolute',
                                        bottom: "12px",
                                        right: "5px",
                                    }
                                }
                            >

                            </div>
                        </div>
                    </div>
                )
                break;

            case "files":
                output.push(
                    <div>
                        <div
                            style={
                                {
                                    position: 'relative'
                                }
                            }
                        >
                            <ChatFileUploader showOnly={true} message={message} />
                            <div
                                style={
                                    {
                                        position: 'absolute',
                                        bottom: "12px",
                                        right: "5px",
                                    }
                                }
                            >

                            </div>
                        </div>
                    </div>
                )
                break;
            default:
                output.push(
                    <p style={{ margin: "0", color: "#075e54" }}>
                        {message.text}
                    </p>
                )
        }
        return output
    }

    onRecording = async () => {
        this.setState({
            voiceRecording: !this.state.voiceRecording
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.state.modalImage} onHide={() => {
                    this.setState({ modalImage: false })
                }}
                    style={{

                    }}
                >
                    <Modal.Body>
                        <img
                            alt="Foto do arquivo"
                            src={this.state.modalUrlImage}
                            style={
                                {
                                    width: 'clamp(12rem, 100%, 100%)',
                                    height: "600px",
                                    objectFit: 'cover'
                                }
                            }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="red"
                            onClick={() => {
                                this.setState({ modalImage: false })
                            }}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {this.state.cameraIsOn ? (
                    <CameraComponent
                        onHideCam={this.onHideCam}
                        onSendImage={({ image }) => this.handleUploadImage({ image: image })}
                    />
                ) : (
                    <>

                        <HeaderComponent
                            // nextRouter={this.props.nextRouter}
                            previousRoute={this.props.previousRoute}
                            dataParams={this.props.paramsRoute}
                            routePrevious={this.props.paramsRoute?.project_product_id ? "chatList" : "products"}
                        />
                        <div
                            style={
                                {
                                    display: 'flex',
                                    height: '70vh',
                                    maxHeight: '70vh',
                                }
                            }
                        >
                            {this.state.onStarted && this.state.loading && (<>
                                <Placeholder.Graph active rows={15} height={350} />
                            </>)}
                            {this.state.onStarted && !this.state.loading && (
                                <div
                                    style={{ display: "flex", flexDirection: "column", marginTop: 5, width: '100%', maxHeight: "100%" }}
                                >
                                    <>
                                        <div
                                            style={{
                                                // flex: "1",
                                                overflowY: "auto",
                                                height: '90%',
                                                maxHeight: '90%',
                                                padding: "20px",
                                                // background: "#f2f2f2",
                                                // boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                scrollbarWidth: "thin", // Para navegadores Firefox
                                                scrollbarColor: "transparent transparent", // Para navegadores Chrome

                                            }}
                                            onScroll={this.handleScroll}

                                        >
                                            <>
                                                {this.state.messages.map((message: MessageType, index: number) => {
                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    flexWrap: "wrap",
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <div
                                                                    key={index}
                                                                    ref={index === this.state.messages.length - 1 ? this.state.lastMessageRef : null}
                                                                    style={{
                                                                        backgroundColor:
                                                                            message.user.name === this.state.userData.user_name
                                                                                ? "#dcf8c6"
                                                                                : "#fff",
                                                                        borderRadius: "6px",
                                                                        padding: "10px",
                                                                        marginBottom: "10px",
                                                                        maxWidth: "90%",
                                                                        // minWidth: '30%',
                                                                        alignSelf:
                                                                            message.user.name === this.state.userData.user_name
                                                                                ? "flex-end"
                                                                                : "flex-start",
                                                                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                                                                        gap: 5,

                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: 'column',
                                                                            // justifyContent: "space-between",
                                                                            // alignItems: "center",
                                                                            justifyContent: 'start',
                                                                            marginBottom: "5px",
                                                                        }}
                                                                    >
                                                                        {message?.forward && (
                                                                            <span style={{ fontWeight: "100", fontStyle: 'italic', color: "rgba(7, 94, 84, 0.5)", fontSize: 11 }}>
                                                                                <i className="fas fa-share fa-xs"></i> encaminhada....
                                                                            </span>
                                                                        )}
                                                                        <div
                                                                            style={
                                                                                {
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    justifyContent: 'space-between',
                                                                                    gap: 20
                                                                                }
                                                                            }
                                                                        >
                                                                            <span style={{ fontWeight: "bold", color: "#075e54" }}>
                                                                                {message.user.name}
                                                                            </span>
                                                                            {/* <div
                                                                                style={
                                                                                    {
                                                                                        cursor: "pointer",
                                                                                    }
                                                                                }
                                                                                onClick={() => this.forwardMessage()}
                                                                            >
                                                                                <i className="fas fa-share"></i>
                                                                            </div> */}
                                                                            <ChatForward
                                                                                params={this.props.paramsRoute}
                                                                                messageForward={message}
                                                                                chatsSteps={this.state.chatsForward}
                                                                            />
                                                                            {/* <ChatForwardHolder
                                                                                params={this.props.paramsRoute}
                                                                                messageForward={message}
                                                                                chatsSteps={this.state.chatsForward}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                    {this.typeRender(
                                                                        {
                                                                            message: message
                                                                        }
                                                                    )}
                                                                    <div
                                                                        style={
                                                                            {
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                justifyContent: 'end'
                                                                            }
                                                                        }
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontSize: "10px",
                                                                                color: "#075e54",
                                                                            }}
                                                                        >
                                                                            {dayjs(message.createdAt).format("HH:mm")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                )}
                                            </>

                                        </div>
                                        <div
                                            style={{
                                                height: '10vh',
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                background: "transparent",
                                                gap: 10,
                                                padding: "10px",
                                                borderTopLeftRadius: "15px",
                                                borderTopRightRadius: "15px",
                                                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
                                                position: 'relative'
                                            }}
                                        >

                                            {(!this.state.voiceRecording && !this.state.audioUrl) ? (
                                                <>
                                                    <Dropdown
                                                        placement="topStart"
                                                        renderTitle={() => {
                                                            return <IconButton
                                                                appearance="primary"
                                                                style={
                                                                    {
                                                                        backgroundColor: 'rgb(0, 179, 171)'
                                                                    }
                                                                }
                                                                icon={<i className="fas fa-paperclip fa-lg"></i>} circle />;
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            onSelect={() => {
                                                                this.setState({ cameraIsOn: true })
                                                            }}
                                                        >
                                                            <i className="fas fa-camera"></i> Camera
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            onSelect={() => {
                                                                this.setState({ uploadFileOn: true })
                                                            }}
                                                        >
                                                            <i className="fas fa-folder-open"></i> Arquivos

                                                        </Dropdown.Item>
                                                    </Dropdown>
                                                    {this.state.uploadFileOn && (
                                                        <ChatFileUploader closeStateModal={(modalState: any) => this.setState({ uploadFileOn: modalState })} sendUploadFile={(value: any) => this.handleUploadFiles(value)} oldState={this.state} params={this.props} />
                                                    )}
                                                    <ChatUpdateStatus
                                                        chatId={this.state.idRoom}
                                                        parentState={this.state}
                                                        submitValues={this.sendDateMessage}
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="Digite sua mensagem..."
                                                        value={this.state.newMessage}
                                                        onChange={(e) => this.setState({ newMessage: e })}
                                                        onKeyPress={this.handleKeyPress}
                                                        style={{
                                                            flex: "1",
                                                            padding: "10px",
                                                            border: "none",
                                                            borderRadius: "10px",
                                                            marginRight: "10px",
                                                            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                        }}
                                                    />
                                                </>) : ""}
                                            <AudioRecorder reset={this.state.senderAudio} setAudio={(url: any) => this.setState({ audioUrl: url })} action={this.state.voiceRecording ? "start" : "stop"} />
                                            {/* <Whisper
                                                trigger="click"
                                                placement={'top'}
                                                speaker={<Popover title="Gravando Audio...">
                                                    <p>aaaaaaaa</p>                                         

                                                </Popover>} >


                                                <Button
                                                    onClick={this.onRecording}
                                                    appearance="primary"
                                                    loading={this.state.voiceRecording}
                                                    style={{
                                                        width: 80,
                                                        background: "rgb(0, 179, 171)",
                                                        color: "#fff",
                                                        padding: "10px",
                                                        border: "none",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                >
                                                    <i className="fa-solid fas fa-microphone"></i>
                                                </Button>
                                            </Whisper> */}
                                            {!this.state.audioUrl && (<Button
                                                onClick={this.onRecording}
                                                appearance="primary"
                                                // loading={this.state.voiceRecording}
                                                style={{
                                                    width: 80,
                                                    background: this.state.voiceRecording ? "rgb(124, 113, 255)" : "rgb(0, 179, 171)",
                                                    color: "#fff",
                                                    padding: "10px",
                                                    border: "none",
                                                    borderRadius: "10px",
                                                    cursor: "pointer",
                                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                }}
                                            >
                                                <i className="fa-solid fas fa-microphone"></i>
                                            </Button>)}



                                            {!this.state.voiceRecording && (<Button
                                                onClick={this.state.audioUrl ? this.handleUploadAudio : this.onSendMessage}
                                                appearance="primary"
                                                style={{
                                                    background: "rgb(0, 179, 171)",
                                                    color: "#fff",
                                                    padding: "10px",
                                                    border: "none",
                                                    borderRadius: "10px",
                                                    cursor: "pointer",
                                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                    minWidth: "50px"
                                                }}
                                            >
                                                <i className="far fa-paper-plane"></i>
                                            </Button>)}
                                        </div>
                                    </>
                                </div>

                            )}
                            {/* <ChatNecComponent 
                            messages={this.state.messages}
                            newMessage={this.state.newMessage}
                            sendNewMessage={this.handleSendMessage}
                        /> */}
                        </div>
                    </>
                )}
            </>
        )
    }
}