import React from "react";
import { Header, Breadcrumb } from "rsuite";
import { ChatProvider, ChatProviderContext, useChatContext } from "./chatProvider/main";

interface HeaderComponenteProps {
    title: string;
    useBack: boolean;
    useInfo: boolean;
    onBack?: () => void;
    renderUseInfo?: React.ReactNode;
}

export class HeaderComponente extends React.Component<HeaderComponenteProps> {
    static contextType = ChatProviderContext;
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }
    renderBreadcrumb = () => {
        const { pathUrl } = this.context;
        return (
            // <Breadcrumb
            //     style={
            //         {
            //             color: '#fff',
            //         }
            //     }
            // >
            //     <Breadcrumb.Item>Home</Breadcrumb.Item>
            //     <Breadcrumb.Item>Components</Breadcrumb.Item>
            //     <Breadcrumb.Item active>Breadcrumb</Breadcrumb.Item>
            // </Breadcrumb>
            <div
                style={
                    {
                        display: 'flex',
                        // flexDirection: 'row',
                        // background: 'red',
                        width: '100%',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        minHeight: 35,
                        // padding: 5,
                        gap: 10
                    }
                }

            >
                {pathUrl && pathUrl.length > 0 && (
                    <>
                        {pathUrl.map((value: Record<string, string>, index: number) => {
                            if (value.label) {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 5
                                    }}>
                                        <div>
                                            <div style={{
                                                alignItems: 'center',

                                            }}>
                                                <span style={{
                                                    fontWeight: '600',
                                                    fontSize: 8,
                                                    justifyContent: 'center',
                                                    display: 'flex'
                                                }}>
                                                    {value.label}
                                                </span>
                                            </div>
                                            <span style={{
                                                fontSize: 10,
                                            }}>
                                                {/* {value.labelValue.length > 15 ? `${value.labelValue.substring(0, 15)}...` : value.labelValue} */}
                                                {value.labelValue}
                                            </span>
                                        </div>
                                        <div style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            alignContent: 'center'
                                        }}>
                                            {index < pathUrl.length - 1 && (
                                                <span style={{ fontSize: 20, marginLeft: 4, marginRight: 4 }}>/</span>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })}

                    </>
                )}
            </div>
        )
    }

    render(): React.ReactNode {
        return (
            <Header
                style={
                    {
                        display: 'flex',
                        backgroundColor: 'rgb(0, 179, 171)',
                        width: '100vw',
                        color: '#fff',
                        height: '10vh',
                        maxHeight: '100vh',
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: '0 0 10px 10px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.4)',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                    }
                }
            >
                {this.props.useBack && (
                    <>
                        <i
                            className="fas fa-chevron-circle-left fa-2x"
                            onClick={this.props.onBack}
                            style={
                                {
                                    cursor: 'pointer'
                                }
                            }
                        ></i>
                    </>
                )}
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: "column",
                            margin: 'auto',
                            height: '100%',
                            width: '100%',
                        }
                    }
                >
                    <h4
                        style={
                            {
                                margin: 'auto'
                            }
                        }
                    >
                        {this.props.title}
                    </h4>
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                               
                            }
                        }
                    >
                        {this.renderBreadcrumb()}
                    </div>
                </div>
                {this.props.useInfo && (
                    // <i
                    //     className="fas fa-info-circle fa-2x"
                    //     style={
                    //         {
                    //             cursor: 'pointer'
                    //         }
                    //     }
                    // ></i>
                    <>
                        {this.props.renderUseInfo}
                    </>
                )}
            </Header>
        )
    }
}