import React from "react";
import { Container, Content } from "rsuite";
import {BrowserPage} from "./pages/BrowserPage";
import { ReadListItems } from "./pages/ReadListItems";
import { ChatList } from "./pages/ChatList";
import { ChatPage } from "./pages/ChatPage";
import { HeaderComponent } from "./components/HeaderComponent";

export class ChatUserComponent extends React.Component<any, {}> {
    state = {
        pathRoute: 'projects',
        paramsRoute: {},
        loadings: false
    }

    constructor(props: any) {
        super(props);
        // Vinculando o método ao contexto do componente
        this.nextRouter = this.nextRouter.bind(this);
        this.previousRoute = this.previousRoute.bind(this);
    }

    componentDidMount(): void {
        this.mountedRouterInitial()
    }

    mountedRouterInitial () {
        
    }

    nextRouter ({route,paramsRoute}: {route: string, paramsRoute: Record<string,any>}) {
        this.setState(
            {
                pathRoute: route,
                paramsRoute: paramsRoute,
            }
            
        )
    }

    previousRoute ({route, dataParams}: {route: string, dataParams: Record<string,any>}) {
        dataParams.pathUrl.pop()
        this.setState(
            {
                pathRoute: route,
                paramsRoute: dataParams
            }
        )
    }


    renderComponentRoute = () => {
        const output: any = [];
        let { pathRoute, paramsRoute } = this.state;
        if(localStorage.getItem('chatWithNotification')) {
            const paramsNotification: any = localStorage.getItem('chatWithNotification');
            pathRoute = 'chatPage';
            paramsRoute = JSON.parse(paramsNotification).params;
            localStorage.removeItem('chatWithNotification')
        }
        switch(pathRoute) {
            case 'projects':
                output.push(<BrowserPage
                    nextRouter={this.nextRouter}
                    // previousRoute={this.previousRoute}
                />)
                // return <BrowserPage
                //     nextRouter={this.nextRouter}
                // />
                break;
            case 'products':
                output.push(
                    <ReadListItems
                        nextRouter={this.nextRouter}
                        previousRoute={this.previousRoute}
                        paramsRoute={paramsRoute}

                />
                )
                // return <ReadListItems 
                // />
                break;
            case 'chatList':
                output.push(
                    <ChatList 
                        nextRouter={this.nextRouter}
                        previousRoute={this.previousRoute}
                        paramsRoute={paramsRoute}
                    />
                )
                break;
            case 'chatPage':
                output.push(
                    <ChatPage 
                        nextRouter={this.nextRouter}
                        previousRoute={this.previousRoute}
                        paramsRoute={paramsRoute}
                    />
                )
            break;
        }
        return output;
    }

    render(): React.ReactNode {
        return (
            <Container
                style={
                    {
                        height: '75vh',
                        width: "100%",
                        gap: '10'
                    }
                }
            >
                {/* <Content>
                    <HeaderComponent 
                        routePrevious="projects"
                        dataParams={{}}
                        previousRoute={this.previousRoute}
                    />
                </Content> */}
                <Content>
                    {this.renderComponentRoute()}
                </Content>
            </Container>
        )
    }
}