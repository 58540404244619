import { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { Button, Modal, Uploader } from 'rsuite';
import { FileType } from 'rsuite/lib/Uploader';
import axiosClientChat from '../../../../../utilities/axiosClientChat';
import axios from 'axios';
import config from '../../../../../globals';

const ChatFileUploader: React.FC<any> = (props: any) => {
    const [showModal, setShowModal] = useState(true);
    const [uploadFiles, setUploadFiles] = useState<any>({})
    const [checkUpload, setCheckUpload] = useState(false)
    let uploaderRef: any = null

    const handleChange = async (value: any) => {
        setUploadFiles(value)
    }

    const handleUpload = async () => {
        await uploaderRef.start();
        setCheckUpload(true)

        // props.closeStateModal(false)
    }

    useEffect(() => {
        if (checkUpload) {
            if (uploaderRef && uploaderRef?.state?.fileList?.[0].status == "finished") {
                props.closeStateModal(false)
            }
        }

    })

    if (props.showOnly) {
        let message = props.message
        if (!message?.files && message) {
            message['files'] = message?.image
        }

        const downloadImage = (url: string, name: string) => {
            let definedAxios: AxiosInstance = axios;
            if (localStorage.getItem('chatToken')) {
                definedAxios = axiosClientChat;
            }

            let baseUrl = config.url;
            let extractedPath = url;
            if (config.url.includes("localhost")) {
                baseUrl = config.url.split('25565').join('25567');
                extractedPath = url.substring(url.indexOf(baseUrl) + baseUrl.length);
            }

            // console.log("debugs salvar", config.url, baseUrl, extractedPath);

            definedAxios.get(extractedPath, {
                responseType: 'blob'
            }).then((response: any) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        }

        let convertFile = () => {
            let output: any = []
            // for (var i in props.messages) {

            let fileName = message?.files?.split("/").pop()
            let fileType = fileName?.split(".").pop()
            output.push({
                name: fileName,
                fileKey: 1,
                url: `https://${window.location.host.split('25565').join('25567')}${message?.files?.split('undefined')?.pop()}`,
                type: fileType?.toLowerCase()
            })
            // }
            return output
        }
        let customRenderIcon = (file: any) => {

            switch (file?.type) {
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'gif':
                case 'bmp':
                    return <img src={file.url} width={200} />
                case 'pdf':
                    return <i className='fas fa-file-pdf' />;
                case 'doc':
                case 'docx':
                    return <i className='fas fa-file-word' />;
                case 'xls':
                case 'xlsx':
                    return <i className='fas fa-file-excel' />;
                case 'ppt':
                case 'pptx':
                    return <i className='fas fa-file-powerpoint' />;
                case 'zip':
                case 'rar':
                    return <i className='fas fa-file-archive' />;
                case 'txt':
                    return <i className='fas fa-file-alt' />;
                default:
                    return <i className='fas fa-file' />;
            }
        }
        return (<>
            {props.message &&
                (<>{convertFile()?.map((file: any) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {customRenderIcon(file)}
                            <div
                            >
                                <span>Arquivo: </span>
                                <p style={{ overflowWrap: "break-word" }}>
                                    {file.name}
                                </p>
                            </div>
                            {/* <p>
                                <a rel="noreferrer" target="_blank" download href={file.url}>
                                    Baixar <i className="fas fa-download"></i>
                                </a>
                            </p> */}
                            <Button
                                size='sm'
                                block={false}
                                style={{ width: 100 }}
                                color="green"
                                onClick={() => downloadImage(file.url, file.name)}
                            >
                                Baixar <i className="fas fa-download"></i>
                            </Button>
                        </div>
                    );
                })}
                </>)}
        </>)
    }


    const renderModal = () => {
        return (<>
            {showModal && (
                <Modal show={showModal}>
                    <Modal.Header onHide={() => { setShowModal(false); props.closeStateModal(false) }}>
                        Anexos
                    </Modal.Header>
                    <Modal.Body>
                        <Uploader
                            autoUpload={false}
                            headers={{
                                Authorization: "bearer " + localStorage.getItem('token'),
                            }}
                            action={"/chat-client/file/upload"}
                            onChange={handleChange}
                            className="w-100"
                            ref={(ref: any) => {
                                uploaderRef = ref;
                            }}
                            onSuccess={(response: any) => {
                                setTimeout(() => {

                                    props.sendUploadFile(response)
                                }, 1000);
                            }}
                            draggable
                        >
                            <div style={{ lineHeight: 5 }}> Clique ou arraste arquivos aqui para enviar.</div>
                        </Uploader>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => { setShowModal(false); props.closeStateModal(false) }} appearance='primary' color='red'>Cancelar</Button>
                        <Button onClick={() => { handleUpload() }} disabled={!uploadFiles?.length} appearance='primary' color='cyan'>Enviar <i className='far fa-paper-plane' /></Button>
                    </Modal.Footer>
                </Modal>)}
        </>)
    }

    return (
        <>
            {renderModal()}
            {/* <div onClick={() => { setShowModal(true) }}>
                <i className="fas fa-folder-open"></i> Arquivos(DESENV)
            </div> */}
        </>
    );
};

export default ChatFileUploader;
