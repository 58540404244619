import { DeveloperProductEvents } from "./pages/developers/DeveloperProductEvents";
import EstimateBrowseEvents from "./pages/estimates/EstimateBrowseEvents";
import CommercialBrowseEvents from './pages/labs/CommercialBrowseEvents'
import { PsmEquipmentEvents } from "./pages/psm_equipments/PsmEquipmentsActions";
import { PsmRegistrationProductEvents } from "./pages/psm_status_logs/PsmRegistrationProductEvents";
import { ArchivedProjectBrowseEvents } from "./pages/projects/ArchivedProjectFooterEvents"
import { ProjectBrowseEvents } from "./pages/projects/ProjectFooterEvents";
import { OngoingProjectBrowseEvents } from "./pages/projects/OngoingProjectFooterEvents";

export const CustomExtensions = {
    EstimateBrowseEvents,
    DeveloperProductEvents,
    PsmRegistrationProductEvents,
    PsmEquipmentEvents,
    CommercialBrowseEvents,
    ArchivedProjectBrowseEvents,
    ProjectBrowseEvents,
    OngoingProjectBrowseEvents
};
