import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Modal, Table } from "rsuite";
import { isInPath } from "../../utilities/isInPath";
import { AuthContext, ModuleContext, UserContext } from "../authProvider/main";
import { ModuleShortcuts } from "./moduleShortcut";
import { NotificationsIcon } from "../notifications/main";
import { BreadCrumbs } from "../breadcrumbs/main";
import _ from "lodash";
const { Column, HeaderCell, Cell } = Table;
// import { SearchBar } from "../search-bar/main";

class InnerTopbar extends React.Component<any, {}> {
    // static contextType = ModuleContext;
    public state: any = {
        showModalCompany: false,
        currentCompany: localStorage.getItem("currentCompany"),
        companyFantasy: localStorage.getItem("company_fantasy"),
    }

    logout = () => {
        this.props.auth.setAuth(null);
        localStorage.removeItem("currentCompany")
        localStorage.removeItem("company_fantasy")
        this.props.history.push("/login");
    };

    goBack = () => {
        this.props.history.push("/dashboard");
    };

    onModuleClick = (moduleName: string) => {
        this.props.history.push("/dashboard/" + moduleName);
    };

    renderProfileButton() {
        var componentDeTeste = () => {
            if (this.props.user.data) {
                return (
                    <div className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", textAlign: "left", minWidth: 100 }}>
                        <div style={{ fontSize: 16 }}>
                            {this.state.companyFantasy}
                            {/* {JSON.stringify(this.props.user.data)}
                            {_.find(this.props.user.data.user_companies_data, { company_id: parseInt(this.state.currentCompany) })?.company_fantasy} */}
                            <i onClick={this.goBack} className="fas fa-fw fa-cog ml-2 mr-2"></i>
                            {this.props.user.data.user_name}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        };

        return (
            <Dropdown placement="bottomEnd" renderTitle={componentDeTeste}>
                <Dropdown.Item onSelect={() => this.props.history.push("/dashboard/configuracoes/profile")}>
                    <i className="fas fa-fw fa-user icon"></i> Meu perfil
                </Dropdown.Item>
                {/* <Dropdown.Item onSelect={() => this.setState({ showModalCompany: true })}>
                    <i className="fas fa-fw fa-sync icon"></i> Trocar Empresa
                </Dropdown.Item> */}
                <Dropdown.Item onSelect={this.logout}>
                    <i className="fas fa-fw fa-sign-out-alt icon"></i> Logout
                </Dropdown.Item>
            </Dropdown>
        );
    }

    renderModulesButton() {
        return (
            <div onClick={this.goBack} className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", minWidth: 200, textAlign: "right" }}>
                <i className="fas fa-fw fa-th-large mr-2"></i>
                {this.props.module.name ? this.props.module.name.split("-").join(" ") : ""}
            </div>
        );
    }

    public ioSubscription: any = null;

    onSelectCompany(id: any) {
        localStorage.setItem("currentCompany", id)
        this.setState({ showModalCompany: false, currentCompany: id })
        this.props.history.push("/dashboard")

    }

    render() {
        if (!this.props.auth.token && !this.props.module.name) {
            return null;
        }
        // if(this.ioSubscription === null) {
        //     this.ioSubscription = io(config.url);
        // this.ioSubscription.on
        //
        // }
        // console.log(this.props.module)
        return (
            <div className={classNames("topbar", this.props.fulld ? "topbar-fulld" : this.props.full ? "topbar-full" : "")} style={{ backgroundColor: !this.props.module.name ? "#FFF" : "#00b3ab" }}>
                {/* <Modal show={!localStorage.getItem("currentCompany") || this.state.showModalCompany} size="lg">
                    <Modal.Header>
                        <b>  Selecione Uma Empresa  </b>
                    </Modal.Header>
                    <Modal.Body>
                        <Table virtualized height={400} data={this.props.user.data.user_companies_data ?? []}>
                            <Column flexGrow={0} align="left">
                                <HeaderCell>#</HeaderCell>
                                <Cell dataKey="company_id" />
                            </Column>
                            <Column flexGrow={3} align="left">
                                <HeaderCell>Nome</HeaderCell>
                                <Cell dataKey="company_fantasy" />
                            </Column>
                            <Column flexGrow={1} align="left">
                                <HeaderCell> </HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        return (<>
                                            <div style={{ marginTop: -10 }}>
                                                <Button onClick={() => this.onSelectCompany(rowData.company_id)} appearance="primary" color="blue">Selecionar</Button>
                                            </div>
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                        </Table>

                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal> */}
                <div style={{ width: "33%", display: "flex", alignItems: "center" }}>
                    {isInPath(this.props.location.pathname, "/dashboard") ? null : <i onClick={() => this.props.changeSidebar()} className={"fas fa-bars"}></i>}
                    <NotificationsIcon
                        history={this.props.history}
                    />

                    <BreadCrumbs />
                </div>

                {isInPath(this.props.location.pathname, "/dashboard") ? <div></div> : <ModuleShortcuts onClick={this.onModuleClick} />}
                {/* <div style={{ width: "33%", textAlign: "right" }}>{isInPath(this.props.location.pathname, "/dashboard") ? this.renderProfileButton() : this.renderModulesButton()}</div> */}
                <div style={{ width: "33%", textAlign: "right" }}>{this.renderProfileButton()}</div>
            </div>
        );
    }
}

class Topbar extends React.Component<any, {}> {
    render() {
        return (
            <ModuleContext.Consumer>
                {(moduleContext) => {
                    return (
                        <AuthContext.Consumer>
                            {(auth) => {
                                let moduleVar: any = moduleContext;
                                if (isInPath(this.props.location.pathname, "/dashboard")) {
                                    moduleVar = { name: "", setModule: () => { } };
                                }
                                return (
                                    <UserContext.Consumer>
                                        {(user: any) => {
                                            // var timeout = setTimeout(() => {

                                            // })
                                            if (user.data?.user_name) {
                                                return (
                                                    <InnerTopbar
                                                        changeSidebar={() => this.props.changeSidebar()}
                                                        fulld={isInPath(this.props.location.pathname, "/dashboard")}
                                                        full={this.props.hiddenSidebar}
                                                        location={this.props.location}
                                                        history={this.props.history}
                                                        module={moduleVar}
                                                        auth={auth}
                                                        user={user}
                                                    />
                                                );
                                            } else {
                                            }
                                        }}
                                    </UserContext.Consumer>
                                );
                            }}
                        </AuthContext.Consumer>
                    );
                }}
            </ModuleContext.Consumer>
        );
    }
}

export default withRouter(Topbar);
