import React from "react";
import { withRouter } from "react-router";
import EditPageCustom from "./EditPageCustom";
class ReadPage extends React.Component<any, {}> {
    render() {
        return <EditPageCustom view={true} />
    }
}

export default withRouter(ReadPage);
