import React from "react";
import { Button, IconButton, Modal, Notification } from 'rsuite';


interface CameraComponentProps {
    onHideCam: () => void;
    onSendImage: ({ image }: { image: any }) => void;
}


interface CameraComponentState {
    capturedImageURL: string | null;
    mediaStream: MediaStream | null;
    cameraIsOn: boolean;
    facingMode: 'user' | 'environment';
    capturedImage: string | null;
}
export class CameraComponent extends React.Component<CameraComponentProps, CameraComponentState> {
    videoRef: any
    constructor(props: any) {
        super(props);
        this.state = {
            capturedImageURL: null,
            mediaStream: null,
            cameraIsOn: false,
            facingMode: 'environment',
            capturedImage: null,
        }
        this.videoRef = React.createRef();
    }

    componentDidMount(): void {
        this.handleCamera()
    }

    handleCamera = async () => {
        try {
            const { facingMode } = this.state;
            const constraints = {
                video: {
                    width: {
                        min: 1280,
                        ideal: 1920,
                        max: 2560
                    },
                    height: {
                        min: 720,
                        ideal: 1080,
                        max: 1440,
                    },
                    facingMode: facingMode
                }
            }
            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            if (mediaStream) {
                this.setState({ mediaStream, cameraIsOn: true })
                this.videoRef.current.srcObject = mediaStream;
            }
            // Use a mediaStream para exibir a câmera em um elemento de vídeo ou faça o que desejar com ele
        } catch (error: any) {
            Notification.error(
                {
                    title: 'Erro',
                    description: error
                }
            )
        }
    };

    takePhoto = () => {
        const { mediaStream } = this.state;
        if (mediaStream && this.videoRef.current) {
            const canvas = document.createElement('canvas');
            const video = this.videoRef.current;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height);
            const capturedImageURL = canvas.toDataURL('image/png');
            this.setState({ capturedImage: capturedImageURL });
        }
    }

    toggleFacingMode = () => {
        this.setState(prevState => ({
            facingMode: prevState.facingMode === 'user' ? 'environment' : 'user', // Alternando entre user e environment
        }), this.handleCamera); // Chamando handleCamera após a alteração do facingMode
    };


    render(): React.ReactNode {
        return (
            <>
                <Modal
                    show={!!this.state.capturedImage}
                    onHide={() => {
                        this.setState({ capturedImage: null });
                    }}
                >
                    <Modal.Header>
                        <p>
                            Deseja Reprovar essa imagem?
                        </p>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.capturedImage && (
                            <img src={this.state.capturedImage} alt="Captured" style={{ width: '100%', height: 'auto' }} />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            appearance="primary"
                            style={
                                {
                                    width: '100%',
                                    background: '#131047'
                                }
                            }
                            onClick={() => {
                                
                                this.props.onSendImage({ image: this.state.capturedImage })
                                this.props.onHideCam()
                            }}
                        >
                            Enviar Foto
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div
                    style={
                        {
                            width: '100vw',
                            height: "100vh",
                            backgroundColor: 'rgb(0, 179, 171)',
                            position: 'relative'
                        }
                    }
                >
                    <video
                        style={
                            {
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                // zIndex: 9999
                            }
                        }
                        autoPlay ref={this.videoRef}
                    />
                    <div
                        style={
                            {
                                position: 'absolute',
                                right: 15,
                                top: 15,
                            }
                        }
                    >
                        <Button
                            appearance="primary"

                            onClick={() => {
                                this.state.mediaStream?.getTracks().forEach(track => {
                                    track.stop();
                                })
                                this.props.onHideCam()
                            }}
                        >
                            <i className="fas fa-times fa-lg"></i>
                        </Button>
                    </div>
                    <div
                        style={
                            {
                                position: 'absolute',
                                bottom: 1,
                                width: "100%",
                                height: '14vh',
                                background: 'rgba(0, 0, 0, 0.2)',
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: "center",
                            }
                        }
                    >
                        <Button
                            style={
                                {
                                    background: 'rgba(19,16,71,0.2)',
                                    padding: 10,
                                    borderRadius: 15,
                                    color: '#fff'
                                }
                            }
                        >
                            {/* <i className="fas fa-square fa-lg"></i> */}
                            Cam
                        </Button>
                        <Button
                            style={
                                {
                                    background: '#131047',
                                    padding: 20,
                                    borderRadius: 15,
                                    color: '#fff'
                                }
                            }
                            onClick={this.takePhoto}
                        >
                            <i className="fas fa-square fa-lg"></i>
                        </Button>
                        <Button
                            style={
                                {
                                    background: '#131047',
                                    padding: 10,
                                    borderRadius: 15,
                                    color: '#fff'
                                }
                            }
                            onClick={this.toggleFacingMode}
                        >
                            <i className="fas fa-undo"></i>
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}