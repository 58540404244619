import classNames from "classnames";
import React, { useEffect } from "react";
import { Redirect, withRouter } from "react-router";
import { Button, ControlLabel, FormGroup, Input, InputGroup, Notification, Panel } from "rsuite";
import InputGroupAddon from "rsuite/lib/InputGroup/InputGroupAddon";
import InputGroupButton from "rsuite/lib/InputGroup/InputGroupButton";
import Logo from "../assets/mind.png";
import { AuthContext } from "../components/authProvider/main";
import { ConfirmModal } from "../components/modal/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import axios from "../utilities/axios";
import Axios from 'axios'
import qs from "query-string";
import { Helmet } from "react-helmet";

class SetPasswordPage extends React.Component<any, {}> {
    public state: any = {
        isPasswordVisible: false,
        isLoading: false,
        userEmail: qs.parse(this.props.location.search)?.email,
        userPassword: "",
        userPasswordTwo: "",
        errorMessage: null,
    };

    doLogin = () => {
        console.log("alguma coisa")
        this.setState({
            isLoading: true
        })
        //Validations
        let error = false
        if (this.state.userPassword?.length <= 0 || this.state.userPasswordTwo?.length <= 0 || this.state.userEmail?.length <= 0) {
            error = true
            Notification.warning({ title: "Atenção!", description: "Preencha todos os campos!" })
        }

        if (this.state.userPassword !== this.state.userPasswordTwo) {
            error = true
            Notification.error({ title: "Erro!", description: "As senhas devem ser iguais!" })
        }

        if (error == true) {
            console.log("if error", error)
            this.setState({
                isLoading: false
            })
            return
        }

        
        const teste = this.context
        console.log("context", teste)
        
        Axios.post("/api/v1/user/setpassword", {
            user_email: this.state.userEmail,
            user_password: this.state.userPassword,
            user_reset_token: qs.parse(this.props.location.search)?.resetToken

        }).then((res) => {
            // await 
            console.log("res troca de senha", res);
            Notification.success({
                title: res.data.message
            })

            setTimeout(() => {
                window.location.href = "/login"
            }, 1000)
        }).catch((err) => {
            if (err && err.response && err.response) {
                Notification.error({
                    title: err.response.data.message
                })
            }
        })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        console.log(this.context);
        var { email, resetToken }: any = qs.parse(this.props.location.search);
        if (!email || !resetToken || resetToken?.length <= 5) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <h2>
                        <b>Link Inválido!</b>
                    </h2>
                </div>
            )
        }


        return (
            <div className="login-page">
                <Helmet>
                    <title>FastFlow - Nova senha</title>
                </Helmet>
                <LoginAnimationSvg />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Panel style={{ width: '350px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                        <div style={{ margin: '0 auto 20px' }}>
                            <img alt={"Logo"} src={Logo} style={{ width: '160px' }} />
                        </div>
                        <form onSubmit={() => this.doLogin()}>
                            <FormGroup className="mb-4">
                                <ControlLabel>E-mail</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-at"></i>
                                    </InputGroupAddon>
                                    <Input style={{ color: "black" }} onPressEnter={this.doLogin} defaultValue={email ?? ""} disabled onChange={(v) => this.setState({ userEmail: v })}></Input>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-6">
                                <ControlLabel>Senha</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-lock"></i>
                                    </InputGroupAddon>
                                    <Input onPressEnter={this.doLogin} onChange={(v) => this.setState({ userPassword: v })} type={this.state.isPasswordVisible ? "text" : "password"}></Input>
                                    {/* <InputGroupButton onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}>
                                        <i className={classNames("fas fa-fw", "fa-" + (!this.state.isPasswordVisible ? "eye" : "low-vision"))}></i>
                                    </InputGroupButton> */}
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className="mb-6">
                                <ControlLabel>Confirmar Senha</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-lock"></i>
                                    </InputGroupAddon>
                                    <Input onPressEnter={this.doLogin} onChange={(v) => this.setState({ userPasswordTwo: v })} type={this.state.isPasswordVisible ? "text" : "password"}></Input>
                                    {/* <InputGroupButton onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}>
                                        <i className={classNames("fas fa-fw", "fa-" + (!this.state.isPasswordVisible ? "eye" : "low-vision"))}></i>
                                    </InputGroupButton> */}
                                </InputGroup>
                            </FormGroup>
                            <div style={{ position: 'relative', width: '100%' }}>
                                <small style={{ position: 'absolute', top: '-10px', color: 'red' }}>{this.state.errorMessage}</small>
                                <Button style={{ background: '#00b3ab', width: '100%', marginTop: '5px', fontSize: '16px', border: 'none', borderRadius: '3px', cursor: 'pointer', padding: '10px', color: 'white' }} color="green" onClick={this.doLogin} loading={this.state.isLoading}>
                                    Salvar Senha
                                </Button>
                            </div>
                        </form>

                    </Panel>
                </div>
            </div>
        );
    }
}

export default withRouter(SetPasswordPage);