const permissionCheck = (params: { key: string, userContext: any}): boolean => {
    const { key, userContext } = params;
    if(userContext?.role?.role_permissions) {
        // alert(userContext?.role?.role_id);
        if(userContext?.role?.role_id === 2) {
            return true;
        }
        var perms : string[] = userContext?.role?.role_permissions.map((item : any) => {
            return item.role_permission_value;
        })
        for(var index in perms) {
            var perm = perms[index].split('-').join('_');
            if(!perm.includes('-')) {
                perms = [...perms,perm+'-browse',perm+'-read',perm+'-edit',perm+'-add',perm+'-delete']
            }
        }
        // //({perms});
        return perms.includes(key);
    }
    return false;
}

export {
    permissionCheck
}