import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";

export class UserCpfCnpjEvents extends Event {
    onBlur = (value: any, setField: any, setState: any) => {
        this.setState({
            loadings: [
                "user_cpf_cnpj",
                "user_name",
                "user_phone_number",
                "user_email",
                "user_address_street",
                "user_address_number",
                "user_address_complement",
                "user_address_neighborhood",
                "user_fk_state_id",
                "user_fk_city_id"
            ]
        });

        axios
            .get("/api/v1/consulta-cnpj/" + value, {
                params: {
                    table: 'users',
                    column: 'user_id',
                    whereColumn: 'user_cpf_cnpj',
                    id: this.globalState.formValues?.user_id
                }
            })
            .then(async function (res) {
                const { data } = res.data;
                if (res.data.error) {
                    Notification.warning({
                        title: 'Aviso',
                        description: res.data.error
                    })
                    return;
                }
                if (data.status !== 'ERROR') {
                    setField("user_name", data.nome);
                    setField("user_phone_number", data.telefone.split(" ").join(""));
                    setField("user_email", data.email);
                    setField("user_address_street", data.logradouro)
                    setField("user_address_number", data.numero)
                    setField("user_address_complement", data.complemento)
                    setField("user_address_neighborhood", data.bairro)
                    await axios
                        .get("/api/v1/states/select", {
                            params: {
                                state: data.uf,
                            },
                        })
                        .then(async (res) => {
                            var { items } = res.data;
                            const { label, value } = items[0] ?? undefined;
                            if (label && value) {
                                setField("user_fk_state_id", { label, value: value.toString() });

                                await axios
                                    .get("/api/v1/cities/select", {
                                        params: {
                                            search: data.municipio,
                                            state: value,
                                        },
                                    })
                                    .then((res) => {
                                        var { items } = res.data;
                                        var { label, value } = items[0] ?? undefined;
                                        console.log({label},{value})
                                        if (label && value) {
                                            setField("user_fk_city_id", { label, value: value.toString() });
                                        }
                                    })

                            }
                        })
                }
            })
            .catch((e) => {
                Notification.error(e)
            })
            .finally(() => {
                setState("loadings", []);
            });
    };
}
